import React, { useState } from "react";
import { Button, Segment, Tab } from "semantic-ui-react";
import FilterReport from "./FilterReport";
import GeneralReports from "./GeneralReports";
import uuidv4 from "uuid/dist/v4";
import DownloadReportList from "./DownloadReportList";
import Pollster from "./Pollster";
import ReportFamilyPlan from "./ReportFamilyPLan";
import FamilyReport from "./FamilyReport";
import FamilyMembers from "./FamilyMembers";
import Offers from "./Offers";
import Project from "./Project";
import Program from "./program";

const Reports = () => {
  const [filters, setFilters] = useState([]);

  const handleCreateFilter = () => {
    const filterList = [...filters];
    filterList.push({
      id: uuidv4(),
      content: FilterReport,
    });
    setFilters(filterList);
  };

  const handleDeleteFilter = (i) => {
    const filterList = [...filters];
    const newData = filterList.filter((v) => v.id !== i);
    setFilters(newData);
  };

  const panes = [
    {
      menuItem: "General",
      render: () => (
        <Tab.Pane>
          <GeneralReports />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Filtros",
      render: () => (
        <Tab.Pane>
          <div
            style={{ height: filters.length ? 680 : "auto", overflowY: "auto" }}
          >
            <Button
              size="medium"
              content="Crear reporte"
              onClick={handleCreateFilter}
              icon="add"
              circular
            />
            <DownloadReportList />
            {filters.length > 0 && (
              <Button
                size="medium"
                color="google plus"
                content="Vaciar"
                onClick={() => setFilters([])}
                icon="trash"
                circular
              />
            )}
            <Content data={filters} handleDeleteFilter={handleDeleteFilter} />
            {filters.length > 2 && (
              <>
                <Button
                  size="medium"
                  content="Agregar filtro"
                  onClick={handleCreateFilter}
                  icon="add"
                  circular
                />
                {filters.length > 0 && (
                  <Button
                    size="medium"
                    color="google plus"
                    content="Vaciar"
                    onClick={() => setFilters([])}
                    icon="trash"
                    circular
                  />
                )}
              </>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Encuestadores",
      render: () => (
        <Tab.Pane>
          <Pollster />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Plan familiar",
      render: () => (
        <Tab.Pane>
          <ReportFamilyPlan />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Familias asignadas",
      render: () => (
        <Tab.Pane>
          <FamilyReport />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Beneficiarios creados",
      render: () => (
        <Tab.Pane>
          <FamilyMembers />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Ofertas",
      render: () => (
        <Tab.Pane>
          <Offers />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Proyectos",
      render: () => <Project />,
    },
    {
      menuItem: "Programas",
      render: () => <Program />,
    },
  ];

  return (
    <>
      <h1>Reporte de usuarios</h1>
      <Tab panes={panes} />
    </>
  );
};

const Content = ({ data = [], handleDeleteFilter }) => {
  return data.map((value) => {
    const NewComponent = value.content;
    return (
      <Segment key={value.id}>
        <NewComponent handleDeleteFilter={() => handleDeleteFilter(value.id)} />
      </Segment>
    );
  });
};

export default Reports;
