import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Label, Modal, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import CustomTable from "../../../Components/CustomTable";

function Proyectos({ idProgram, project, refetch, error, loading, EDIT }) {
  const [open, setOpen] = useState(false);
  const [beneficiary, setBeneficiary] = useState("");
  const [idProject, setIdProject] = useState(0);
  const [, setSearch] = useState("");
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompletedDelete = async () => {
    setSearch("");
    setIdProject(0);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el proyecto de manera exitosa.",
    });
  };
  const onCompleted = async () => {
    setSearch("");
    setIdProject(0);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el proyecto de manera exitosa.",
    });
  };
  const [mutation] = useMutation(ADD_FAMILY_LEADERS, { onCompleted, onError });
  const [deleteMutation] = useMutation(DELETE_FAMILY_LEADERS, {
    onCompleted: onCompletedDelete,
    onError,
  });

  const handleMutation = () => {
    const input = {
      idProject,
      idProgram,
    };
    console.log(input);
    mutation({ variables: { input } });
  };

  const handleMutationDelete = (id) => {
    const input = {
      idProject: id,
      idProgram,
    };
    deleteMutation({ variables: { input } });
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Estrategia",
      accessor: "strategy",
    },
  ];

  const { data } = useQuery(GET_PROGRAM, {
    variables: {
      page: { number: 1, limit: 100000 },
      filter: { status: "ACTIVE", name: "" },
    },
    fetchPolicy: "network-only",
  });

  if (EDIT)
    columns.push({
      header: "Acción de eliminar",
      render: ({ id }) => (
        <Button
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "No podrás revertir esta acción.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí, eliminar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                handleMutationDelete(id);
              }
            });
          }}
          size="mini"
        >
          Eliminar
        </Button>
      ),
    });

  return (
    <>
      <Button
        size="tiny"
        compact
        icon="eye"
        content="Ver Proyectos"
        onClick={() => setOpen(true)}
      />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Proyectos</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label content={`Cantidad (${project?.length || 0})`} />
              </Form.Field>
              <Form.Input
                action={{
                  icon: "trash",
                  size: "mini",
                  content: "Limpiar",
                  onClick: () => setBeneficiary(""),
                }}
                value={beneficiary}
                size="mini"
                onChange={(e, { value }) => setBeneficiary(value)}
                placeholder="Búsqueda por nombre"
              />
              {EDIT && (
                <Popup
                  content={
                    <Form.Group>
                      <Form.Dropdown
                        search
                        selection
                        loading={loading}
                        placeholder="Afiliado"
                        onResultSelect={(_, data) =>
                          setIdProject(data.result.id)
                        }
                        value={idProject}
                        onChange={(_, { value }) => setIdProject(value)}
                        options={data?.projectList?.items?.map((data) => ({
                          key: data.id,
                          text: data.name,
                          value: data.id,
                        }))}
                      />
                      <div style={{ marginTop: 10 }} />
                      <Form.Button
                        content="Guardar"
                        loading={loading}
                        disabled={idProject === 0 || loading}
                        onClick={handleMutation}
                      />
                    </Form.Group>
                  }
                  on="click"
                  positionFixed
                  trigger={<Form.Button icon="add" size="tiny" compact />}
                />
              )}
            </Form.Group>
          </Form>
          <CustomTable
            totalPages={1}
            data={
              project.filter((item) =>
                item.name
                  .toLowerCase()
                  .includes(beneficiary.toLocaleLowerCase().trim())
              ) || []
            }
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

const ADD_FAMILY_LEADERS = gql`
  mutation ($input: inputAddProgram!) {
    addProgram(input: $input) {
      id
    }
  }
`;
const DELETE_FAMILY_LEADERS = gql`
  mutation ($input: inputAddProgram!) {
    deleteProgramProject(input: $input) {
      id
    }
  }
`;

const GET_PROGRAM = gql`
  query ProjectList($page: PageInput!, $filter: ProjectFilter!) {
    projectList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
      }
    }
  }
`;

export default Proyectos;
