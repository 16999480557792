import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import DashboardModule from "./Dashboard";
import Allies from "./Allies";
import {
  Dimmer,
  Grid,
  Loader,
  Menu,
  Message,
  Segment,
  Icon,
  Button,
  Image,
  Sidebar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import { client } from "../../client";
import { getData, setData } from "../../Utils";
import Modules from "./Modules";
import Actions from "./Actions";
import LOGO_BID from "../../assets/bid.png";
import Permissions from "./Permissions";
import Surveys from "./Surveys";
import Survey from "./Surveys/Survey";
import UserGroup from "./UserGroup";
import User from "./User";
import Parameter from "./Parameters";
import Sesions from "./Sesions";
import Periods from "./Periods";
import Reports from "./Reports";
import Offers from "./Offers";
import AnswerSurvey from "./Answer/SearchFamilyMenmber";
import { useMediaQuery } from "react-responsive";
import OptionsList from "./Answer";
import FamilyPlan from "./FamilyPLan";
import Questions from "./FamilyPLan/Categories/Questions";
import AnswersHistory from "./AnswersHistory/index";
import UpdateOffer from "./Offers/Offer";
import Members from "./Members";
import Project from "./Project";
import Program from "./Program";
import WorkTeam from "./WorkTeam";

const icons = {
  Módulos: "tablet",
  Acciones: "cogs",
  Permisos: "lock open",
  Usuarios: "user",
  Beneficiarios: "address book",
  Encuestas: "file alternate outline",
  "Grupo Usuarios": "group",
  Parámetros: "tag",
  Periodos: "list alternate",
  Reportes: "line graph",
  Ofertas: "percent",
  Responder: "clipboard outline",
  "Plan Familiar": "sitemap",
  Sesiones: "address book",
  "Historial respuestas": "history",
  Dashboard: "line graph",
  Aliados: "user",
  Proyectos: "crop",
  Programas: "book",
  "Equipos de Trabajos": "address card",
};

const Dashboard = ({ match, history }) => {
  const me = getData("meBid");
  const [active, setActive] = useState("");
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [, setOpen] = useState("");
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const { loading, error, data, refetch } = useQuery(MODULES, {
    variables: { idUserGroup: me?.userGroup?.id },
  });

  const handleItemClick = (e, { name }) => {
    setActive(name);
    history.replace(
      name === "Dashboard" ? `/dashboard` : `${match.url}/${name}`
    );
  };

  const onCompleted = () => {
    setData("meBid", null);
    setData("userBid", null);
    client.clearStore();
    history.replace("/");
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [logout] = useMutation(LOGOUT, { onCompleted, onError });
  const handleLogout = () => {
    Swal.fire({
      title: "¿Deseas finalizar la sesión?",
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  if (loading)
    return (
      <Segment style={{ minHeight: "100vh" }}>
        <Dimmer inverted active>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      </Segment>
    );

  if (error || !data)
    return (
      <Segment style={{ minHeight: "100vh" }}>
        <Message>Ha ocurrido un error.</Message>
      </Segment>
    );

  let groupedByCategory = [];
  if (data) {
    groupedByCategory = data?.moduleList?.items?.reduce(
      (acumulador, elemento) => {
        if (elemento.categoria === null) {
          acumulador[elemento.name] = elemento;
          return acumulador;
        }
        const clave = elemento.categoria.name;

        if (!acumulador[clave]) {
          acumulador[clave] = [];
        }

        acumulador[clave].push(elemento);

        return acumulador;
      },
      {}
    );
  }

  if (isTabletOrMobile)
    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          inverted
          onHide={() => setSidebarOpened(false)}
          style={{
            backgroundColor: "#DB2C39",
            borderRadius: 0,
            width: "70%",
          }}
          icon
          vertical
          visible={sidebarOpened}
        >
          {Object.keys(groupedByCategory).map((module, key) => {
            if (!Array.isArray(groupedByCategory[module])) {
              const READ = groupedByCategory[module]?.permissions?.some(
                (value) => value?.action?.name === "Leer"
              );
              return (
                READ && (
                  <Menu.Item
                    key={key}
                    name={module}
                    active={active === module}
                    onClick={handleItemClick}
                    as="a"
                  >
                    {icons[module] && <Icon name={icons[module]} />}
                    {module}
                  </Menu.Item>
                )
              );
            } else {
              const READ = groupedByCategory[module].filter((item) =>
                item?.permissions?.some(
                  (value) => value?.action?.name === "Leer"
                )
              );
              return (
                READ &&
                (sidebarOpened ? (
                  <Dropdown
                    style={{
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      flexDireccion: "column",
                      padding: ".92857143em 1.14285714em",
                    }}
                    text={module}
                    key={key}
                    onClick={() => setOpen(module)}
                  >
                    <DropdownMenu>
                      {groupedByCategory[module].map(({ name }, key) => (
                        <DropdownItem
                          key={key}
                          as="a"
                          onClick={() => handleItemClick(null, { name })}
                          active={active === module}
                        >
                          {icons[name] && <Icon name={icons[name]} />}
                          {name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <div
                    key={key}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <Icon name="caret right" />
                  </div>
                ))
              );
            }
          })}
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Menu pointing secondary size="large">
            <Menu.Item onClick={() => setSidebarOpened(true)}>
              <Icon name="sidebar" />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item style={{ color: "#DB2C39" }} name="profile">
                <Icon size="large" name="user" />
                {me?.name}
              </Menu.Item>
              <Menu.Item>
                <Button
                  onClick={handleLogout}
                  style={{ backgroundColor: "#DB2C39", color: "white" }}
                  icon="sign-out alternate"
                  color="google plus"
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <div style={{ minHeight: "100vh", margin: 20 }}>
            {data?.moduleList?.items?.map((module, key) => {
              const READ = module?.permissions?.some(
                (value) => value?.action?.name === "Leer"
              );
              return (
                READ && (
                  <Route
                    key={key}
                    exact={module?.name === "Dashboard"}
                    path={
                      module?.name === "Dashboard"
                        ? `/dashboard`
                        : `${match.url}/${module?.name}`
                    }
                  >
                    <Content
                      history={history}
                      path={
                        module?.name === "Dashboard"
                          ? `/dashboard`
                          : `${match.url}/${module?.name}`
                      }
                      module={module}
                      refetch={refetch}
                    />
                  </Route>
                )
              );
            })}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );

  return (
    <div>
      <Menu fluid stackable attached="top">
        <Menu.Item link>
          {
            <Image
              style={{ margin: "0px 16px" }}
              size="tiny"
              src={LOGO_BID}
              alt="logo Bid"
            />
          }
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item style={{ color: "#DB2C39" }} name="profile">
            <Icon size="large" name="user" />
            {me?.name}
          </Menu.Item>
          <Menu.Item>
            <Button
              onClick={handleLogout}
              style={{ backgroundColor: "#DB2C39", color: "white" }}
              icon="sign-out alternate"
              color="google plus"
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <div style={{ backgroundColor: "#DB2C39", height: 7 }}></div>
      <Grid stackable>
        <Grid.Column
          style={{
            transition: "0.5s",
            width: sidebarOpened ? 220 : 80,
            height: "100vh",
          }}
          onMouseEnter={() => setSidebarOpened(true)}
          onMouseLeave={() => setSidebarOpened(false)}
        >
          <Menu
            inverted
            fluid
            style={{
              backgroundColor: "#DB2C39",
              minHeight: "100%",
              borderRadius: 0,
            }}
            icon
            vertical
          >
            {Object.keys(groupedByCategory).map((module, key) => {
              if (!Array.isArray(groupedByCategory[module])) {
                const READ = groupedByCategory[module]?.permissions?.some(
                  (value) => value?.action?.name === "Leer"
                );
                return (
                  READ && (
                    <Menu.Item
                      key={key}
                      name={module}
                      active={active === module}
                      onClick={handleItemClick}
                      as="a"
                    >
                      {icons[module] && <Icon name={icons[module]} />}
                      {sidebarOpened ? module : null}
                    </Menu.Item>
                  )
                );
              } else {
                const READ = groupedByCategory[module].filter((item) =>
                  item?.permissions?.some(
                    (value) => value?.action?.name === "Leer"
                  )
                );
                return (
                  READ &&
                  (sidebarOpened ? (
                    <Dropdown item text={module} key={key}>
                      <DropdownMenu>
                        {groupedByCategory[module].map(({ name }, key) => (
                          <DropdownItem
                            key={key}
                            as="a"
                            onClick={() => handleItemClick(null, { name })}
                            active={active === module}
                          >
                            {icons[name] && <Icon name={icons[name]} />}
                            {name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <div
                      key={key}
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <Icon name="caret right" />
                    </div>
                  ))
                );
              }
            })}
          </Menu>
        </Grid.Column>
        <Grid.Column width={13}>
          <div style={{ paddingTop: 20 }}>
            <Switch>
              {data?.moduleList?.items?.map((module, key) => {
                const READ = module?.permissions?.some(
                  (value) => value?.action?.name === "Leer"
                );
                return (
                  READ && (
                    <Route
                      key={key}
                      exact={module?.name === "Dashboard"}
                      path={
                        module?.name === "Dashboard"
                          ? `/dashboard`
                          : `${match.url}/${module?.name}`
                      }
                    >
                      <Content
                        history={history}
                        path={
                          module?.name === "Dashboard"
                            ? `/dashboard`
                            : `${match.url}/${module?.name}`
                        }
                        module={module}
                        refetch={refetch}
                      />
                    </Route>
                  )
                );
              })}
            </Switch>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const Content = ({ module, refetch, path, history }) => {
  let location = useLocation();
  switch (module?.name) {
    case "Módulos":
      return <Modules permissions={module?.permissions} />;
    case "Acciones":
      return <Actions permissions={module?.permissions} />;
    case "Permisos":
      return (
        <Permissions
          reloadPermissions={refetch}
          permissions={module?.permissions}
        />
      );
    case "Usuarios":
      return <User permissions={module?.permissions} />;
    case "Grupo Usuarios":
      return <UserGroup permissions={module?.permissions} />;
    case "Encuestas":
      return (
        <>
          {path === location?.pathname && (
            <Surveys history={history} permissions={module?.permissions} />
          )}
          <Switch>
            <Route path={`${path}/:id`}>
              <Survey />
            </Route>
          </Switch>
        </>
      );
    case "Parámetros":
      return <Parameter history={history} permissions={module?.permissions} />;
    case "Beneficiarios":
      return <Members history={history} permissions={module?.permissions} />;
    case "Sesiones":
      return <Sesions permissions={module?.permissions} />;
    case "Proyectos":
      return (
        <Project
          permissions={module?.permissions}
          reloadPermissions={refetch}
        />
      );
    case "Programas":
      return (
        <Program
          permissions={module?.permissions}
          reloadPermissions={refetch}
        />
      );
    case "Periodos":
      return <Periods permissions={module?.permissions} />;
    case "Reportes":
      return <Reports permissions={module?.permissions} />;
    case "Plan Familiar":
      return (
        <>
          {path === location?.pathname && (
            <FamilyPlan history={history} permissions={module?.permissions} />
          )}
          <Switch>
            <Route path={`${path}/:idFamilyPlan/Categoría/:id`}>
              <Questions permissions={module?.permissions} />
            </Route>
          </Switch>
        </>
      );
    case "Ofertas":
      return (
        <>
          {path === location?.pathname && (
            <Offers permissions={module?.permissions} />
          )}
          <Switch>
            <Route path={`${path}/:id`}>
              <UpdateOffer />
            </Route>
          </Switch>
        </>
      );
    case "Historial respuestas":
      return <AnswersHistory permissions={module?.permissions} />;
    case "Responder":
      return (
        <>
          {path === location?.pathname && (
            <AnswerSurvey history={history} permissions={module?.permissions} />
          )}
          <Switch>
            <Route exact component={OptionsList} path={`${path}/Listado/:id`} />
          </Switch>
        </>
      );
    case "Dashboard":
      return (
        <>
          <Switch>
            <Route path="/dashboard" exact>
              <DashboardModule
                history={history}
                permissions={module?.permissions}
              />
            </Route>
          </Switch>
        </>
      );
    case "Aliados":
      return (
        <>
          <Allies history={history} permissions={module?.permissions} />
        </>
      );
    case "Equipos de Trabajos":
      return (
        <>
          <WorkTeam history={history} permissions={module?.permissions} />
        </>
      );
    default:
      return null;
  }
};

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const MODULES = gql`
  query ($idUserGroup: ID) {
    moduleList(page: { number: 1, limit: 100 }, filter: { status: ACTIVE }) {
      items {
        id
        name
        order
        categoria {
          name
          id
        }
        status
        permissions(idUserGroup: $idUserGroup) {
          id
          action {
            id
            name
          }
        }
      }
    }
  }
`;

export default Dashboard;
