import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import CustomTable from "../../../Components/CustomTable";
import { Form, Label } from "semantic-ui-react";
import CreateUser from "./CreateUser";
import SelectUserGroup from "./SelectUserGroup";
import { EMAIL_REGEX } from "../../../Utils/Constants";
import UpdateUser from "./UpdateUser";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
];

const User = ({ permissions }) => {
  const [status, setStatus] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [fakeName, setFakeName] = useState("");
  const [idUserGroup, setIdUserGroup] = useState("");
  const [document, setDocument] = useState("");
  const [fakeDocument, setFakeDocument] = useState("");
  const [email, setEmail] = useState("");
  const [fakeEmail, setFakeEmail] = useState("");
  const filter = { status };
  if (name.trim()) filter.name = name;
  if (idUserGroup) filter.idUserGroup = idUserGroup;
  if (document.trim()) filter.document = document;
  if (EMAIL_REGEX.test(email)) filter.email = email.trim();
  const change = () => {
    setCurrentPage(1);
  };
  const clearFilter = () => {
    setName("");
    setFakeName("");
    setIdUserGroup("");
    setDocument("");
    setFakeDocument("");
    setEmail("");
    setFakeEmail("");
  };
  const { data, loading, error, refetch } = useQuery(USERS, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });

  const CREATE = permissions?.some((value) => value?.action.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action.name === "Editar");
  //const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Identificación",
      accessor: "document",
    },
    {
      header: "Correo",
      accessor: "email",
    },
    {
      header: "Género",
      accessor: "gender",
    },
    {
      header: "Teléfono",
      accessor: "phone",
    },
    {
      header: "Grupo Usuarios",
      accessor: "userGroup.name",
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (data) => <UpdateUser refetch={refetch} user={data} />,
    });

  /*
    if (DELETE && status !== "ELIMINADO") columns.push(
      {
        header: 'Acción de eliminar',
        render: data => <DeleteModule reload={refetch} id={data.id} />
      }
    );
  */

  return (
    <div>
      <h1>Usuarios</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateUser refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.userList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => {
              setStatus(value);
              change();
            }}
            options={options}
          />
          <SelectUserGroup
            label="Grupo usuario"
            clearable
            value={idUserGroup}
            onChange={(e, { value }) => setIdUserGroup(value)}
            placeholder="Búsqueda por grupo usuario"
            selection={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setDocument(fakeDocument),
            }}
            value={fakeDocument}
            size="mini"
            onChange={(e, { value }) => setFakeDocument(value)}
            placeholder="Búsqueda por documento"
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              disabled: !EMAIL_REGEX.test(fakeEmail) ? true : false,
              content: "Buscar",
              onClick: () => setEmail(fakeEmail),
            }}
            value={fakeEmail}
            size="mini"
            onChange={(e, { value }) => setFakeEmail(value)}
            placeholder="Búsqueda por correo"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.userList?.totalPages || 0}
        data={data?.userList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
        document
        firstName
        firstSurname
        secondName
        secondSurname
        email
        gender
        phone
        status
        userGroup {
          id
          name
        }
      }
    }
  }
`;

export default User;
