import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal, Tab } from "semantic-ui-react";
import Swal from "sweetalert2";
import UpdateDocumentFamilyLeader from "./UpdateDocumentFamilyLeader";
import { getData } from "../../../../Utils";

const UpdateFamilyLeader = ({ user }) => {
  const me = getData("meBid");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(user?.name || "");
  const [houseNumber, setHouseNumber] = useState(user?.houseNumber || "");
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(user?.etapa || "");
  const [encuestador, setEncuestador] = useState(user?.Encuestador?.id || null);

  const { loadingUsers, errorUsers, data } = useQuery(USERS, {
    variables: {
      page: {
        number: 1,
        limit: 10000,
      },
      filter: {
        status: "ACTIVE",
        idUserGroup: 2,
      },
    },
    fetchPolicy: "network-only",
  });
  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el usuario de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_FAMILY_LEADER, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          id: user?.id,
          name,
          houseNumber,
          idEncuestador: encuestador,
          etapa: stage,
        },
      },
    });
  };
  const disabled = () => {
    if (!name?.trim()) return true;
    if (!houseNumber?.trim()) return true;
    if (loading) return true;
    if (!stage) return true;
  };
  const panes = [
    {
      menuItem: "Datos personales",
      render: () => (
        <Tab.Pane>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Nombre completo"
                value={name}
                onChange={(e, { value }) => setName(value)}
              />
              <Form.Input
                label="# Casa"
                value={houseNumber}
                onChange={(e, { value }) => setHouseNumber(value)}
              />
            </Form.Group>
            <Form.Group width="equal">
              <Form.Input
                value={stage}
                onChange={(e, { value }) => setStage(value)}
                label="Etapa"
                required
              />
              <Form.Dropdown
                label="Encuestador"
                value={encuestador}
                onChange={(e, { value }) => {
                  if (value === "") {
                    setEncuestador(null);
                  } else {
                    setEncuestador(value);
                  }
                }}
                scrolling={true}
                clearable={true}
                selection={true}
                error={errorUsers}
                search={true}
                loading={loadingUsers}
                disabled={
                  parseInt(me.userGroup.id) === 1
                    ? false
                    : parseInt(me.userGroup.id) === 3
                    ? false
                    : true
                }
                options={
                  data?.userList?.items.map((item) => {
                    return { ...item, value: item.id, text: item.name };
                  }) || []
                }
              />
            </Form.Group>
          </Form>
          <div style={{ marginTop: 20 }}>
            <Button
              content="Guardar"
              style={{ backgroundColor: "#045A73", color: "white" }}
              loading={loading}
              disabled={disabled()}
              onClick={handleMutation}
            />
            <Button content="Cancelar" onClick={() => setOpen(false)} />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Identificación",
      render: () => (
        <Tab.Pane>
          <UpdateDocumentFamilyLeader setOpen={setOpen} user={user} />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Modal
      size="tiny"
      open={open}
      trigger={<Button size="tiny" onClick={() => setOpen(true)} icon="edit" />}
    >
      <Modal.Header>Editar lider</Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
    </Modal>
  );
};
const UPDATE_FAMILY_LEADER = gql`
  mutation ($input: UpdateFamilyLeaderInput!) {
    updateFamilyLeader(input: $input) {
      id
      identification
      name
      houseNumber
      Encuestador {
        id
        name
      }
      etapa
      familyMenmbers {
        id
        name
        typeIdentification {
          id
          name
        }
      }
      documents {
        id
        identification
        active
      }
    }
  }
`;

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
  }
`;
export default UpdateFamilyLeader;
