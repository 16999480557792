import React from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import UpdateFamilyMenmber from "./UpdateFamilyMenmber";
import DocumentList from "./DocumentList";
import ObservationList from "./ObservationList";
import OfferModal from "./OfferModal";
import ProjectModal from "./ProjectList";

const MemberList = ({ search, filterType, goTo }) => {
  const variables = {};
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data, refetch } = useQuery(FAMILY_MEMBERS, {
    variables,
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <Segment style={{ height: 300 }}>
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      </Segment>
    );

  if (error) return <Message>Lo sentimos, ha ocurrido un error.</Message>;
  const { familyMenmbers } = data;

  if (!familyMenmbers?.length)
    return (
      <div style={{ marginTop: 30 }}>
        <Message>No se ha encontrado ningún miembro de familia.</Message>
      </div>
    );

  return familyMenmbers?.map((familyMenmber, key) => (
    <Segment key={key}>
      <Label
        style={{ backgroundColor: "#045A73", color: "white" }}
        attached="top"
      >
        Miembro familiar
      </Label>
      {!familyMenmber?.isLeader && (
        <Segment>
          <Label
            content="Lider Familiar"
            style={{ backgroundColor: "#045A73", color: "white" }}
            attached="top"
          />
          <Header as="h5">
            <Header.Content>
              {familyMenmber?.familyLeader?.name}
              <Header.Subheader>
                <Icon name="home" /> {familyMenmber?.familyLeader?.houseNumber}
              </Header.Subheader>
              <Header.Subheader>
                {" "}
                <Icon name="address card" />{" "}
                {familyMenmber?.familyLeader?.identification}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      )}
      {familyMenmber?.isLeader && (
        <Label
          content="Lider Familiar"
          style={{ backgroundColor: "#045A73", color: "white" }}
          ribbon
        />
      )}
      <Table basic="very" fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Identificación</Table.HeaderCell>
            <Table.HeaderCell>Fecha de nacimiento</Table.HeaderCell>
            <Table.HeaderCell>Tipo de identificación</Table.HeaderCell>
            <Table.HeaderCell>Reside en Villa Caracas</Table.HeaderCell>
            <Table.HeaderCell>Editar</Table.HeaderCell>
            <Table.HeaderCell>Lista de identificaciones</Table.HeaderCell>
            <Table.HeaderCell>Lista de observaciones</Table.HeaderCell>
            <Table.HeaderCell>Ofertas</Table.HeaderCell>
            <Table.HeaderCell>Acción</Table.HeaderCell>
            <Table.HeaderCell>Proyectos</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>
                  {familyMenmber?.firstName} {familyMenmber?.secondName}{" "}
                  {familyMenmber?.surname} {familyMenmber?.secondSurname}
                  <Header.Subheader>Miembro Familiar</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{familyMenmber?.identification}</Table.Cell>
            <Table.Cell>{familyMenmber?.birthDate}</Table.Cell>
            <Table.Cell>{familyMenmber?.typeIdentification?.name}</Table.Cell>
            <Table.Cell>
              {familyMenmber?.residesInVillaCaracas ? "Si" : "No"}
            </Table.Cell>
            <Table.Cell>
              <UpdateFamilyMenmber triggerFluid={false} user={familyMenmber} />
            </Table.Cell>
            <Table.Cell>
              {<DocumentList data={familyMenmber?.documents} />}
            </Table.Cell>
            <Table.Cell>
              <ObservationList
                id={familyMenmber?.id}
                refetch={refetch}
                data={familyMenmber?.observations}
              />
            </Table.Cell>
            <Table.Cell>
              <OfferModal user={familyMenmber} update={refetch} />
            </Table.Cell>
            <Table.Cell>
              <Button
                disabled={
                  familyMenmber?.exitDate &&
                  new Date(familyMenmber?.exitDate) <= new Date()
                }
                content="Responder"
                onClick={() => goTo(familyMenmber?.id)}
                size="tiny"
              />
            </Table.Cell>
            <Table.Cell>
              {<ProjectModal idFamilyLeader={familyMenmber?.id} />}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  ));
};

const FAMILY_MEMBERS = gql`
  query ($name: String, $identification: String, $houseNumber: String) {
    familyMenmbers(
      name: $name
      identification: $identification
      houseNumber: $houseNumber
    ) {
      id
      firstName
      secondName
      surname
      secondSurname
      residesInVillaCaracas
      isLeader
      birthDate
      phone
      mail
      exitDate
      identification
      familyLeader {
        id
        name
        identification
        houseNumber
      }
      observations {
        id
        description
        startDate
        user {
          id
          name
        }
      }
      documents {
        id
        active
        identification
        typeIdentification {
          id
          name
        }
      }
      gender {
        id
        name
      }
      typeIdentification {
        id
        name
      }
      maritalStatus {
        id
        name
      }
      expeditionDocumentDate
      expirationDocumentDate
    }
  }
`;

export default MemberList;
