import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

const ColumnChart = ({ title, data = [], type = "column" }) => {
  const options =
  {
    chart: {
      type
    },
    title: {
      text: title
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        'Cantidad: <b>{point.y}</b><br/>' + (type === "column" ? 'Porcentaje: <b>{point.z}%</b><br/>' : "")
    },
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    series: [{
      name: 'Información',
      colorByPoint: true,
      data
    }]
  };

  if (type === "column") options.yAxis = {
    min: 0,
    title: {
      text: 'Cantidad'
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options || {}}
    />
  );
}

export default ColumnChart;