import { useMutation, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Header, Input, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import DragAndDrop from "../../../../Components/DragAndDrop";
import CreateCategory from "./CreateCategory";
import DeleteCategory from "./DeleteCategory";

const FamilyPlanCategories = ({
  categories = [],
  name,
  idFamilyPlan,
  refetch,
  actionCreate,
  actionUpdate,
  actionDelete
}) => {
  const [categoriesState, setCategoriesState] = useState(categories || []);
  const [familyPlanName, setFamilyPlanName] = useState(name || "");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setCategoriesState(categories);
  }, [categories]);
  const onCompleted = async () => {
    setLoading(false);
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_FAMILY_PLAN, { onCompleted, onError });
  const handleCategory = (currentCategoty, value) => {
    const newCategories = [...categoriesState];
    const index = newCategories.findIndex(category => category?.id === currentCategoty?.id);
    if (index !== -1) {
      newCategories[index] = { ...currentCategoty, name: value?.trim() };
      setCategoriesState(newCategories);
    }
  }
  const saveFamilyPlan = () => {
    setLoading(true);
    const familyPlan = {
      idFamilyPlan,
      name: familyPlanName,
      categories: categoriesState.map((category, i) => ({
        id: category?.id,
        name: category?.name,
        order: i + 1
      }))
    }
    mutation({
      variables: {
        input: familyPlan
      }
    });
  }
  const goTo = idCategory => () => {
    history.push(`/dashboard/Plan Familiar/${idFamilyPlan}/Categoría/${idCategory}`)
  }

  return (
    <>
      <Input
        size="big"
        fluid
        onChange={(e, { value }) => setFamilyPlanName(value)}
        value={familyPlanName} />
      <Header as="h2">Categorías</Header>
      <DragAndDrop data={categoriesState} onChange={res => setCategoriesState(res)}>
        {({ element, index }) => (
          <FamilyPlanCategory
            index={index}
            actionDelete={actionDelete}
            category={element}
            goTo={goTo(element?.id)}
            refetch={refetch}
            handleCategory={handleCategory} />
        )}
      </DragAndDrop>
      <br />
      {actionCreate
        && (
          <div style={{ textAlign: 'center' }}>
            <CreateCategory
              idFamilyPlan={idFamilyPlan}
              refetch={refetch} />
          </div>
        )}
      {(actionUpdate && categoriesState.length > 0) && (
        <Button
          loading={loading}
          disabled={loading}
          content="Guardar"
          onClick={saveFamilyPlan} />
      )}
    </>
  )
}


const FamilyPlanCategory = ({ category, handleCategory, goTo, index, actionDelete, refetch }) => {
  const [name, setName] = useState(category?.name || "");
  const onChange = (e, { value }) => {
    setName(value);
    if (typeof handleCategory === "function") handleCategory(category, value);
  }
  return (
    <Segment style={{ marginTop: 10 }}>
      {actionDelete && (
        <div style={{ textAlign: 'right' }}>
          <DeleteCategory
            refetch={refetch}
            id={category?.id} />
        </div>
      )}
      <br />
      <Label attached="top left">
        {index + 1}
      </Label>
      <Input
        fluid
        size="big"
        value={name}
        onChange={onChange}
        action={{
          icon: 'arrow alternate circle right',
          onClick: () => {
            if (typeof goTo === "function") goTo();
          }
        }}
      />
    </Segment>
  )
};

const UPDATE_FAMILY_PLAN = gql`
  mutation($input: FamilyPLanUpdateInput!){
    updateFamilyPlan(input: $input) {
      id
      name
      categories {
        id
        name
        order
      }
    }
  }
`;

export default FamilyPlanCategories;