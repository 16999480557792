import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import CustomTable from "../../../../../../Components/CustomTable";
import ModalDetails from "./ModalDetails";
import moment from "moment";

const HistoryFamilyPlan = ({ idFamilyMenmber, idFamilyPlan }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      size={"large"}
      trigger={
        <Button
          compact
          size="mini"
          onClick={() => setOpen(true)}
          content="Ver historial"
        />
      }
    >
      <Content
        setOpen={setOpen}
        idFamilyMenmber={idFamilyMenmber}
        idFamilyPlan={idFamilyPlan}
      />
    </Modal>
  );
};

const Content = ({ idFamilyMenmber, idFamilyPlan, setOpen }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [idFilter, setIdFilter] = useState("");
  const variables = {
    idFamilyMenmber,
    idFamilyPlan,
    page: { number: 1, limit: 100 },
  };
  if (idFilter.trim()) variables.idPeriod = idFilter;
  const { loading, error, data } = useQuery(HISTORY_FAMILY_PLAN, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Encuestador",
      accessor: "user.name",
    },
    {
      header: "Fecha inicio",
      accessor: "startDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Fecha fin",
      accessor: "endDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Periodo",
      accessor: "period.name",
    },
    {
      header: "Fecha inicio (periodo)",
      accessor: "period.startDate",
    },
    {
      header: "Fecha fin (periodo)",
      accessor: "period.endDate",
    },
    {
      header: "Respondio",
      accessor: "answered",
      render: (answered) => {
        if (typeof answered === "boolean" && answered === true) return "Si";
        if (typeof answered === "boolean" && answered === false) return "No";
      },
    },
    {
      header: "Detalle",
      accessor: "familyPlanDetailQuestionAnswer",
      render: (familyPlanDetailQuestionAnswer) => (
        <ModalDetails data={familyPlanDetailQuestionAnswer} />
      ),
    },
  ];

  return (
    <>
      <Modal.Header>Historial</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Group inline>
            <Form.Dropdown
              size="mini"
              options={data?.periodList?.items || []}
              placeholder="Periodos"
              value={idFilter}
              label="Periodos"
              clearable
              onChange={(e, { value }) => setIdFilter(value)}
            />
          </Form.Group>
        </Form>

        <CustomTable
          currentPage={currentPage}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          totalPages={data?.periodList?.totalPages || 0}
          data={data?.familyPlanAnswerHeaders || []}
          loading={loading}
          error={error}
          columns={columns}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </>
  );
};

const HISTORY_FAMILY_PLAN = gql`
  query FamilyPlanAnswerHeaders(
    $idFamilyPlan: ID
    $idFamilyMenmber: ID
    $idPeriod: ID
    $page: PageInput!
  ) {
    familyPlanAnswerHeaders(
      idFamilyPlan: $idFamilyPlan
      idFamilyMenmber: $idFamilyMenmber
      idPeriod: $idPeriod
    ) {
      id
      startDate
      endDate
      period {
        id
        name
        startDate
        endDate
      }
      user {
        id
        name
      }
      answered
      familyPlanDetailQuestionAnswer {
        id
        enunciated
        nameQuestion
        familyPlanDetailAnswers {
          answer
          answerQuestion
        }
      }
    }
    periodList(page: $page, type: FAMILYPLAN) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default HistoryFamilyPlan;
