import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tab } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputsEducation from "./InputsEducation";
import InputsEmployability from "./InputsEmployability";
import InputsFamily from "./InputsFamily";
import InputsHealth from "./InputsHealth";
import LeaderInput from "./LeaderInput";
import { onlyNumbers } from "../../../helpers/inputs";

const UpdateFamilyMenmber = ({ user, triggerFluid = true, refetch }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [secondName, setSecondName] = useState(user?.secondName || "");
  const [surname, setSurname] = useState(user?.surname || "");
  const [secondSurname, setSecondSurname] = useState(user?.secondSurname || "");
  const [address, setAddress] = useState(user?.address || "");
  const [socialSecurity, setSocialSecurity] = useState(
    user?.socialSecurity?.id || ""
  );
  const { loading: loadingData, error, data } = useQuery(DATA);
  const [leader, setLeader] = useState(user?.familyLeader || null);
  const [jobPerformed, setJobPerformed] = useState(user?.jobPerformed || "");
  const [occupation, setOccupation] = useState(user?.occupation?.id || "");
  const [educativeEstablishment, setEducativeEstablishment] = useState(
    user?.educativeEstablishment || ""
  );
  const [educativeLevel, setEducativeLevel] = useState(
    user?.educativeLevel?.id || ""
  );
  const [title, setTitle] = useState(user?.title || "");
  const [lastGradeReached, setLastGradeReached] = useState(
    user?.lastGradeReached || ""
  );

  const [eps, setEps] = useState(user?.eps || "");
  const [inabilities, setInabilities] = useState(user?.inabilities?.id || "");

  const [neighborhood, setNeighborhood] = useState(user?.neighborhood || "");
  const [nationality, setNationality] = useState(user?.nationality?.id || "");
  const [regularization, setRegularization] = useState(
    user?.regularization?.id || ""
  );

  const [identification, setIdentification] = useState(
    user?.identification || ""
  );
  const [birthDate, setBirthDate] = useState(user?.birthDate || "");
  const [exitDate, setExitDate] = useState(user?.exitDate || "");

  const [isFamilyLeader, setIsFamilyLeader] = useState(
    user?.isFamilyLeader || ""
  );
  const [leaderRelation, setLeaderRelation] = useState(
    user?.leaderRelation?.id || ""
  );

  const [healthAffiliationDate, setHealthAffiliationDate] = useState(
    user?.healthAffiliationDate || ""
  );
  const [supportingElement, setSupportingElement] = useState(
    user?.supportingElement?.id || ""
  );
  const [sisben, setSisben] = useState(user?.sisben || "");
  const [sisbenCategory, setSisbenCategory] = useState(
    user?.sisbenCategory?.id || ""
  );
  const [pregnant, setPregnant] = useState(user?.pregnant || "");
  const [attendsPrenatalControl, setAttendsPrenatalControl] = useState(
    user?.attendsPrenatalControl || ""
  );
  const [PrenatalUnassistanceReason, setPrenatalUnassistanceReason] = useState(
    user?.PrenatalUnassistanceReason?.id || ""
  );

  const [studying, setStudying] = useState(user?.studying || "");
  const [NotStudyingReason, setNotStudyingReason] = useState(
    user?.NotStudyingReason?.id || ""
  );

  const [contractType, setContractType] = useState(
    user?.contractType?.id || ""
  );
  const [independentType, setIndependentType] = useState(
    user?.independentType || ""
  );
  const [undertakes, setUndertakes] = useState(user?.undertakes || "");
  const [undertakeName, setUndertakeName] = useState(user?.undertakeName || "");
  const [artOrWork, setArtOrWork] = useState(user?.artOrWork || "");
  const [artOrWorkType, setArtOrWorkType] = useState(user?.artOrWorkType || "");

  const [phone, setPhone] = useState(user?.phone || "");
  const [mail, setMail] = useState(user?.mail || "");
  const [typeIdentification, setTypeIdentification] = useState(
    user?.typeIdentification?.id || ""
  );
  const [gender, setGender] = useState(user?.gender?.id || "");
  const [maritalStatus, setMaritalStatus] = useState(
    user?.maritalStatus?.id || ""
  );
  const [expeditionDocumentDate, setExpeditionDocumentDate] = useState(
    user?.expeditionDocumentDate || ""
  );
  const [expirationDocumentDate, setExpirationDocumentDate] = useState(
    user?.expirationDocumentDate || ""
  );

  const onCompleted = async () => {
    setLoading(false);
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el usuario de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  // Validaciones condicionales, limpiarlos cuando no se vayan a usar

  useEffect(() => {
    if (nationality !== "67") {
      setRegularization("");
    }
  }, [nationality]);

  useEffect(() => {
    if (socialSecurity === "77") {
      setEps("");
    }
  }, [socialSecurity]);

  useEffect(() => {
    if (!["56", "57", "58", "59"].includes(educativeLevel)) {
      setTitle("");
    }
  }, [educativeLevel]);

  useEffect(() => {
    if (occupation !== "60") {
      setEducativeEstablishment("");
    }
    if (occupation !== "62") {
      setJobPerformed("");
    }
  }, [occupation]);

  const [mutation] = useMutation(UPDATE_FAMILY_MENMBER, {
    onCompleted,
    onError,
  });
  const [mutationCaracterizacion] = useMutation(CARATERISACION_UPDATE, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    if (new Date(birthDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de nacimiento es inválida.",
      });
    } else if (new Date(expeditionDocumentDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de expedición del documento es inválida.",
      });
    } else {
      setLoading(true);
      const input = {
        id: user?.id,
        idTypeIdentification: typeIdentification,
        firstName,
        secondName,
        surname,
        secondSurname,
        birthDate,
        residesInVillaCaracas: true,
        idGender: gender,
        idMaritalStatus: maritalStatus,
        nationality,
        occupation,
        educativeLevel,
        socialSecurity,
        inabilities,
        regularization,
        idFamilyLeader: leader?.id,
      };
      if (identification !== user?.identification)
        input.identification = identification;
      if (phone.trim()) input.phone = phone;
      if (mail.trim()) input.mail = mail;
      if (exitDate.trim()) input.exitDate = exitDate;
      if (expirationDocumentDate.trim())
        input.expirationDocumentDate = expirationDocumentDate;
      if (expeditionDocumentDate.trim())
        input.expeditionDocumentDate = expeditionDocumentDate;
      if (address.trim()) input.address = address;
      if (neighborhood.trim()) input.neighborhood = neighborhood;
      if (title.trim()) input.title = title;
      if (lastGradeReached.trim()) input.lastGradeReached = lastGradeReached;
      if (educativeEstablishment.trim())
        input.educativeEstablishment = educativeEstablishment;
      if (jobPerformed.trim()) input.jobPerformed = jobPerformed;
      if (eps.trim()) input.eps = eps;
      if (isFamilyLeader !== "") input.isFamilyLeader = isFamilyLeader;
      if (leaderRelation.trim()) input.leaderRelation = leaderRelation;
      if (healthAffiliationDate.trim())
        input.healthAffiliationDate = healthAffiliationDate;
      if (supportingElement.trim()) input.supportingElement = supportingElement;
      if (sisben !== "") input.sisben = sisben;
      if (sisbenCategory.trim()) input.sisbenCategory = sisbenCategory;
      if (pregnant !== "") input.pregnant = pregnant;
      if (attendsPrenatalControl !== "")
        input.attendsPrenatalControl = attendsPrenatalControl;
      if (PrenatalUnassistanceReason.trim())
        input.PrenatalUnassistanceReason = PrenatalUnassistanceReason;
      if (studying !== "") input.studying = studying;
      if (NotStudyingReason.trim()) input.NotStudyingReason = NotStudyingReason;
      if (contractType.trim()) input.contractType = contractType;
      if (independentType.trim()) input.independentType = independentType;
      if (undertakes !== "") input.undertakes = undertakes;
      if (undertakeName.trim()) input.undertakeName = undertakeName;
      if (artOrWork !== "") input.artOrWork = artOrWork;
      if (artOrWorkType.trim()) input.artOrWorkType = artOrWorkType;
      mutation({ variables: { input } });
    }
  };

  const disabled = () => {
    /*
    console.log({
      firstName: !firstName.trim(),
      surname: !surname.trim(),
      identification: !identification.trim(),
      birthDate: !birthDate.trim(),
      gender: !gender.trim(),
      maritalStatus: !maritalStatus.trim(),
      typeIdentification: !typeIdentification.trim(),
      nationality: !nationality.trim(),
      nationality2:
        !nationality.trim() && nationality === "67" && !regularization.trim(),
      leader: !leader,
      occupation: occupation === "60" && !educativeEstablishment.trim(),
      occupation2: occupation === "62" && !jobPerformed.trim(),
    });
    */
    if (!firstName.trim()) return true;
    if (!surname.trim()) return true;
    if (!identification.trim()) return true;
    if (!birthDate.trim()) return true;
    if (!gender.trim()) return true;
    if (!maritalStatus.trim()) return true;
    if (!typeIdentification.trim()) return true;
    if (!nationality.trim()) return true;
    if (nationality.trim() && nationality === "67" && !regularization.trim())
      return true;
    if (!leader) return true;
    if (occupation === "60" && !educativeEstablishment.trim()) return true;
    if (occupation === "62" && !jobPerformed.trim()) return true;
    if (loading) return true;
  };

  const panes = [
    {
      menuItem: "Datos básicos",
      render: () => (
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              value={firstName}
              onChange={(e, { value }) => setFirstName(value)}
              label="Primer nombre"
            />
            <Form.Input
              value={secondName}
              onChange={(e, { value }) => setSecondName(value)}
              label="Segundo nombre"
            />
            <Form.Input
              required
              value={surname}
              onChange={(e, { value }) => setSurname(value)}
              label="Primer apellido"
            />
            <Form.Input
              value={secondSurname}
              onChange={(e, { value }) => setSecondSurname(value)}
              label="Segundo apellido"
            />
          </Form.Group>
          <Form.Input
            required
            onKeyDown={onlyNumbers}
            value={identification}
            onChange={(e, { value }) => setIdentification(value)}
            label="Identificación"
          />
          <InputsFamily
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            gender={gender}
            setGender={setGender}
            typeIdentification={typeIdentification}
            setTypeIdentification={setTypeIdentification}
            setNationality={setNationality}
            nationality={nationality}
            regularization={regularization}
            setRegularization={setRegularization}
          />

          <Form.Group widths="equal">
            <Form.Input
              value={mail}
              onChange={(e, { value }) => setMail(value)}
              label="Correo (opcional)"
            />
            <Form.Input
              value={phone}
              onKeyDown={onlyNumbers}
              onChange={(e, { value }) => setPhone(value)}
              label="Teléfono (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Checkbox
              checked={isFamilyLeader}
              onChange={(e, { checked }) => {
                setLeaderRelation("");
                setIsFamilyLeader(checked);
              }}
              label="¿Es líder de familia?"
            />
          </Form.Group>

          {!isFamilyLeader && (
            <Form.Group widths="equal">
              <Form.Dropdown
                required={true}
                scrolling
                placeholder="Parentezco con el líder familiar"
                clearable={false}
                selection={true}
                search={true}
                value={leaderRelation}
                onChange={(e, { value }) => setLeaderRelation(value)}
                loading={loadingData}
                error={error}
                options={data?.relationship?.items || []}
                label="Parentezco con el líder familiar"
              />
            </Form.Group>
          )}
          <Form.Group widths="equal">
            <Form.Input
              value={address}
              onChange={(e, { value }) => setAddress(value)}
              label="Dirección (opcional)"
            />
            <Form.Input
              value={neighborhood}
              onChange={(e, { value }) => setNeighborhood(value)}
              label="Barrio (opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              type="date"
              value={birthDate}
              onChange={(e, { value }) => setBirthDate(value)}
              label="Fecha de nacimiento"
            />
            <Form.Input
              type="date"
              value={expeditionDocumentDate}
              onChange={(e, { value }) => setExpeditionDocumentDate(value)}
              label="Fecha de expedición del documento (Opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              type="date"
              value={expirationDocumentDate}
              onChange={(e, { value }) => setExpirationDocumentDate(value)}
              label="Fecha de vencimiento (Opcional)"
            />
            <Form.Input
              type="date"
              value={exitDate}
              onChange={(e, { value }) => setExitDate(value)}
              label="Fecha de salida (Opcional)"
            />
          </Form.Group>
        </Form>
      ),
    },
    {
      menuItem: "Datos salud",
      render: () => (
        <Form>
          <InputsHealth
            socialSecurity={socialSecurity}
            setSocialSecurity={setSocialSecurity}
            eps={eps}
            setEps={setEps}
            inabilities={inabilities}
            setInabilities={setInabilities}
            healthAffiliationDate={healthAffiliationDate}
            setHealthAffiliationDate={setHealthAffiliationDate}
            supportingElement={supportingElement}
            setSupportingElement={setSupportingElement}
            sisben={sisben}
            setSisben={setSisben}
            sisbenCategory={sisbenCategory}
            setSisbenCategory={setSisbenCategory}
            gender={gender}
            birthDate={birthDate}
            pregnant={pregnant}
            setPregnant={setPregnant}
            attendsPrenatalControl={attendsPrenatalControl}
            setAttendsPrenatalControl={setAttendsPrenatalControl}
            PrenatalUnassistanceReason={PrenatalUnassistanceReason}
            setPrenatalUnassistanceReason={setPrenatalUnassistanceReason}
          />
        </Form>
      ),
    },
    {
      menuItem: "Datos educación",
      render: () => (
        <Form>
          <InputsEducation
            lastGradeReached={lastGradeReached}
            setLastGradeReached={setLastGradeReached}
            title={title}
            setTitle={setTitle}
            educativeLevel={educativeLevel}
            setEducativeLevel={setEducativeLevel}
            studying={studying}
            setStudying={setStudying}
            NotStudyingReason={NotStudyingReason}
            setNotStudyingReason={setNotStudyingReason}
            birthDate={birthDate}
          />
        </Form>
      ),
    },
    {
      menuItem: "Datos empleabilidad",
      render: () => (
        <Form>
          <InputsEmployability
            jobPerformed={jobPerformed}
            setJobPerformed={setJobPerformed}
            educativeEstablishment={educativeEstablishment}
            setEducativeEstablishment={setEducativeEstablishment}
            occupation={occupation}
            setOccupation={setOccupation}
            contractType={contractType}
            setContractType={setContractType}
            independentType={independentType}
            setIndependentType={setIndependentType}
            undertakes={undertakes}
            undertakeName={undertakeName}
            setUndertakeName={setUndertakeName}
            setUndertakes={setUndertakes}
            setArtOrWork={setArtOrWork}
            setArtOrWorkType={setArtOrWorkType}
            artOrWork={artOrWork}
            artOrWorkType={artOrWorkType}
          />
        </Form>
      ),
    },
    {
      menuItem: "Líder familiar",
      render: () => (
        <Form>
          <LeaderInput
            leader={leader}
            setLeader={setLeader}
            refetch={refetch}
          />
        </Form>
      ),
    },
  ];

  const caracterizacionUpdate = () => {
    mutationCaracterizacion({ variables: { id: user?.id } });
  };
  return (
    <Modal
      trigger={
        <Form.Button onClick={() => setOpen(true)} icon="edit" size="tiny" />
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>Actualizar miembro familiar</p>{" "}
        <Button onClick={caracterizacionUpdate} content="Completar datos" />
      </Modal.Header>

      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={disabled()}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
};

const UPDATE_FAMILY_MENMBER = gql`
  mutation ($input: UpdateFamilyMenmberInput!) {
    updateFamilyMenmber(input: $input) {
      id
      firstName
      secondName
      surname
      secondSurname
      identification
      residesInVillaCaracas
      exitDate
      familyLeader {
        id
        name
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
      }
      mail
      phone
      birthDate
      gender {
        id
        name
      }
      maritalStatus {
        id
        name
      }
      typeIdentification {
        id
        name
      }
      regularization {
        id
        name
      }
      nationality {
        id
        name
      }
      socialSecurity {
        id
        name
      }
      educativeLevel {
        id
        name
      }
      occupation {
        id
        name
      }
      familyLeader {
        id
        identification
        name
        houseNumber
      }
      inabilities {
        id
        name
      }
      title
      lastGradeReached
      educativeEstablishment
      jobPerformed
      eps
      neighborhood
      address
      expeditionDocumentDate
      expirationDocumentDate
    }
  }
`;

const CARATERISACION_UPDATE = gql`
  mutation ($id: ID!) {
    caraterisacionUpdate(id: $id) {
      id
      identification
      residesInVillaCaracas
      exitDate
      familyLeader {
        id
        name
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
      }
      mail
      phone
      birthDate
      gender {
        id
        name
      }
      maritalStatus {
        id
        name
      }
      typeIdentification {
        id
        name
      }
      regularization {
        id
        name
      }
      nationality {
        id
        name
      }
      socialSecurity {
        id
        name
      }
      educativeLevel {
        id
        name
      }
      occupation {
        id
        name
      }
      familyLeader {
        id
        identification
        name
        houseNumber
      }
      inabilities {
        id
        name
      }
      title
      lastGradeReached
      educativeEstablishment
      jobPerformed
      eps
      neighborhood
      address
      expeditionDocumentDate
      expirationDocumentDate
    }
  }
`;

const DATA = gql`
  query {
    relationship: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "20" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;
export default UpdateFamilyMenmber;
