import { useQuery, gql } from "@apollo/client";
import React from "react";
import {
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
  Tab,
} from "semantic-ui-react";
import FamilyPlanContent from "./ContentList/FamilyPlan";
//import { upperCaseInitialEachWord } from "../../../Utils";
//import OfferContent from "./ContentList/Offer";
import SurveyContent from "./ContentList/Survey";

const OptionsList = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const handleBack = () => {
    history.replace("/dashboard/Responder");
  };
  const { loading, error, data } = useQuery(FAMILY_MEMBER, {
    variables: { id },
  });
  const panes = [
    {
      menuItem: "Encuestas",
      render: () => (
        <Tab.Pane>
          <div style={{ minHeight: 500, marginTop: 20 }}>
            <SurveyContent idFamilyMember={id} />
          </div>
        </Tab.Pane>
      ),
    },
    /* {
      menuItem: "Ofertas",
      render: () => (
        <Tab.Pane>
          <div style={{ minHeight: 500, marginTop: 20 }}>
            <OfferContent idFamilyMember={id} />
          </div>
        </Tab.Pane>
      ),
    },*/
    {
      menuItem: "Plan familiar",
      render: () => (
        <Tab.Pane>
          <div style={{ minHeight: 500, marginTop: 20 }}>
            <FamilyPlanContent idFamilyMember={id} />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  if (loading)
    return (
      <Segment style={{ minHeight: "100vh" }}>
        <Dimmer inverted active>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      </Segment>
    );

  if (error)
    return (
      <Segment style={{ minHeight: "100vh" }}>
        <Header as="h3" style={{ margin: 20 }}>
          <Icon onClick={handleBack} link size="big" name="arrow left" />
          Listado de elementos
        </Header>
        <Message>Ha ocurrido un error.</Message>
      </Segment>
    );

  const { familyMenmber } = data;

  if (!familyMenmber)
    return (
      <Segment style={{ minHeight: "100vh" }}>
        <Header as="h3" style={{ margin: 20 }}>
          <Icon onClick={handleBack} link size="big" name="arrow left" />
          Listado de elementos
        </Header>
        <Message>El miembro de familia que intentas buscar no existe.</Message>
      </Segment>
    );

  return (
    <Container>
      <Segment>
        <Header as="h3" style={{ marginTop: 20 }}>
          <Icon onClick={handleBack} link size="big" name="arrow left" />
          Listado de elementos
        </Header>
        <Header as="h4">
          <Icon size="big" name="user" />
          {familyMenmber?.firstName} {familyMenmber?.secondName}{" "}
          {familyMenmber?.surname} {familyMenmber?.secondSurname}
        </Header>
        <Tab panes={panes} />
      </Segment>
    </Container>
  );
};

const FAMILY_MEMBER = gql`
  query ($id: ID) {
    familyMenmber(id: $id) {
      id
      firstName
      secondName
      surname
      secondSurname
      identification
    }
  }
`;

export default OptionsList;
