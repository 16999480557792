import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Card, Message } from "semantic-ui-react";
import CustomCardList from "../../../../../Components/CustomCardList";
import History from "../../History/HistorySurvey";
import ModalAnwserSurvey from "./ModalAnwserSurvey";


const SurveyContent = ({ idFamilyMember }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data } = useQuery(SURVEYS, {
    fetchPolicy: "network-only",
    variables: { page: { number: currentPage, limit: 10 } },
  });

  return (
    <CustomCardList
      loading={loading}
      error={error}
      currentPage={currentPage}
      totalPages={data?.surveyList?.totalPages || 0}
      onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
      data={data?.surveyList?.items || []}>
      {({ list }) => {
        if (!list.length) return <Message>No hay encuestas disponibles.</Message>
        return (
          <Card.Group stackable>
            {list.map((survey, key) => (
              <Card key={key}>
                <Card.Content>
                  <Card.Header>{survey?.name}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <ModalAnwserSurvey
                      idFamilyMember={idFamilyMember}
                      idSurvey={survey?.id} />
                    <History
                      idSurvey={survey?.id}
                      idFamilyMenmber={idFamilyMember} />
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )
      }}
    </CustomCardList>
  );
}


const SURVEYS = gql`
query($page: PageInput!){
  surveyList(page: $page, filter: { status: ACTIVE}) {
    page {
      number
      limit
    }
    totalPages
    count
    items {
      id
      name
    }
  }
}
`;

export default SurveyContent;