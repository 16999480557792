import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Accordion,
  Form,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
  Statistic,
} from "semantic-ui-react";
import ColumnChart from "../../../Components/ColumnChart";
import { parseDataToJson } from "../../../Utils";
import "./dashboard.css";

const ProjectReports = () => {
  const [filter, setFilter] = useState({
    idProject: "1",
    idSurvey: "1",
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const { loading, error, data } = useQuery(REPORTS, {
    fetchPolicy: "network-only",
    variables: filter,
  });

  const {
    data: dataProject,
    loading: loadingProject,
    error: errorProject,
  } = useQuery(PROJECT, {
    variables: {
      page: { number: 1, limit: 1000 },
      filter: { status: "ACTIVE", name: "" },
    },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ height: 300 }}>
        <Loader
          style={{ marginTop: 300 }}
          size="massive"
          active
          inline="centered"
        />
      </div>
    );

  if (error)
    return (
      <div style={{ height: 300 }}>
        <Message style={{ height: 300 }} error>
          Lo sentimos, ha ocurrido un error.
        </Message>
      </div>
    );

  return (
    <>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column>
            <Form.Group inline>
              <Form.Dropdown
                label="Proyecto"
                placeholder="Búsqueda por Proyecto"
                value={filter.idProject}
                scrolling
                loading={loadingProject}
                error={errorProject}
                onChange={(e, { value }) => {
                  console.log(value);
                  setFilter({ ...filter, idProject: value });
                }}
                options={dataProject?.projectList?.items}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment style={{ textAlign: "center" }}>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportTotalHouses?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>Total de familias creadas</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportTotalCreatedMembers?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>
                  Total de beneficiarios creados
                </Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment style={{ textAlign: "center" }}>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportyProjectFamilyLeaders?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>Familias en Proyectos</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportProjectFamilyMembers?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>
                  Miembros familiares en Proyectos
                </Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                Gráficas generales
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por género"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMenmberByGender?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por grupo etario"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByAgeGroup?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por nacionalidad"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByNationality?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                Gráficas de Projectos
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por género"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMenmberByGenderProject?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por grupo etario"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByAgeGroupProject?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por nacionalidad"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByNationality?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const REPORTS = gql`
  query ($idSurvey: ID, $idProject: ID) {
    reportFamilyMenmberByGenderProject(idProject: $idProject) {
      data {
        name
        y
        z
      }
    }
    reportFamilyMenmberByGender {
      data {
        name
        y
        z
      }
    }
    reportFamilyMemberByNationality(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportTotalHouses {
      total
    }
    reportTotalCreatedMembers {
      total
    }
    reportFamilyMemberByAgeGroup(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportFamilyMemberByAgeGroupProject(idProject: $idProject) {
      data {
        name
        y
        z
      }
    }
    reportProjectFamilyMembers(idProject: $idProject) {
      total
    }
    reportyProjectFamilyLeaders(idProject: $idProject) {
      total
    }
  }
`;

const PROJECT = gql`
  query ($page: PageInput!, $filter: ProjectFilter!) {
    projectList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        key: id
        text: name
        workTeam {
          id
        }
        beneficiaries {
          id
        }
        status
      }
    }
  }
`;

export default ProjectReports;
