import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Label,
  Modal,
  Segment,
} from "semantic-ui-react";
import ColumnChart from "../../../Components/ColumnChart";
import CustomTable from "../../../Components/CustomTable";
import { calculateAge, parseDataToJson } from "../../../Utils";
import InputsFamily from "../Surveys/ResponseSurvey/InputsFamily";
import DownloadReport from "./DownloadReport";
import FilterSurvey from "./FilterSurvey";
import ModalDetails from "./ModalDetails";
import Swal from "sweetalert2";

const optionsRangeAge = [
  { text: "0 años a 5 años", value: "[0, 5]", key: 1 },
  { text: "6 años a 11 años", value: "[6, 11]", key: 2 },
  { text: "12 años a 18 años", value: "[12, 18]", key: 3 },
  { text: "19 años a 26 años", value: "[19, 26]", key: 4 },
  { text: "27 años a 59 años", value: "[27, 59]", key: 5 },
  { text: "Más de 60", value: "[60, 130]", key: 6 },
];

const FilterReport = ({ handleDeleteFilter }) => {
  const [idPeriod, setPeriod] = useState("");
  const [nameQuestion, setNameQuestion] = useState("");
  const [typeChart, setTypeChart] = useState("column");
  const [openModal, setOpenModal] = useState(false);
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [typeIdentification, setTypeIdentification] = useState("");
  const [idSurvey, setIdSurvey] = useState("");
  const [idCategorie, setIdCategorie] = useState("");
  const [idQuestion, setIdQuestion] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const fields = [];
  const fieldsSurvey = {};
  const variables = { page: { number: currentPage, limit: 10 } };
  if (gender.trim()) fields.push({ value: gender, variable: "GENDER" });
  if (ageRange?.length > 0)
    fields.push({
      values: JSON.parse(ageRange),
      variable: "BIRTH_DATE",
      comparate: "BETWEEN",
    });
  if (maritalStatus.trim())
    fields.push({ value: maritalStatus, variable: "MARITAL_STATUS" });
  if (typeIdentification.trim())
    fields.push({ value: typeIdentification, variable: "TYPE_IDENTIFICATION" });
  if (idSurvey.trim()) {
    fieldsSurvey.idSurvey = idSurvey;
    variables.idSurvey = idSurvey;
  }
  if (idPeriod.trim()) {
    fieldsSurvey.idPeriod = idPeriod;
    variables.idPeriod = idPeriod;
  }
  if (idQuestion.trim()) {
    fieldsSurvey.idQuestion = idQuestion;
    variables.idQuestion = idQuestion;
  }
  if (optionsValue.length > 0) {
    fieldsSurvey.options = optionsValue;
    variables.options = optionsValue;
  }

  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      fieldsSurvey.initialDate = `${initialDate} 00:00:00`;
      fieldsSurvey.finalDate = `${finalDate} 23:59:59`;
    }
  }

  const filter = { fields };
  if (Object.keys(fieldsSurvey).length > 0) filter.fieldsSurvey = fieldsSurvey;
  variables.filter = filter;
  variables.inFilter = Boolean(
    fields.length || Object.keys(fieldsSurvey).length
  );
  const { loading, error, data } = useQuery(REPORT_FAMILY_MENMBERS, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Identificación",
      accessor: "identification",
    },
    {
      header: "Nombre",
      render: ({ firstName, secondName, surname, secondSurname }) =>
        `${firstName}${
          secondName?.length > 0 ? " " + secondName : ""
        } ${surname}${secondSurname?.length > 0 ? " " + secondSurname : ""}`,
    },
    {
      header: "Género",
      accessor: "gender",
      render: (gender) => gender?.name,
    },
    {
      header: "Estado civil",
      accessor: "maritalStatus",
      render: (maritalStatus) => maritalStatus?.name,
    },
    {
      header: "Número Telefónico",
      render: ({ phone }) => phone,
    },
    {
      header: "Tipo identificación",
      accessor: "typeIdentification",
      render: (typeIdentification) => typeIdentification?.name,
    },
    {
      header: "Fecha de nacimiento",
      accessor: "birthDate",
    },
    {
      header: "Edad",
      accessor: "birthDate",
      render: (birthDate) =>
        birthDate ? `${calculateAge(birthDate)} Años` : null,
    },
    {
      header: "Encuestador",
      accessor: "responseQuestion",
      render: (responseQuestion) => {
        return responseQuestion.length > 0
          ? responseQuestion[0].user.name
          : null;
      },
    },
    {
      header: "Detalle",
      accessor: "responseQuestion",
      render: (responseQuestion) => <ModalDetails data={responseQuestion} />,
    },
  ];

  const handleClearFilter = () => {
    setGender("");
    setPeriod("");
    setMaritalStatus("");
    setTypeIdentification("");
    setIdSurvey("");
    setIdQuestion("");
    setIdCategorie("");
    setInitialDate("");
    setFinalDate("");
    setOptionsValue([]);
  };

  const visualColumn = Boolean(
    data?.reportFamilyMenmber?.options?.length || [].length
  );

  return (
    <>
      <Form>
        <Form.Field>
          <Button
            onClick={handleDeleteFilter}
            icon="delete"
            color="google plus"
            floated="right"
          />
        </Form.Field>
        <Form.Group inline>
          <Form.Field>
            <Label
              content={`Cantidad (${data?.reportFamilyMenmber?.count || 0})`}
            />
          </Form.Field>
          <Form.Field>
            <Modal
              onClose={() => setOpenModal(false)}
              open={openModal}
              trigger={
                <Button
                  onClick={() => setOpenModal(true)}
                  icon="filter"
                  compact
                  size="mini"
                  content="Filtros"
                />
              }
            >
              <Modal.Header>Construir filtros</Modal.Header>
              <Modal.Content scrolling>
                <div style={{ minHeight: 200 }}>
                  <Segment>
                    <Label attached="top right">Campos de la encuesta</Label>
                    <FilterSurvey
                      setNameQuestion={setNameQuestion}
                      handleClearFilter={handleClearFilter}
                      idPeriod={idPeriod}
                      setPeriod={setPeriod}
                      idCategorie={idCategorie}
                      setIdCategorie={setIdCategorie}
                      idSurvey={idSurvey}
                      setIdSurvey={setIdSurvey}
                      idQuestion={idQuestion}
                      setIdQuestion={setIdQuestion}
                      optionsValue={optionsValue}
                      setOptionsValue={setOptionsValue}
                      initialDate={initialDate}
                      finalDate={finalDate}
                      setInitialDate={setInitialDate}
                      setFinalDate={setFinalDate}
                    />
                  </Segment>
                  <Segment>
                    <Label attached="top right">Campos del usuario</Label>
                    <Form>
                      <InputsFamily
                        clearable
                        required={false}
                        search={false}
                        widths={null}
                        gender={gender}
                        setGender={setGender}
                        maritalStatus={maritalStatus}
                        setMaritalStatus={setMaritalStatus}
                        typeIdentification={typeIdentification}
                        setTypeIdentification={setTypeIdentification}
                      />
                      <Form.Dropdown
                        value={ageRange}
                        label="Rango de edad"
                        placeholder="Filtrar por rango de edad"
                        clearable
                        scrolling
                        onChange={(e, { value }) => setAgeRange(value)}
                        options={optionsRangeAge}
                        selection
                      />
                    </Form>
                  </Segment>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  icon="check"
                  style={{ backgroundColor: "#045A73", color: "white" }}
                  onClick={() => setOpenModal(false)}
                  content="Filtrar"
                />
                <Button
                  icon="trash"
                  onClick={handleClearFilter}
                  content="Vaciar filtros"
                />
              </Modal.Actions>
            </Modal>
          </Form.Field>
          {data?.reportFamilyMenmber?.count > 0 && (
            <DownloadReport variables={{ ...variables }} />
          )}
        </Form.Group>
      </Form>
      <br />
      <Grid stackable>
        <Grid.Column width={16}>
          <Segment>
            <CustomTable
              loading={loading}
              error={error}
              columns={columns}
              currentPage={currentPage}
              onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
              totalPages={data?.reportFamilyMenmber?.totalPages || 0}
              data={data?.reportFamilyMenmber?.items}
            />
          </Segment>
        </Grid.Column>
        {visualColumn && (
          <Grid.Column width={16}>
            <Segment>
              <Dropdown
                value={typeChart}
                onChange={(e, { value }) => setTypeChart(value)}
                selection
                label="Tipo de grafico"
                options={[
                  { text: "Torta", value: "pie", key: 1 },
                  { text: "Columna", value: "column", key: 2 },
                ]}
              />
              <ColumnChart
                title={nameQuestion}
                type={typeChart}
                data={parseDataToJson(data?.reportFamilyMenmber?.options) || []}
              />
            </Segment>
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

const REPORT_FAMILY_MENMBERS = gql`
  query (
    $filter: ReportFilterInput!
    $inFilter: Boolean!
    $page: PageInput!
    $idSurvey: ID
    $idPeriod: ID
    $idQuestion: ID
    $options: [String!]
  ) {
    reportFamilyMenmber(filter: $filter, page: $page) @include(if: $inFilter) {
      count
      page {
        number
        limit
      }
      totalPages
      options {
        name
        y
      }
      items {
        id
        firstName
        secondName
        surname
        secondSurname
        birthDate
        identification
        phone
        responseQuestion(idSurvey: $idSurvey, idPeriod: $idPeriod) {
          id
          user {
            name
          }
          detailQuestionAnswers(idQuestion: $idQuestion, options: $options) {
            id
            enunciated
            nameQuestion
            detailAnswers {
              id
              answer
              answerQuestion
            }
          }
        }
        gender {
          id
          name
        }
        maritalStatus {
          id
          name
        }
        typeIdentification {
          id
          name
        }
      }
    }
  }
`;

export default FilterReport;
