import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import moment from "moment";
import TokenValue from "./tokenValue";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "REMOVED", key: "REMOVED" },
];

const Sesions = ({ permissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [userName, setUserName] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const filter = { status };

  const { data, loading, error, refetch } = useQuery(GET_SESSIONS, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Fecha",
      accessor: "sessionDate",
      render: (sessionDate) =>
        moment(sessionDate).format("DD-MM-YYYY h:mm:ss A"),
    },
    {
      header: "Usuario",
      accessor: "user.name",
    },
    {
      header: "Id Sesión",
      accessor: "sessionId",
    },
    {
      header: "Token",
      render: (parameter) => (
        <TokenValue reload={refetch} values={parameter.sessionToken} />
      ),
    },
  ];

  const searchUserName = (userName) => {
    setDataFiltered(
      data?.sessionList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(userName?.toLowerCase())
      )
    );
  };

  const clearFilter = () => {
    setUserName("");
    setStatus("ACTIVE");
    setCurrentPage(1);
    setDataFiltered([]);
  };

  return (
    <div>
      <h1>Sesiones</h1>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Label
              content={`Cantidad (${
                dataFiltered.length > 0
                  ? dataFiltered.length
                  : data?.sessionList?.count || 0
              })`}
            />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchUserName(userName),
            }}
            value={userName}
            size="mini"
            onChange={(e, { value }) => setUserName(value)}
            placeholder="Búsqueda por encuestador"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.sessionList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.sessionList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_SESSIONS = gql`
  query SessionList($page: PageInput!, $filter: SessionFilter!) {
    sessionList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        sessionDate
        sessionId
        sessionToken
        status
        user {
          name
        }
      }
    }
  }
`;

export default Sesions;
