import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Header, Modal } from "semantic-ui-react";
import { Questions } from "../..";

const QuestionRecursive = ({ idOption, actionCreate, actionDelete, actionUpdate, idFamilyPlan, count }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open} trigger={
        <Form.Button
          onClick={() => setOpen(true)}
          compact
          size="mini"
          content="Preguntas" />
      }>
      <ModalContent
        idFamilyPlan={idFamilyPlan}
        actionCreate={actionCreate}
        actionDelete={actionDelete}
        actionUpdate={actionUpdate}
        idOption={idOption}
        count={count}/>
    </Modal>
  );
}

const ModalContent = ({ idOption, actionCreate, actionDelete, actionUpdate, idFamilyPlan, count }) => {
  const { loading, error, data, refetch } = useQuery(QUESTIONS_OF_OPTION,
    {
      variables: { idOption },
      fetchPolicy: "network-only"
    }
  );

  if (loading) return "Cargando...";
  if (error) return "Error...";

  const { familyPlanOption } = data;

  return (
    <>
      <Modal.Header>
        Opción: {familyPlanOption?.response}
      </Modal.Header>
      <Modal.Content scrolling>
        <Header>
          Preguntas
        </Header>
        <Questions
          refetch={refetch}
          idOption={idOption}
          idFamilyPlan={idFamilyPlan}
          actionCreate={actionCreate}
          actionUpdate={actionUpdate}
          actionDelete={actionDelete}
          questions={familyPlanOption?.questions}
          count={count+1}/>
      </Modal.Content>
    </>
  );

}

const QUESTIONS_OF_OPTION = gql`
query($idOption: ID!) {
  familyPlanOption(id: $idOption) {
    id
    response
    questions {
      id
      order
      name
      required
      type {
        id
        name
      }
      enunciated
      options {
        id
        order
        optionQuestion
        questionText
        response
        negativeIndicator
      }
    }
  }
}
`;

export default QuestionRecursive;