import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Header, Modal } from "semantic-ui-react";
import { Questions } from "./questions";
import './modified.css';

const QuestionRecursive = ({ idOption, idSurvey, count }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <Form.Button
          onClick={() => setOpen(true)}
          compact
          size="mini"
          content="Preguntas"
        />
      }
    >
      <ModalContent idSurvey={idSurvey} idOption={idOption} count={count} />
    </Modal>
  );
};

const ModalContent = ({ idOption, idSurvey, count }) => {
  const { loading, error, data, refetch } = useQuery(QUESTIONS_OF_OPTION, {
    variables: { idOption },
    fetchPolicy: "network-only",
  });

  if (loading) return "Cargando...";
  if (error) return "Error...";

  const { option } = data;

  return (
    <>
      <Modal.Header>Opción: {option?.response}</Modal.Header>
      <Modal.Content scrolling>
        <Header>Preguntas</Header>
        <Questions
          refetch={refetch}
          idOption={idOption}
          idSurvey={idSurvey}
          questions={option?.questions}
          count={count + 1}
        />
      </Modal.Content>
    </>
  );
};

const QUESTIONS_OF_OPTION = gql`
  query ($idOption: ID!) {
    option(id: $idOption) {
      id
      response
      questions {
        id
        order
        name
        required
        type {
          id
          name
        }
        enunciated
        options {
          id
          order
          optionQuestion
          questionText
          response
        }
      }
    }
  }
`;

export default QuestionRecursive;
