import React from "react";
import { Form } from "semantic-ui-react";

const Selector = ({
  data = [],
  value,
  label = "",
  selection = false,
  onChange,
  placeholder = "",
  clearable = true,
  loading = false,
  error = false
}) => {


  if (loading) return (
    <Form.Dropdown
      label={label}
      placeholder={placeholder}
      loading
      selection
      options={[]} />
  );

  if (error) return (
    <Form.Dropdown
      label={label}
      placeholder={placeholder}
      error
      selection
      options={[]} />
  );

  return (
    <Form.Dropdown
      label={label}
      selection={selection}
      placeholder={placeholder}
      value={value}
      scrolling
      clearable={clearable}
      onChange={onChange}
      options={data} />
  );
};

export default Selector;