import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeleteOption = ({ id, refetch }) => {
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    setLoading(false);
    await refetch();
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(DELETE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    Swal.fire({
      icon: "warning",
      title: "Advertencia",
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
      text: "¿Está seguro de eliminar esta opción?",
    }).then(action => {
      if (action.isConfirmed) {
        setLoading(true);
        mutation({
          variables: {
            id
          }
        });
      }
    })
  }
  return (
    <Button
      onClick={handleMutation}
      loading={loading}
      disabled={loading}
      color="google plus"
      icon="delete" />
  );
}

const DELETE_OPTION = gql`
  mutation($id: ID!) {
    updateFamilyPlanOption(input: { id: $id, status: REMOVED }) {
      id
      status
    }
  }
`;

export default DeleteOption;