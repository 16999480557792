import React, { Fragment, useState } from "react";
import { Button, Modal, Table, Message } from "semantic-ui-react";

const ModalDetails = ({ data = [] }) => {
  const [open, setOpen] = useState(false);

  if (!data.length)
    return (
      <div style={{ overflowX: "overlay" }}>
        <Message>No hay datos.</Message>
      </div>
    );

  const HeaderAnswers = () => (
    <Table striped stackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{ backgroundColor: "#045A73", color: "white" }}
          >
            Pregunta
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{ backgroundColor: "#045A73", color: "white" }}
          >
            Respuesta
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((question, key) => (
          <Fragment key={key}>
            <Table.Row>
              <Table.Cell>{question?.enunciated}</Table.Cell>
              <Table.Cell>
                {question?.detailAnswers
                  ?.map((answer) => answer?.answer)
                  ?.join(", ")}
              </Table.Cell>
            </Table.Row>
            {question?.nameQuestion && (
              <Table.Row>
                <Table.Cell>{question?.enunciated} - {question?.nameQuestion}</Table.Cell>
                <Table.Cell>
                  {question?.detailAnswers
                    ?.map((answer) => answer?.answerQuestion)
                    ?.join(", ")}
                </Table.Cell>
              </Table.Row>
            )}
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <Button
          size="tiny"
          compact
          onClick={() => setOpen(true)}
          content="ver detalle"
        />
      }
    >
      <Modal.Header>Detalle de respuestas</Modal.Header>
      <Modal.Content scrolling>
        <HeaderAnswers />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalDetails;
