import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateFamilyMenmber = ({ offer, triggerFluid = false, refetch }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(offer?.answeDate?.substring(0, 10) || "");
  const [endDate, setEndDate] = useState(
    offer?.endDate?.substring(0, 10) || ""
  );
  
  const [status, setStatus] = useState(offer?.status || "");
  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
    { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
  ];

  const onCompleted = async () => {
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado la oferta de manera exitosa.",
    }).then(() => {
      refetch();
      setOpen(false);
    });
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_OFFER_HEADER, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    const input = {
      id: offer?.id,
      answeDate: date,
      status,
      endDate: endDate
    };
    if (status.trim()) input.status = status;

    mutation({ variables: { input } });
  };

  const disabled = () => {
    if (!date.trim()) return true;
    if (!status.trim()) return true;
  };

  return (
    <Modal
      trigger={
        triggerFluid ? (
          <Button
            onClick={() => setOpen(true)}
            content="Editar"
            size="tiny"
            fluid
          />
        ) : (
          <Button onClick={() => setOpen(true)} icon="edit" size="tiny" />
        )
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Actualizar Oferta</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              type="date"
              value={date}
              onChange={(e, { value }) => setDate(value)}
              label="Fecha ingreso"
            />
            <Form.Input
              required
              type="date"
              value={endDate}
              onChange={(e, { value }) => setEndDate(value)}
              label="Fecha de finalización"
            />
            <Form.Dropdown
              label="Estado"
              value={status}
              selection
              required
              onChange={(e, { value }) => setStatus(value)}
              options={options}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={disabled()}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
};

const UPDATE_OFFER_HEADER = gql`
  mutation ($input: UpdateOfferHeaderInput!) {
    updateOfferHeader(input: $input) {
      id
      answeDate
      endDate
      offer {
        id
        name
      }
      status
    }
  }
`;

export default UpdateFamilyMenmber;
