import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import {
  Checkbox,
  Dimmer,
  Form,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import ConvertToLeaderModal from "./ConvertToLeaderModal";
import TransferModal from "./TranferModal";

const Transfer = ({ search, filterType }) => {
  const variables = {};
  const [selected, setSeleted] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data } = useQuery(FAMILY_MEMBERS, {
    variables,
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <Segment style={{ height: 300 }}>
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      </Segment>
    );

  if (error) return <Message>Lo sentimos, ha ocurrido un error.</Message>;
  const { familyMenmbers } = data;

  if (!familyMenmbers?.length)
    return (
      <div style={{ marginTop: 30 }}>
        <Message>No se ha encontrado ningún miembro de familia.</Message>
      </div>
    );

  const handleSelected = (value, checked) => {
    const tempSelected = [...selected];
    if (checked) {
      const index = tempSelected.findIndex((v) => v?.id === value?.id);
      if (index === -1) tempSelected.push(value);
      setSeleted(tempSelected);
    } else {
      setSeleted(tempSelected.filter((v) => v?.id !== value?.id));
      setAllSelected(false);
    }
  };

  const handleAllSelected = (e, { checked }) => {
    setAllSelected(checked);
    if (checked) {
      setSeleted(familyMenmbers);
    } else {
      setSeleted([]);
    }
  };

  const onChecked = (id) => {
    const index = selected.findIndex((v) => v?.id === id);
    return index !== -1 || allSelected;
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Form>
        <Form.Group>
          <Form.Checkbox
            checked={allSelected || selected.length === familyMenmbers?.length}
            onChange={handleAllSelected}
            label={`Seleccionar todos (${selected?.length})`}
          />
          <TransferModal
            selected={selected}
            setSelected={setSeleted}
            setAllSelected={setAllSelected}
          />
        </Form.Group>
      </Form>
      {familyMenmbers?.map((familyMenmber) => (
        <Segment key={familyMenmber?.id}>
          <Label
            style={{ backgroundColor: "#045A73", color: "white" }}
            attached="top"
          >
            Miembro familiar
          </Label>
          <br />
          <div style={{ float: "right" }}>
            <Checkbox
              checked={onChecked(familyMenmber?.id)}
              onChange={(e, { checked }) =>
                handleSelected(familyMenmber, checked)
              }
            />
          </div>
          {familyMenmber?.isLeader && (
            <Label
              content="Lider Familiar"
              style={{ backgroundColor: "#045A73", color: "white" }}
              ribbon
            />
          )}
          <Table basic="very" fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Identificación</Table.HeaderCell>
                {!familyMenmber?.isLeader && (
                  <Table.HeaderCell>Lider familiar</Table.HeaderCell>
                )}
                {!familyMenmber?.isLeader && (
                  <Table.HeaderCell>Acción</Table.HeaderCell>
                )}
                {familyMenmber?.isLeader && (
                  <Table.HeaderCell>Dirección</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      {familyMenmber?.firstName} {familyMenmber?.secondName}{" "}
                      {familyMenmber?.surname} {familyMenmber?.secondSurname}
                      <Header.Subheader>Miembro Familiar</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon name="address card" />
                  {familyMenmber?.identification}
                </Table.Cell>
                {!familyMenmber?.isLeader && (
                  <Table.Cell>
                    <Header as="h5">
                      <Header.Content>
                        {familyMenmber?.familyLeader?.name}
                        <Header.Subheader>
                          <Icon name="home" />{" "}
                          {familyMenmber?.familyLeader?.houseNumber}
                        </Header.Subheader>
                        <Header.Subheader>
                          <Icon name="address card" />{" "}
                          {familyMenmber?.familyLeader?.identification}
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                )}
                {!familyMenmber?.isLeader && (
                  <Table.Cell>
                    <ConvertToLeaderModal familyMember={familyMenmber} />
                  </Table.Cell>
                )}
                {familyMenmber?.isLeader && (
                  <Table.Cell>
                    <Icon name="home" />
                    {familyMenmber?.familyLeader?.houseNumber}
                  </Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      ))}
    </div>
  );
};

const FAMILY_MEMBERS = gql`
  query ($name: String, $identification: String, $houseNumber: String) {
    familyMenmbers(
      name: $name
      identification: $identification
      houseNumber: $houseNumber
    ) {
      id
      firstName
      secondName
      surname
      secondSurname
      isLeader
      identification
      familyLeader {
        id
        name
        identification
        houseNumber
      }
    }
  }
`;
export default Transfer;
