import { gql, useMutation } from "@apollo/client";
import React from "react";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeleteFamilyMember = ({ member, refetch, setFilter }) => {
  const onCompleted = async () => {
    await refetch();
    setFilter({});

    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el miembro de manera exitosa.",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error al eliminar el miembro.");
    }
  };
  const [mutation] = useMutation(DELETE_FAMILY_MENMBER, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    if (member?.responseQuestion?.length > 0) {
      Swal.fire({
        icon: "error",
        text: "Lo sentimos, no se puede eliminar a un miembro familiar que haya contestado al menos una caracterización",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        icon: "warning",
        text: "¿Está seguro que desea eliminar este miembro familiar?",
        showCancelButton: true,
        showConfirmButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          mutation({ variables: { id: member.id } });
        }
      });
    }
  };
  return (
    <>
      <Button
        icon="delete"
        onClick={() => handleMutation()}
        size="tiny"
        compact
      />
    </>
  );
};

const DELETE_FAMILY_MENMBER = gql`
  mutation ($id: ID!) {
    deleteFamilyMember(id: $id) {
      firstName
      secondName
      surname
      secondSurname
      id
      identification
      residesInVillaCaracas
      exitDate
      isFamilyLeader

      offers {
        id
        offer {
          id
          name
          offerType {
            id
            name
          }
        }
      }
      leaderRelation {
        id
        name
      }
      healthAffiliationDate
      supportingElement {
        id
        name
      }
      sisben
      sisbenCategory {
        id
        name
      }
      pregnant
      attendsPrenatalControl
      PrenatalUnassistanceReason {
        id
        name
      }
      studying
      NotStudyingReason {
        id
        name
      }
      contractType {
        id
        name
      }
      independentType
      undertakes
      undertakeName
      artOrWork
      artOrWorkType
      familyLeader {
        id
        name
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
      }
      mail
      phone
      birthDate
      gender {
        id
        name
      }
      maritalStatus {
        id
        name
      }
      typeIdentification {
        id
        name
      }
      regularization {
        id
        name
      }
      nationality {
        id
        name
      }
      socialSecurity {
        id
        name
      }
      educativeLevel {
        id
        name
      }
      occupation {
        id
        name
      }
      inabilities {
        id
        name
      }
      title
      familyLeader {
        id
        identification
        name
        houseNumber
      }
      lastGradeReached
      educativeEstablishment
      jobPerformed
      eps
      neighborhood
      address
      expeditionDocumentDate
      expirationDocumentDate
      responseQuestion {
        id
      }
    }
  }
`;

export default DeleteFamilyMember;
