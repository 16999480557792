import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateCategory = ({ refetch }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    setLoading(false);
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_CATEGORY, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        name
      }
    });
  }
  return (
    <Popup
      content={
        <>
          <Form>
            <Form.TextArea
              value={name}
              onChange={(e, { value }) => setName(value)}
              label='Nombre' />
            <Form.Button
              content="Guardar"
              onClick={handleMutation}
              loading={loading}
              disabled={!name.trim() || loading}
              style={{ backgroundColor: "#045A73", color: "white" }} />
          </Form>
        </>
      }
      on='click'
      pinned
      trigger={<Button style={{ backgroundColor: "#045A73", color: "white" }} content='Crear categoría' />}
    />
  );
}
const CREATE_CATEGORY = gql`
  mutation($name: String!){
    createCategory(name: $name) {
      id
    }
  }
`;

export default CreateCategory;