import React, { useState } from "react";
import {
  Container,
  Message,
  Header,
  Button,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { getData, setData } from "../../../../Utils";
import CreateFamilyLeader from "./CreateFamlilyLeader";
import LeadersList from "./LeadersList";
import MemberList from "./MemberList";
import Transfer from "./Transfer";

const filterTypeText = {
  ID: {
    placeholder: "Buscar por identificación",
    message: "Digite la identificación para su busqueda."
  },
  NAME: {
    placeholder: "Buscar por nombre",
    message: "Digite un nombre para su busqueda."
  },
  HOUSE_NUMBER: {
    placeholder: "Buscar por número de casa",
    message: "Digite un número de casa para su busqueda."
  },
}

const ResponseSurvey = ({ history }) => {
  const [memberType, setMemberType] = useState(
    getData("memberType") || "LEADER"
  );
  const [search, setSearch] = useState(
    getData("searchFamilyMenmber") || ""
  );
  const [filterType, setFilterType] = useState(
    getData("filterType") || "ID"
  );
  const [fakeSearch, setFakeSearch] = useState(
    getData("searchFamilyMenmber") || ""
  );

  const handleFilterType = (e, { value }) => {
    setFilterType(value);
    setData("filterType", value)
    setSearch("");
    setFakeSearch("");
    setData("searchFamilyMenmber", "");
  };

  const handleDeleteSearch = () => {
    setSearch("");
    setFakeSearch("");
    setData("searchFamilyMenmber", "");
  }

  const goTo = (idFamilyMenmber) => {
    history.replace(`/dashboard/Responder/Listado/${idFamilyMenmber}`);
  }

  return (
    <Container>
      <div style={{ textAlign: 'center' }}>
        <Button.Group widths='3'>
          <Button
            style={memberType === "LEADER" ? { backgroundColor: "#045A73", color: "white" } : null}
            onClick={() => {
              setMemberType("LEADER");
              setData("memberType", "LEADER");
            }}
            content="Lider familiar" />
          <Button
            style={memberType === "MEMBER" ? { backgroundColor: "#045A73", color: "white" } : null}
            onClick={() => {
              setMemberType("MEMBER");
              setData("memberType", "MEMBER");
            }}
            content="Miembro familiar" />
          <Button
            style={memberType === "TRANSFER" ? { backgroundColor: "#045A73", color: "white" } : null}
            onClick={() => {
              setMemberType("TRANSFER");
              setData("memberType", "TRANSFER");
            }}
            content="Transferir" />
        </Button.Group>
      </div>
      <Header as="h3">
        {memberType === "LEADER" ? "Buscar por lider familiar" : "Buscar por miembro familiar"}
      </Header>
      {memberType === "LEADER" && (
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <CreateFamilyLeader />
        </div>
      )}
      <Dropdown
        value={filterType}
        onChange={handleFilterType}
        options={[
          { text: 'Identificación', value: 'ID', key: 1 },
          { text: 'Nombre', value: 'NAME', key: 2 },
          { text: '# Casa', value: 'HOUSE_NUMBER', key: 3 }
        ]
        } />
      <Input
        fluid
        iconPosition={fakeSearch.trim() ? 'left' : null}
        icon={fakeSearch.trim() ? {
          name: 'delete',
          circular: true,
          link: true,
          onClick: () => handleDeleteSearch()
        } : null}
        placeholder={filterTypeText[filterType]?.placeholder}
        value={fakeSearch}
        action={{
          content: "Buscar",
          icon: "search",
          style: { backgroundColor: "#045A73", color: "white" },
          onClick: () => {
            setSearch(fakeSearch);
            setData("searchFamilyMenmber", fakeSearch);
          },
        }}
        onChange={(e, { value }) => setFakeSearch(value)}
      />
      {!search?.trim() && (
        <Message>{filterTypeText[filterType]?.message}</Message>
      )}
      {(search.trim() && memberType === "LEADER") && (
        <LeadersList goTo={goTo} search={search} filterType={filterType} />
      )}
      {(search.trim() && memberType === "MEMBER") && (
        <MemberList goTo={goTo} search={search} filterType={filterType} />
      )}
      {(search.trim() && memberType === "TRANSFER") && (
        <Transfer search={search} filterType={filterType} />
      )}
    </Container>
  );
};


export default ResponseSurvey;
