import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Dimmer,
  Loader,
  Message,
  Container,
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  List,
  Button,
} from "semantic-ui-react";
import Swal from "sweetalert2";
const UpdateOffer = () => {
  let { id } = useParams();
  let history = useHistory();
  const handleBack = () => {
    history.replace("/dashboard/Ofertas");
  };

  const { loading, error, data, refetch } = useQuery(OFFER, {
    variables: { id },
  });

  if (loading)
    return (
      <div>
        <Dimmer active inverted>
          <Loader size="large">Cargando</Loader>
        </Dimmer>
      </div>
    );

  if (error)
    return (
      <div style={{ margin: 30 }}>
        <Header as="h1" style={{ margin: 20 }}>
          <Icon onClick={handleBack} link size="big" name="arrow left" />
          Oferta
        </Header>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );

  return (
    <>
      {data?.offer ? (
        <Content handleBack={handleBack} refetch={refetch} data={data?.offer} />
      ) : (
        <>
          <Header as="h1" style={{ margin: 20 }}>
            <Icon onClick={handleBack} link size="big" name="arrow left" />
            Oferta
          </Header>
          <Message>La oferta a la que intentas acceder no existe.</Message>
        </>
      )}
    </>
  );
};

const Content = ({ data, refetch, handleBack }) => {
  const [idWorkTeam, setIdWorkTeam] = useState(data?.workTeam?.id || 0);
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: userData,
  } = useQuery(USERS, {
    variables: {
      page: {
        number: 1,
        limit: 10000,
      },
      filter: {
        status: "ACTIVE",
        idWorkTeam: idWorkTeam,
        //idUserGroup: 16,
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingType,
    error: errorType,
    data: typeData,
  } = useQuery(OFFERTYPES);

  const {
    loading: loadingAllies,
    error: errorAllies,
    data: allyData,
  } = useQuery(ALLIES, {
    variables: {
      page: {
        number: 1,
        limit: 10000,
      },
      filter: {
        status: "ACTIVE",
      },
    },
    fetchPolicy: "network-only",
  });

  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
    { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
  ];
  const [name, setName] = useState(data?.name || "");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(data?.status || "");
  const [loading, setLoading] = useState(false);
  const [encargado, setEncargado] = useState(data?.inCharge?.id || "");
  const [selectedAlly, setSelectedAlly] = useState("");
  const [allies, setAllies] = useState(data?.allies || []);
  const [activities, setActivities] = useState(data?.activities || []);
  const [offerType, setTypeOffer] = useState(data?.offerType?.id || "");
  const [program, setProgram] = useState(data?.program?.id || "");

  const {
    data: programData,
    error: errorProgram,
    loading: loadingProgram,
  } = useQuery(GET_PROGRAM, {
    variables: {
      page: { number: 1, limit: 10000 },
      filter: { status: "ACTIVE", name: "" },
    },
    fetchPolicy: "network-only",
  });
  const onCompleted = async () => {
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado la oferta de manera exitosa.",
    });
    setLoading(false);
    handleBack();
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_OFFER, { onError, onCompleted });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        updateOfferId: data.id,
        name,
        program,
        description,
        inCharge: encargado,
        status,
        allies: allies.map((e) => {
          return { id: e.id, name: e.name };
        }),
        activities: activities.map((e) => ({ id: e.id, activity: e.activity })),
        type: offerType,
        idWorkTeam,
      },
    });
  };

  const getAlly = (x) => {
    let _x = null;
    allyData?.allyList?.items?.forEach((e) => {
      if (e.id === x) _x = e;
    });
    return _x;
  };

  const addAlly = (e) => {
    e.preventDefault();
    if (selectedAlly !== "") {
      setAllies([...allies, getAlly(selectedAlly)]);
      setSelectedAlly("");
    } else {
      Swal.fire({
        icon: "warning",
        text: "No se ha seleccionado ningún aliado para agregar",
      });
    }
  };
  const addActivity = (e) => {
    e.preventDefault();
    if (description !== "") {
      setActivities([...activities, { activity: description }]);
      setDescription("");
    } else {
      Swal.fire({
        icon: "warning",
        text: "No se ha digitado una actividad para agregar",
      });
    }
  };

  return (
    <Container text>
      <Header as="h1" style={{ margin: 20 }}>
        <Icon onClick={handleBack} link size="big" name="arrow left" />
        Oferta
      </Header>
      <Segment style={{ margin: 20 }}>
        <Form>
          <Form.Input
            label="Nombre"
            value={name}
            required
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Dropdown
            inline
            required
            size="mini"
            options={userData?.workTeamListByUser || []}
            placeholder="Equipo de Trabajo"
            value={idWorkTeam}
            label="Equipo de Trabajo"
            clearable
            onChange={(e, { value }) => setIdWorkTeam(value)}
          />

          <Form.Dropdown
            required={true}
            scrolling
            placeholder="Tipo de Oferta"
            selection={true}
            search={true}
            value={offerType}
            error={errorType}
            onChange={(e, { value }) => setTypeOffer(value)}
            loading={loadingType}
            options={typeData?.offerType?.items || []}
            label="Tipo de Oferta"
          />

          <Form.Dropdown
            required={true}
            scrolling
            placeholder="Programa"
            selection={true}
            search={true}
            value={program}
            error={errorProgram}
            onChange={(e, { value }) => setProgram(value)}
            loading={loadingProgram}
            options={programData?.programList?.items || []}
            label="Programa"
          />
          <Form.TextArea
            value={description}
            required
            onChange={(e, { value }) => setDescription(value)}
            label="Actividad"
          />
          <Button onClick={addActivity}>Añadir actividad</Button>
          <Header as="h5">Actividades</Header>

          <List divided verticalAlign="middle">
            {activities.length > 0 ? (
              <>
                {activities.map((e, row) => (
                  <List.Item key={`activity${e.id || row}`}>
                    <List.Content floated="right">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setActivities(
                            activities.filter((e, row2) => row2 !== row)
                          );
                        }}
                        icon="delete"
                      />
                    </List.Content>
                    {e.activity}
                  </List.Item>
                ))}
              </>
            ) : (
              "Actualmente no hay aliados asociados"
            )}
          </List>
          <Form.Dropdown
            label="Encargado"
            value={encargado}
            onChange={(e, { value }) => setEncargado(value)}
            scrolling={true}
            clearable={true}
            selection={true}
            error={errorUsers}
            search={true}
            required
            loading={loadingUsers}
            options={
              userData?.userList?.items.map((item) => {
                return { ...item, value: item.id, text: item.name };
              }) || []
            }
          />
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            selection
            required
            options={options}
          />
          <Divider />
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Form.Dropdown
              label="Aliado"
              value={selectedAlly}
              onChange={(e, { value }) => setSelectedAlly(value)}
              scrolling={true}
              clearable={true}
              selection={true}
              error={errorAllies}
              search={true}
              required
              loading={loadingAllies}
              options={
                allyData?.allyList?.items
                  .filter((e) => {
                    let ids = allies.map((e) => e.id);
                    return !ids.includes(e.id);
                  })
                  .map((item) => {
                    return { ...item, value: item.id, text: item.name };
                  }) || []
              }
            />
            <Button onClick={addAlly}>Añadir aliado</Button>
            <Header as="h5">Aliados asociados</Header>
            <List divided verticalAlign="middle">
              {allies.length > 0 ? (
                <>
                  {allies.map((e) => (
                    <List.Item key={`ally${e.id}`}>
                      <List.Content floated="right">
                        <Button
                          onClick={() =>
                            setAllies(allies.filter((el) => el.id !== e.id))
                          }
                        >
                          X
                        </Button>
                      </List.Content>
                      {e.name}
                    </List.Item>
                  ))}
                </>
              ) : (
                "Actualmente no hay aliados asociados"
              )}
            </List>
          </div>
          <Form.Button
            loading={loading}
            disabled={!name.trim() || loading}
            onClick={handleMutation}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Guardar"
          />
        </Form>
      </Segment>
    </Container>
  );
};

const UPDATE_OFFER = gql`
  mutation (
    $updateOfferId: ID!
    $name: String
    $description: String
    $status: Status
    $inCharge: ID!
    $allies: [OfferAlly]!
    $activities: [OfferActivityInput]!
    $type: ID!
    $program: ID!
    $idWorkTeam: ID!
  ) {
    updateOffer(
      id: $updateOfferId
      name: $name
      description: $description
      status: $status
      inCharge: $inCharge
      allies: $allies
      activities: $activities
      type: $type
      program: $program
      idWorkTeam: $idWorkTeam
    ) {
      id
      name
      description
      user {
        id
        name
      }
      offerType {
        id
        name
      }
    }
  }
`;

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

const ALLIES = gql`
  query ($page: PageInput!, $filter: AllyFilter!) {
    allyList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
  }
`;
const OFFERTYPES = gql`
  query {
    offerType: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "27" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

const OFFER = gql`
  query Query($id: ID!) {
    offer(id: $id) {
      id
      name
      description
      status
      workTeam {
        id
      }
      inCharge {
        id
        name
      }
      allies {
        id
        name
      }
      activities {
        id
        activity
      }
      offerType {
        id
        name
      }
      program {
        id
        name
      }
    }
  }
`;

const GET_PROGRAM = gql`
  query ProgramList($page: PageInput!, $filter: ProgramFilter!) {
    programList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default UpdateOffer;
