import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Image, Container } from "semantic-ui-react";
import { getData, setData } from "../../Utils";
import { client } from "../../client";
import Swal from "sweetalert2";
import LOGO_BID from "../../assets/bid.png";
import { EMAIL_REGEX } from "../../Utils/Constants";
import { Link } from "react-router-dom";
import InputTypePassword from "../../Components/InputTypePassword";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (getData("meBid")?.userGroup?.name === "Reportes")
      history.replace("/dashboard/Reportes");
    if (getData("meBid")?.userGroup?.name === "Encuestadores")
      history.replace("/dashboard/Responder");
    else if (getData("userBid")) history.replace("/dashboard");
  }, [history]);
  const onCompleted = ({ loginAdmin }) => {
    setLoading(false);
    setData("userBid", loginAdmin);
    client
      .query({ query: ME })
      .then((res) => {
        if (res.data) {
          setData("meBid", res.data.me);
          if (res.data.me?.userGroup?.name === "Reportes")
            history.replace("/dashboard/Reportes");
          if (res.data.me?.userGroup?.name === "Encuestadores")
            history.replace("/dashboard/Responder");
          else history.replace("/dashboard");
        }
      })
      .catch((err) => console.log(err));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [login] = useMutation(LOGIN, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    login({
      variables: {
        email: email.trim(),
        password,
      },
    });
  };
  return (
    <Grid stackable verticalAlign="middle">
      <Grid.Column width={6}>
        <Container style={{ padding: 30 }} textAlign="center">
          <Form size="large">
            <div style={{ margin: 20 }}>
              {
                <Image
                  style={{ display: "initial" }}
                  size="small"
                  src={LOGO_BID}
                  alt="logo bid"
                />
              }
            </div>
            <Form.Input
              fluid
              icon="user"
              value={email}
              error={
                email.trim() && !EMAIL_REGEX.test(email)
                  ? "Ingresa una dirección de correo electrónico válida."
                  : null
              }
              placeholder="Correo"
              onChange={(e, { value }) => setEmail(value)}
            />
            <InputTypePassword
              fluid
              value={password}
              placeholder="Contraseña"
              onChange={(e, { value }) => setPassword(value)}
            />
            <Button
              loading={loading}
              disabled={!EMAIL_REGEX.test(email) || !password.trim() || loading}
              onClick={handleMutation}
              style={{ backgroundColor: "#045A73", color: "white" }}
              fluid
              size="large"
            >
              Iniciar sesión
            </Button>
            <br />
            <br />
            <Link style={{ color: "#045A73" }} to="/recover-account">
              ¿Has olvidado la contraseña?
            </Link>
          </Form>
        </Container>
      </Grid.Column>
      <Grid.Column className="login" width={10} />
    </Grid>
  );
};

const LOGIN = gql`
  mutation login($email: EmailAddress!, $password: String!) {
    loginAdmin(email: $email, password: $password)
  }
`;

const ME = gql`
  query me {
    me {
      id
      name
      document
      email
      userGroup {
        id
        name
      }
    }
  }
`;

export default Login;
