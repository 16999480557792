import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Accordion,
  Form,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
  Statistic,
} from "semantic-ui-react";
import ColumnChart from "../../../Components/ColumnChart";
import { parseDataToJson } from "../../../Utils";
import "./dashboard.css";

const GeneralReports = () => {
  const [filter, setFilter] = useState({
    idSurvey: "1",
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const { loading, error, data } = useQuery(REPORTS, {
    fetchPolicy: "network-only",
    variables: filter,
  });

  const {
    data: dataSurvey,
    loading: loadingSurvey,
    error: errorSurvey,
  } = useQuery(SURVEYS, {
    variables: { page: { number: 1, limit: 100 }, filter },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ height: 300 }}>
        <Loader
          style={{ marginTop: 300 }}
          size="massive"
          active
          inline="centered"
        />
      </div>
    );

  if (error)
    return (
      <div style={{ height: 300 }}>
        <Message style={{ height: 300 }} error>
          Lo sentimos, ha ocurrido un error.
        </Message>
      </div>
    );

  return (
    <>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column>
            <Form.Group inline>
              <Form.Dropdown
                label="Encuesta"
                placeholder="Búsqueda por Encuesta"
                value={filter.idSurvey}
                scrolling
                loading={loadingSurvey}
                error={errorSurvey}
                onChange={(e, { value }) =>
                  setFilter({ ...filter, idSurvey: value })
                }
                options={dataSurvey?.surveyList?.items}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment style={{ textAlign: "center" }}>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportTotalHouses?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>Total de familias creadas</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportTotalCreatedMembers?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>
                  Total de beneficiarios creados
                </Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment style={{ textAlign: "center" }}>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportyAnsweredFamilyLeaders?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>Familias encuestadas</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {new Intl.NumberFormat("en-US").format(
                    data?.reportAnsweredFamilyMembers?.total
                  ) || "Cargando..."}
                </Statistic.Value>
                <Statistic.Label>
                  Miembros familiares encuestados
                </Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                Gráficas generales
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por género"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMenmberByGender?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por grupo etario"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByAgeGroup?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por nacionalidad"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByNationality?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                Gráficas de encuesta
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por género"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMenmberByGenderSurvey?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por grupo etario"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByAgeGroupSurvey?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ColumnChart
                          title="Usuarios por nacionalidad"
                          data={
                            parseDataToJson(
                              data?.reportFamilyMemberByNationality?.data
                            ) || []
                          }
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const REPORTS = gql`
  query ($idSurvey: ID) {
    reportFamilyMenmberByGender {
      data {
        name
        y
        z
      }
    }
    reportFamilyMenmberByGenderSurvey(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportFamilyMemberByNationality(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportTotalHouses {
      total
    }
    reportTotalCreatedMembers {
      total
    }
    reportFamilyMemberByAgeGroup(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportFamilyMemberByAgeGroupSurvey(idSurvey: $idSurvey) {
      data {
        name
        y
        z
      }
    }
    reportAnsweredFamilyMembers(idSurvey: $idSurvey) {
      total
    }
    reportyAnsweredFamilyLeaders(idSurvey: $idSurvey) {
      total
    }
  }
`;

const SURVEYS = gql`
  query ($page: PageInput!) {
    surveyList(page: $page, filter: {}) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        key: id
        text: name
      }
    }
  }
`;

export default GeneralReports;
