import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateAction = ({ refetch }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha creado la acción de manera exitosa.'
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_ACTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(false);
    mutation({ variables: { name } });
  }

  return (
    <Popup
      content={
        <>
          <Form>
            <Form.Input
              label="Nombre"
              value={name}
              onChange={(e, { value }) => setName(value)} />
            <Form.Button
              content="Guardar"
              loading={loading}
              disabled={!name.trim() || loading}
              onClick={handleMutation} />
          </Form>
        </>
      }
      on='click'
      positionFixed
      trigger={<Form.Button icon="add" size="tiny" compact />}
    />
  );
}

const CREATE_ACTION = gql`
  mutation($name: String!){
    createAction(name: $name) {
      id
    }
  }
`;

export default CreateAction;