import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";

const ReportDetail = ({ id, pollster }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={<Button compact size="tiny" icon="arrow circle right" onClick={() => setOpen(true)} />}>
      <Modal.Header>
        Detalle de asignaciones
      </Modal.Header>
      <Content
        id={id}
        pollster={pollster} />
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
}

const Content = ({ id, filter, pollster }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    id,
    filter,
    page: { number: currentPage, limit: 10 }
  };
  const { loading, error, data } = useQuery(POLLSTER_DETAIL,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  );

  const columns = [
    {
      header: 'Identificación',
      accessor: 'identification'
    },
    {
      header: 'Etapa',
      accessor: 'etapa'
    },
    {
      header: 'Nombre',
      accessor: 'name'
    },
    {
      header: '#Casa',
      accessor: 'houseNumber'
    },
  ];


  return (
    <>
      <div style={{ paddingTop: 10, paddingLeft: 20 }}>
        <Form>
          <Form.Group>
            <Form.Field>
              <Label content={`Encuestador (${pollster})`} />
            </Form.Field>
            <Form.Field>
              <Label content={`Familias asignadas (${data?.detailReportFamily?.count || 0})`} />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <Modal.Content scrolling>
        <CustomTable
          currentPage={currentPage}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          totalPages={data?.detailReportFamily?.totalPages || 0}
          data={data?.detailReportFamily?.items || []}
          loading={loading}
          error={error}
          columns={columns}
        />
      </Modal.Content>
    </>
  );
}

const POLLSTER_DETAIL = gql`
query(
  $id: ID!
  $page: PageInput!
) {
  detailReportFamily(id: $id, page: $page) {
    page {
      number
      limit
    }
    count
    totalPages
    items {
        id
        name
        identification
        houseNumber
        etapa
    }
  }
}
`;

export default ReportDetail;