import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateDocument = ({ user, refetch, setOpen }) => {

  const [document, setDocument] = useState(user?.document || "");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el documento de manera exitosa.'
    }).then(() => setOpen(false))
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_DOCUMENT, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const variables = {
      id: user?.id,
      document
    };

    mutation({ variables });
  }
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            required
            label="Documento"
            value={document}
            onChange={(e, { value }) => setDocument(value)} />
        </Form.Group>
      </Form>
      <div style={{ marginTop: 20 }}>
        <Button
          content="Guardar"
          style={{ backgroundColor: "#045A73", color: "white" }}
          loading={loading}
          disabled={!document.trim() || loading}
          onClick={handleMutation} />
        <Button
          content="Cancelar"
          onClick={() => setOpen(false)} />
      </div>
    </>
  );
}

const UPDATE_DOCUMENT = gql`
  mutation($id: ID! $document: String!){
    updateDocument(id: $id document: $document) {
      id
    }
  }
`;


export default UpdateDocument;