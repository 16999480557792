import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Grid, Image, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import LOGO_BID from "../../assets/bid.png";
import InputTypePassword from "../../Components/InputTypePassword";
import { PASSWORD_REGEX } from "../../Utils/Constants";
const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';

const RecoverPassword = ({ location, history }) => {
  let params = (new URLSearchParams(location?.search));
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  let validateToken = params.get('validateToken') || "";

  const onCompleted = () => {
    setLoading(false);
    Swal.fire('Su contraseña se actualizó exitosamente.', '', 'success')
      .then(() => history.replace("/"))
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const validatePassword = () => {
    if (password.trim() && !PASSWORD_REGEX.test(password)) return passwordError;
    return null;
  }


  const [mutation] = useMutation(RECOVER_PASSWORD, { onCompleted, onError });
  const handleMutation = () => {
    const variables = {
      newPassword: password,
      validateToken
    };
    mutation({ variables });
  }
  return (
    <Grid className="login" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 367 }}>
        <Form size='large'>
          <Segment stacked>
            <div style={{ margin: 20, textAlign: 'center' }}>
              {<Image src={LOGO_BID} alt="logo bid" />}
            </div>
            <InputTypePassword
              label="Nueva contraseña"
              placeholder="Contraseña *"
              value={password}
              error={validatePassword() || password !== repeatPassword}
              required
              onChange={(e, { value }) => setPassword(value)} />
            <InputTypePassword
              label="Repetir contraseña"
              placeholder="Repetir contraseña *"
              error={password !== repeatPassword}
              value={repeatPassword}
              required
              onChange={(e, { value }) => setRepeatPassword(value)} />
            <Button
              onClick={handleMutation}
              disabled={!PASSWORD_REGEX.test(password) || !password.trim() || password.trim() !== repeatPassword.trim() || loading}
              style={{ backgroundColor: '#045A73', color: '#fff' }}>
              Enviar
            </Button>
            <Button
              onClick={() => history.replace('/')}
              style={{ backgroundColor: '#045A73', color: '#fff' }}>
              Volver
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

const RECOVER_PASSWORD = gql`
  mutation ($newPassword: String!, $validateToken: String!) {
    updatePasswordWithToken(
      newPassword: $newPassword
      validateToken: $validateToken
    )
  }
`;

export default RecoverPassword;