import { gql, useMutation } from "@apollo/client";
import React from "react";
import { Button, Message } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeleteAnswerHistory = ({ edit, answerHeader, refetch }) => {
  const onCompleted = async () => {
    await refetch();

    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el historial de encuestas de manera exitosa.",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error al eliminar el miembro.");
    }
  };

  const [mutation] = useMutation(DELETE_FAMILY_MENMBER, {
    onCompleted,
    onError,
  });

  if (!edit) {
    return (
      <div style={{ overflowX: "overlay" }}>
        <Message>
          Usted no tiene permiso para editar un historial de encuesta.
        </Message>
      </div>
    );
  }
  const handleMutation = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Está seguro que desea eliminar este historial de encuestas?",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        mutation({ variables: { id: answerHeader.id } });
      }
    });
  };
  return (
    <>
      <Button
        icon="delete"
        onClick={() => handleMutation()}
        size="tiny"
        compact
      />
    </>
  );
};

const DELETE_FAMILY_MENMBER = gql`
  mutation ($id: ID!) {
    deleteAnswerHeader(id: $id) {
      id
    }
  }
`;

export default DeleteAnswerHistory;
