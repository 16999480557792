import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal, Button, Form, Tab } from "semantic-ui-react";
import Swal from "sweetalert2";
import SelectUserGroup from "./SelectUserGroup";
import UpdateDocument from "./UpdateDocument";
import UpdateMail from "./UpdateMail";
import UpdatePassword from "./UpdatePassword";

const genderOptions = [
  { text: "Masculino", value: "Masculino", key: 1 },
  { text: "Femenino", value: "Femenino", key: 2 },
  { text: "Otro", value: "Otro", key: 3 }
];

const UpdateUser = ({ user, refetch }) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [secondName, setSecondName] = useState(user?.secondName || "");
  const [firstSurname, setFirstSurname] = useState(user?.firstSurname || "");
  const [secondSurname, setSecondSurname] = useState(user?.secondSurname || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [gender, setGender] = useState(user?.gender || "");
  const [idUserGroup, setIdUserGroup] = useState(user?.userGroup?.id || "");
  const [loading, setLoading] = useState(false);

  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
    { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
  ];
  const [status, setStatus] = useState(user?.status  || "");
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el usuario de manera exitosa.'
    }).then(() => {
      setOpen(false)
    })

  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_USER, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      id: user?.id,
      secondName,
      secondSurname,
      phone,
      gender,
      status
    };
    if (firstName.trim()) input.firstName = firstName;
    if (firstSurname.trim()) input.firstSurname = firstSurname;
    if (idUserGroup.trim()) input.idUserGroup = idUserGroup;
    mutation({ variables: { input } });
  }

  const disabled = () => {
    if (!firstName.trim()) return true;
    if (!firstSurname.trim()) return true;
    if (!idUserGroup.trim()) return true;
    if (!firstName.trim()) return true;
    if (loading) return true;
  }

  const panes = [
    {
      menuItem: 'Datos personales',
      render: () => (
        <Tab.Pane>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Primer nombre"
                value={firstName}
                onChange={(e, { value }) => setFirstName(value)} />
              <Form.Input
                label="Segundo nombre"
                value={secondName}
                onChange={(e, { value }) => setSecondName(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Primer apellido"
                value={firstSurname}
                onChange={(e, { value }) => setFirstSurname(value)} />
              <Form.Input
                label="Segundo apellido"
                value={secondSurname}
                onChange={(e, { value }) => setSecondSurname(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Teléfono"
                type="number"
                value={phone}
                onChange={(e, { value }) => setPhone(value)} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                selection
                clearable
                options={genderOptions}
                label="Género"
                value={gender}
                onChange={(e, { value }) => setGender(value)} />
              <SelectUserGroup
                required
                label="Grupo usuario"
                value={idUserGroup}
                onChange={(e, { value }) => setIdUserGroup(value)}
              />
              <Form.Dropdown
              label="Estado"
              required
              selection
              value={status}
              onChange={(e, { value }) => setStatus(value)}
              options={options} />
            </Form.Group>
          </Form>
          <div style={{ marginTop: 20 }}>
            <Button
              content="Guardar"
              style={{ backgroundColor: "#045A73", color: "white" }}
              loading={loading}
              disabled={disabled()}
              onClick={handleMutation} />
            <Button
              content="Cancelar"
              onClick={() => setOpen(false)} />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Correo', render: () => (
        <Tab.Pane>
          <UpdateMail
            refetch={refetch}
            setOpen={setOpen}
            user={user} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Documento', render: () => (
        <Tab.Pane>
          <UpdateDocument
            refetch={refetch}
            setOpen={setOpen}
            user={user} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Contraseña', render: () => (
        <Tab.Pane>
          <UpdatePassword
            refetch={refetch}
            setOpen={setOpen}
            user={user} />
        </Tab.Pane>
      )
    },
  ]


  return (
    <>
      <Button size="tiny" compact icon="edit" onClick={() => setOpen(true)} />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>
          Editar usuario
        </Modal.Header>
        <Modal.Content>
          <Tab panes={panes} />

        </Modal.Content>

      </Modal>
    </>
  );
}



const UPDATE_USER = gql`
  mutation($input: UpdateUserInput!){
    updateUser(input: $input) {
      id
    }
  }
`;

export default UpdateUser;