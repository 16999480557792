import { useQuery, gql } from "@apollo/client";
import React from "react";
import { Form } from "semantic-ui-react";

const TypeQuestion = ({
  onChange,
  value,
  placeholder,
  label,
  name
}) => {
  const { loading, error, data } = useQuery(PARAMETER_TYPE_QUESTIONS,
    { fetchPolicy: "network-only" }
  );

  if (loading) return (
    <Form.Dropdown
      selection
      label={label}
      loading
      name={name}
      options={[]}
      placeholder={placeholder} />
  );

  if (error) return (
    <Form.Dropdown
      selection
      label={label}
      error
      name={name}
      options={[]}
      placeholder={placeholder} />
  );

  const { parameter } = data;

  return (
    <Form.Dropdown
      selection
      label={label}
      onChange={onChange}
      value={value}
      name={name}
      options={parameter?.parameterValues || []}
      placeholder={placeholder} />
  );
}


const PARAMETER_TYPE_QUESTIONS = gql`
  query {
    parameter(id: "1") {
      id
      parameterValues {
        key: id
        value: id
        text: name
      }
    }
  }
`;

export default TypeQuestion;