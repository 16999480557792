import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeletePermit = ({ id, reload, reloadPermissions }) => {
  const [open, setOpen] = useState(false);
  const onCompleted = async () => {
    await reload();
    await reloadPermissions();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha eliminado el permiso de manera exitosa.'
    }).then(() => setOpen(false))
  }
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(DELETE_PERMIT, { onCompleted, onError });
  const handleMutation = () => {
    mutation({ variables: { id } });
  }
  return (
    <div>
      <Button size="mini" content="Eliminar" icon="delete" onClick={() => setOpen(true)} />
      <Confirm
        size="tiny"
        confirmButton="Aceptar"
        cancelButton="Cancelar"
        open={open}
        content='¿Desea eliminar este permiso?'
        onCancel={() => setOpen(false)}
        onConfirm={handleMutation}
      />
    </div>
  );
};

const DELETE_PERMIT = gql`
  mutation deletePermit($id: ID!) {
    deletePermission(id: $id)
  }
`;
export default DeletePermit;