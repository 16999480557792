import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal, Table } from "semantic-ui-react";
import Swal from "sweetalert2";

const ProjectModal = ({ triggerFluid = false, idFamilyLeader }) => {
  const [open, setOpen] = useState(false);
  const variables = {
    page: { number: 1, limit: 100000 },
    filter: { status: "ACTIVE" },
  };
  const [project, setProject] = useState("");

  const { data, loading, error } = useQuery(Projects, {
    variables,
    fetchPolicy: "network-only",
  });

  const { data: userProjectsData, refetch } = useQuery(userProjects, {
    variables: { idFamilyMenmber: idFamilyLeader },
    fetchPolicy: "no-cache",
  });

  const onCompleted = async (data) => {
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha asociado de manera exitosa.",
    });
    handleRefetch();
    setProject("");
  };

  const onCompletedDelete = async (data) => {
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado de manera exitosa.",
    });
    handleRefetch();
    setProject("");
  };

  const handleRefetch = async () => {
    refetch({
      variables: {
        familyLeaderId: idFamilyLeader,
      },
      fetchPolicy: "no-cache",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(ADD_FAMILY_LEADER, {
    onCompleted,
    onError,
  });

  const [deleteMutation] = useMutation(DELETE_FAMILY_LEADER, {
    onCompleted: onCompletedDelete,
    onError,
  });

  const handleMutation = () => {
    if (project !== "") {
      const input = {
        idFamilyMenmber: idFamilyLeader,
        idProject: project,
      };

      mutation({ variables: { input } });
    } else {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar un programa primero",
      });
    }
  };

  const deleteMutationTrigger = (projectId) => {
    const input = {
      idFamilyMenmber: idFamilyLeader,
      idProject: projectId,
    };

    deleteMutation({ variables: { input } });
  };

  return (
    <Modal
      trigger={
        triggerFluid ? (
          <Button onClick={() => setOpen(true)} icon="edit" size="tiny" fluid />
        ) : (
          <Button onClick={() => setOpen(true)} icon="edit" size="tiny" />
        )
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Proyectos de miembro familiar</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Proyecto"
              value={project}
              onChange={(e, { value }) => setProject(value)}
              scrolling={true}
              clearable={true}
              selection={true}
              error={error}
              search={true}
              required
              loading={loading}
              options={
                data?.projectList?.items
                  .filter((e) => {
                    let ids = userProjectsData?.familyLeaderProjects?.map(
                      (e) => e.id
                    );
                    return !ids?.includes(e.id);
                  })
                  .map((item) => {
                    return { value: item.id, text: item.name };
                  }) || []
              }
            />
          </Form.Group>
          <Button
            disabled={!project.trim()}
            onClick={handleMutation}
            content="Agregar proyecto"
          />
        </Form>

        {userProjectsData?.projectListByFamilyMenmber.length > 0 ? (
          <Table basic="very" fixed>
            <Table.Header>
              <Table.Row align="center">
                <Table.HeaderCell>Proyecto</Table.HeaderCell>
                <Table.HeaderCell>Acción</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {userProjectsData?.projectListByFamilyMenmber.map((e) => (
                <Table.Row align="center" key={`projectHeader${e.id}`}>
                  <Table.Cell>{e.name}</Table.Cell>
                  <Table.Cell>
                    <Button
                      size="mini"
                      onClick={() => {
                        Swal.fire({
                          title: "¿Estás seguro?",
                          text: "No podrás revertir esta acción.",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#d33",
                          cancelButtonColor: "#3085d6",
                          confirmButtonText: "Sí, eliminar",
                          cancelButtonText: "Cancelar",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteMutationTrigger(e.id);
                          }
                        });
                      }}
                    >
                      Eliminar
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <div style={{ marginTop: 20 }}>
            Este líder no tiene proyectos asociados.
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
};

const userProjects = gql`
  query Query($idFamilyMenmber: ID!) {
    projectListByFamilyMenmber(idFamilyMenmber: $idFamilyMenmber) {
      id
      name
    }
  }
`;

const Projects = gql`
  query Query($page: PageInput!, $filter: ProjectFilter!) {
    projectList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
      }
    }
  }
`;

const ADD_FAMILY_LEADER = gql`
  mutation ($input: inputAddFamilyMenmber!) {
    addFamilyMenmber(input: $input) {
      id
    }
  }
`;

const DELETE_FAMILY_LEADER = gql`
  mutation ($input: inputAddFamilyMenmber!) {
    deleteFamilyMenmber(input: $input) {
      id
    }
  }
`;

export default ProjectModal;
