import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateOffer = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [idWorkTeam, setIdWorkTeam] = useState(0);
  const [offerType, setTypeOffer] = useState("");
  const [, setLoading] = useState(false);

  const onCompleted = async () => {
    await refetch();
    setName("");
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado la oferta de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_OFFER, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        name,
        type: offerType,
        idWorkTeam,
      },
    });
  };

  const { loading, error, data } = useQuery(DATA);
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={<Button size="mini" onClick={() => setOpen(true)} icon="add" />}
      size="small"
    >
      <Modal.Header>Crear oferta</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group inline>
            <Form.Input
              required
              label="Nombre"
              value={name}
              onChange={(_, { value }) => setName(value)}
            />
            <Form.Dropdown
              size="mini"
              options={data?.workTeamListByUser || []}
              placeholder="Equipo de Trabajo"
              value={idWorkTeam}
              label="Equipo de Trabajo"
              clearable
              onChange={(e, { value }) => setIdWorkTeam(value)}
            />
          </Form.Group>
          <Form.Dropdown
            required={true}
            scrolling
            placeholder="Tipo de Oferta"
            selection={true}
            search={true}
            value={offerType}
            onChange={(e, { value }) => setTypeOffer(value)}
            loading={loading}
            error={error}
            options={data?.offerType?.items || []}
            label="Tipo de Oferta"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!name.trim()}
          loading={loading}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  );
};

const CREATE_OFFER = gql`
  mutation ($name: String!, $type: ID!, $idWorkTeam: ID!) {
    createOffer(name: $name, type: $type, idWorkTeam: $idWorkTeam) {
      id
      name
      user {
        id
        name
      }
      offerType {
        id
        name
      }
    }
  }
`;

const DATA = gql`
  query {
    offerType: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "27" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    workTeamListByUser {
      value: id
      text: name
      key: id
    }
  }
`;

export default CreateOffer;
