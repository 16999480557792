import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";

const ReportDetail = ({ id, member }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          compact
          size="tiny"
          icon="arrow circle right"
          onClick={() => setOpen(true)}
        />
      }
    >
      <Modal.Header>Detalle de encuestas realizadas por miembro</Modal.Header>
      <Content id={id} member={member} />
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

const Content = ({ id, member }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    page: { number: currentPage, limit: 100 },
    filter: { status: "ACTIVE", member: member },
  };
  const { loading, error, data } = useQuery(POLLSTER_DETAIL, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Encuesta",
      accessor: "survey.name",
    },
    {
      header: "Fecha de inicio",
      accessor: "startDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Fecha de fin",
      accessor: "endDate",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
  ];

  return (
    <>
      <div style={{ paddingTop: 10, paddingLeft: 20 }}>
        <Form>
          <Form.Group>
            <Form.Field>
              <Label content={`Miembro (${member})`} />
            </Form.Field>
            <Form.Field>
              <Label
                content={`Encuestas realizadas (${
                  data?.detailReportFamily?.count || 0
                })`}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <Modal.Content scrolling>
        <CustomTable
          currentPage={currentPage}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          totalPages={data?.answerHeaderList?.totalPages || 0}
          data={data?.answerHeaderList?.items || []}
          loading={loading}
          error={error}
          columns={columns}
        />
      </Modal.Content>
    </>
  );
};

const POLLSTER_DETAIL = gql`
  query ($filter: AnswerHeaderFilter!, $page: PageInput!) {
    answerHeaderList(filter: $filter, page: $page) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        survey {
          name
        }
        startDate
        endDate
      }
    }
  }
`;

export default ReportDetail;
