import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreatePeriod = ({ refetch, dataSelect, type }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, sertEndDate] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [loading, setLoading] = useState(false);

  const onCompleted = async () => {
    await refetch();
    setName("");
    setStartDate("");
    sertEndDate("");
    setOptionSelect("");
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado el periodo de manera exitosa.",
    }).then(() => setOpen(false));
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_PERIOD, { onCompleted, onError });

  const handleMutation = () => {
    setLoading(true);
    const input = {
      name,
      startDate,
      endDate,
    };
    switch (type) {
      case "SURVEY":
        input.survey = optionSelect;
        break;
      case "OFFER":
        input.offer = optionSelect;
        break;
      case "FAMILYPLAN":
        input.familyPlan = optionSelect;
        break;
      default:
        break;
    }

    mutation({
      variables: {
        input,
      },
    });
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={<Button size="mini" onClick={() => setOpen(true)} icon="add" />}
      size="mini"
    >
      <Modal.Header>Crear periodo</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Nombre"
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Input
            type="date"
            value={startDate}
            onChange={(e, { value }) => setStartDate(value)}
            label="Fecha inicio"
          />
          <Form.Input
            type="date"
            disabled={!startDate}
            value={endDate}
            min={startDate}
            onChange={(e, { value }) => sertEndDate(value)}
            label="Fecha final"
          />
          <Form.Select
            options={dataSelect}
            onChange={(e, { value }) => setOptionSelect(value)}
            label={
              type === "SURVEY"
                ? "Encuesta"
                : type === "OFFER"
                ? "Oferta"
                : "Plan familiar"
            }
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!name.trim() || !startDate.trim() || !endDate.trim()}
          loading={loading}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  );
};

const CREATE_PERIOD = gql`
  mutation ($input: CreatePeriodInput!) {
    createPeriod(input: $input) {
      id
      name
      startDate
      endDate
    }
  }
`;

export default CreatePeriod;
