import { useQuery, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import Selector from "../../Permissions/Selector";
import Offers from "../../Program/Offers";
import Beneficiarios from "../../Program/Beneficiarios";
import DownloadReport from "./DownloadReport";

const Project = () => {
  return (
    <>
      <Content />
    </>
  );
};
const reports = [
  { id: 0, name: "Seleciona el reporte" },
  { id: 1, name: "Oferta por Programa" },
  { id: 2, name: "Beneficiarios por Programa" },
];

const Content = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const variables = {
    filter: {
      status: "ACTIVE",
      name: "",
    },
  };
  const [reportType, setReportType] = useState(0);
  const { data, loading, error, refetch } = useQuery(GET_PROGRAM, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Proyecto",
      render: ({ project }) => {
        return (
          <>
            {project.map((item) => (
              <Label key={item.id} content={item.name} />
            ))}
          </>
        );
      },
    },
    {
      header: "Estado",
      accessor: "status",
    },
  ];

  if (reportType === 1) {
    columns.push({
      header: "Ofertas",
      render: ({ offer, id }) => (
        <Offers
          idProgram={parseInt(id)}
          offers={offer || []}
          error={error}
          loading={loading}
          refetch={refetch}
        />
      ),
    });
  }

  if (reportType === 2) {
    columns.push({
      header: "Beneficiarios",
      render: ({ offer }) => {
        const list = [];
        offer?.forEach(({ offerHeader }) => {
          //const  = offerHeader;
          offerHeader?.forEach(({ familyMenmber }) => {
            list.push({
              ...familyMenmber,
              name: `${familyMenmber.firstName}${
                familyMenmber?.secondName
                  ? " " + familyMenmber?.secondName
                  : " "
              }${" " + familyMenmber.surname}${
                familyMenmber?.secondSurname
                  ? " " + familyMenmber?.secondSurname
                  : " "
              }`,
            });
          });
        });

        return (
          <Beneficiarios beneficiaries={list} error={error} loading={loading} />
        );
      },
    });
  }

  useEffect(() => {
    switch (reportType) {
      case 1:
        setList(
          data?.programList?.items.filter(({ offer }) => offer.length > 0)
        );
        break;
      case 2:
        setList(
          data?.programList?.items.filter(
            ({ offer }) =>
              offer?.filter(({ offerHeader }) => offerHeader?.length > 0)
                .length > 0
          )
        );
        break;
      default:
        setList(data?.programList?.items);
        break;
    }
  }, [reportType, data]);

  return (
    <>
      <Form style={{ padding: 10 }}>
        <Form.Group>
          <Label content={`Total Projectos (${list?.length || 0})`} />
          <DownloadReport list={list} reportType={reportType} />
        </Form.Group>
        <Selector
          loading={!data ? true : false}
          error={error}
          value={reportType}
          onChange={(_, { value }) => {
            setReportType(value);
          }}
          data={
            reports.map((e) => ({
              value: e.id,
              text: e.name,
              key: e.name,
            })) || []
          }
          placeholder="Tipo de Reporte"
        />
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(_, { activePage }) => setCurrentPage(activePage)}
        totalPages={Math.ceil(list?.length / 10) || 0}
        data={
          list?.slice(10 * (currentPage - 1), 10 * (currentPage - 1) + 10) || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const GET_PROGRAM = gql`
  query ProgramList($filter: ProgramFilter!) {
    programList(filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        project {
          id
          name
          strategy
        }
        offer {
          id
          name
          description
          status
          offerHeader {
            id
            familyMenmber {
              firstName
              secondName
              surname
              secondSurname
              identification
              address
              id
              phone
            }
          }
        }
        status
      }
    }
  }
`;

export default Project;
