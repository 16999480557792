import React from "react";
import { Tab } from "semantic-ui-react";
import GeneralReports from "./GeneralReports";
import ProjectReports from "./ProjectReports";

const Dashboard = () => {
  const panes = [
    {
      menuItem: "General",
      render: () => (
        <Tab.Pane>
          <GeneralReports />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Proyectos",
      render: () => (
        <Tab.Pane>
          <ProjectReports />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <h1>Dashboard</h1>
      <Tab panes={panes} />
    </>
  );
};

export default Dashboard;
