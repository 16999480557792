import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import CustomTable from "../../../Components/CustomTable";
import Swal from "sweetalert2";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "REMOVED", key: "REMOVED" },
];
const Members = ({ EDIT, DELETE, id, refetch }) => {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [workTeam, setWorkTeam] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const [inputs, setInputs] = useState({
    idUser: 0,
    idWorkTeam: id,
  });
  const filter = { status, name: workTeam, id };

  const { data, loading, error } = useQuery(GET_USERWORKTEAM, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });
  const searchProjec = (name) => {
    console.log(name);
    setDataFiltered(
      data?.workTeamList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(name?.toLowerCase())
      )
    );
  };

  const { data: userData } = useQuery(USERS, {
    variables: {
      page: { number: 1, limit: 10000 },
      filter: { status: "ACTIVE" },
    },
    fetchPolicy: "network-only",
  });

  const onCompleted = async () => {
    await refetch();
    clearFilter();
    setOpen(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el usuario del equipo de trabajo de manera exitosa.",
    });
  };
  const onCompletedAdd = async () => {
    setInputs({
      idUser: 0,
      idWorkTeam: id,
    });
    await refetch();
    clearFilter();
    setOpen(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha agrego el usuario del equipo de trabajo de manera exitosa.",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(DELETE_WORKTEAM, { onCompleted, onError });
  const [mutationAdd] = useMutation(CREATE_USERWORKTEAM, {
    onCompleted: onCompletedAdd,
    onError,
  });

  const deleteUserWorkTeam = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Advertencia!",
      text: "Seguro que desea eliminar este usuario de este equipo de trabajo?",
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
      confirmButtonColor: "rgb(4, 90, 115)",
      showDenyButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutation({ variables: { id } });
      }
    });
  };

  const clearFilter = () => {
    setWorkTeam("");
    setInputs({
      idUser: 1,
      idWorkTeam: id,
    });
    setStatus("ACTIVE");
    setCurrentPage(1);
    setDataFiltered([]);
  };

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "user.name",
    },
  ];

  if (DELETE) {
    columns.push({
      header: "Eliminar",
      render: (data) => (
        <Button icon="trash" onClick={() => deleteUserWorkTeam(data.id)} />
      ),
    });
  }

  const addUserWorkTeam = () => {
    if (inputs.user === 0) {
      return;
    }

    mutationAdd({ variables: { inputs } });
  };

  return (
    <>
      <Button
        size="tiny"
        content="Integrantes"
        icon="users"
        onClick={() => setOpen(true)}
      />
      <Modal size="large" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Integrantes</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              {EDIT && (
                <>
                  <Button
                    icon="add"
                    size="tiny"
                    onClick={addUserWorkTeam}
                    disabled={inputs.idUser == 0}
                  />
                  <Form.Dropdown
                    search
                    selection
                    clearable
                    size="tiny"
                    required
                    loading={loading}
                    placeholder="Usuario"
                    onChange={(_, { value }) =>
                      setInputs({ ...inputs, idUser: value })
                    }
                    options={
                      userData?.userList?.items?.map((data) => ({
                        key: data.id,
                        value: data.id,
                        text: data.name,
                      })) || []
                    }
                    value={inputs.idUser}
                  />
                </>
              )}

              <Form.Field>
                <Label
                  content={`Cantidad (${data?.userWorkTeamList?.count || 0})`}
                />
              </Form.Field>
              <Form.Dropdown
                label="Estado"
                value={status}
                onChange={(e, { value }) => setStatus(value)}
                options={options}
              />
              <Form.Input
                action={{
                  icon: "search",
                  size: "mini",
                  content: "Buscar",
                  onClick: () => searchProjec(workTeam),
                }}
                value={workTeam}
                size="mini"
                onChange={(e, { value }) => setWorkTeam(value)}
                placeholder="Búsqueda por nombre"
              />
              <Form.Button
                size="tiny"
                onClick={clearFilter}
                content="Vaciar filtro"
                compact
                icon="trash"
              />
            </Form.Group>
          </Form>
          <CustomTable
            currentPage={currentPage}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
            totalPages={
              dataFiltered.length > 0
                ? dataFiltered.length
                : data?.userWorkTeamList?.totalPages || 0
            }
            data={
              dataFiltered.length > 0
                ? dataFiltered
                : data?.userWorkTeamList?.items || []
            }
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const GET_USERWORKTEAM = gql`
  query UserWorkTeamList($page: PageInput!, $filter: UserWorkTeamFilter!) {
    userWorkTeamList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        user {
          name
        }
        status
      }
    }
  }
`;

const USERS = gql`
  query ($page: PageInput!, $filter: UserFilter!) {
    userList(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      totalPages
      items {
        id
        name
      }
    }
  }
`;

const DELETE_WORKTEAM = gql`
  mutation ($id: ID!) {
    deleteUserWorkTeam(id: $id) {
      id
    }
  }
`;

const CREATE_USERWORKTEAM = gql`
  mutation ($inputs: createUserWorkTeamInput!) {
    createUserWorkTeam(input: $inputs) {
      id
    }
  }
`;
export default Members;
