import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateCategory = ({ idFamilyPlan, refetch }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    setLoading(false);
    await refetch();
    setName("");

  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_CATEGORY, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        name,
        idFamilyPlan
      }
    });
  }
  return (
    <Popup
      content={
        <>
          <Form>
            <Form.TextArea
              value={name}
              onChange={(e, { value }) => setName(value)}
              style={{ minHeight: 75, maxHeight: 75 }}
              label="Nombre" />
            <Button
              loading={loading}
              disabled={!name.trim() || loading}
              onClick={handleMutation}
              content="Crear categoría" />
          </Form>
        </>
      }
      on='click'
      positionFixed
      trigger={<Button icon="add" circular />}
    />
  );
}

const CREATE_CATEGORY = gql`
  mutation($name: String!, $idFamilyPlan: ID!) {
    createFamilyPlanCategory(name: $name, idFamilyPlan: $idFamilyPlan) {
      id
      name
      order
    }
  }
`;

export default CreateCategory;