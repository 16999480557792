import React, { useState } from "react";
import { Button, Form, Label } from "semantic-ui-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import CustomTable from "../../../Components/CustomTable";
import Created from "./Created";
import Updated from "./Updated";
import Swal from "sweetalert2";
import Members from "./Members";
const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "REMOVED", key: "REMOVED" },
];
const WorkTeam = ({ permissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("ACTIVE");
  const [workTeam, setWorkTeam] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const filter = { status, name: workTeam };

  const CREATE = permissions?.some((value) => value?.action.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action.name === "Editar");
  const DELETE = permissions?.some(
    (value) => value?.action?.name === "Eliminar"
  );
  const { data, loading, error, refetch } = useQuery(GET_WORKTEAM, {
    variables: { page: { number: currentPage, limit: 10 }, filter },
    fetchPolicy: "network-only",
  });
  const searchProjec = (name) => {
    setDataFiltered(
      data?.workTeamList?.items?.filter((header) =>
        header?.user?.name?.toLowerCase().includes(name?.toLowerCase())
      )
    );
  };
  const clearFilter = () => {
    setWorkTeam("");
    setStatus("ACTIVE");
    setCurrentPage(1);
    setDataFiltered([]);
  };

  const onCompleted = async () => {
    await refetch();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha eliminado el equipo de trabajo de manera exitosa.",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(DELETE_WORKTEAM, { onCompleted, onError });

  const delteWorkTeam = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Advertencia!",
      text: "Seguro que desea eliminar este equipo de trabajo?",
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
      confirmButtonColor: "rgb(4, 90, 115)",
      showDenyButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutation({ variables: { id } });
      }
    });
  };
  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Integrantes",
      render: ({ id }) => (
        <Members EDIT={EDIT} DELETE={DELETE} id={id} refetch={refetch} />
      ),
    },
  ];

  if (EDIT) {
    columns.push({
      header: "Actualizar",
      render: (data) => <Updated refetch={refetch} data={data} />,
    });
  }
  if (DELETE) {
    columns.push({
      header: "Eliminar",
      render: (data) => (
        <Button
          icon="trash"
          content="Eliminar"
          onClick={() => delteWorkTeam(data.id)}
        />
      ),
    });
  }

  return (
    <div>
      <h1>Equipos de Trabajos</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <Created refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.workTeamList?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => searchProjec(workTeam),
            }}
            value={workTeam}
            size="mini"
            onChange={(e, { value }) => setWorkTeam(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Button
            size="tiny"
            onClick={clearFilter}
            content="Vaciar filtro"
            compact
            icon="trash"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={
          dataFiltered.length > 0
            ? dataFiltered.length
            : data?.workTeamList?.totalPages || 0
        }
        data={
          dataFiltered.length > 0
            ? dataFiltered
            : data?.workTeamList?.items || []
        }
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_WORKTEAM = gql`
  query WorkTeamList($page: PageInput!, $filter: WorkTeamFilter!) {
    workTeamList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        status
      }
    }
  }
`;

const DELETE_WORKTEAM = gql`
  mutation ($id: ID!) {
    deleteWorkTeam(id: $id) {
      id
    }
  }
`;

export default WorkTeam;
