/* eslint-disable default-case */
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Swal from "sweetalert2";

const DownloadReport = ({ list, reportType }) => {
  const [loading, setLoading] = useState(false);

  const exportToExcel = () => {
    setLoading(true);
    const listData = [];
    let No = 1;
    switch (reportType) {
      case 1:
        list.forEach((item) => {
          item.beneficiaries.forEach((item2) => {
            listData.push({
              No,
              Proyecto: item.name,
              Estrategia: item.strategy,
              "Usuario Encargado": item.userCharge.name,
              Beneficiario: `${item2?.firstName || ""} ${
                item2?.secondName ? ` ${item2?.secondName}` : ""
              } ${item2?.surname ? ` ${item2?.surname}` : ""} ${
                item2?.secondSurname ? ` ${item2?.secondSurname}` : ""
              }`,
              identificación: item2?.identification || "",
              Dirección: item2.address,
              Barrio: item2.neighborhood,
              "Numero Telefonico": item2.phone,
            });
            No = No + 1;
          });
        });
        break;
      case 2:
        list.forEach((item) => {
          item.programs.forEach((item2) => {
            listData.push({
              No,
              Proyecto: item.name,
              Estrategia: item.strategy,
              "Usuario Encargado": item.userCharge.name,
              Prgrama: item2.name,
            });
            No = No + 1;
          });
        });
        break;
      case 3:
        list.forEach((item) => {
          item.programs.forEach((item2) => {
            item2.offer.forEach((item3) => {
              listData.push({
                No,
                Proyecto: item.name,
                Estrategia: item.strategy,
                "Usuario Encargado": item.userCharge.name,
                Oferta: item3.name,
                Descripción: item3.description,
              });
              No = No + 1;
            });
          });
        });
        break;
    }
    if (listData.length > 0) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(listData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `report-Project-${new Date().getTime()}` + fileExtension
      );
    } else {
      Swal.fire({
        title: "No se Encontraron datos para generar el reporte",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Form.Field
      compact
      icon="file excel"
      loading={loading}
      disabled={loading}
      onClick={exportToExcel}
      size="mini"
      control={Button}
      content="Descargar"
    />
  );
};

export default DownloadReport;
