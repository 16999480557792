import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputTypePassword from "../../../Components/InputTypePassword";
import { PASSWORD_REGEX } from "../../../Utils/Constants";
const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';

const UpdatePassword = ({ user, refetch, setOpen }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    setPassword("");
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado la contraseña de manera exitosa.'
    }).then(() => setOpen(false))
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_PASSWORD, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const variables = {
      id: user?.id,
      password
    };

    mutation({ variables });
  }
  const validatePassword = () => {
    if (password.trim() && !PASSWORD_REGEX.test(password)) return passwordError;
    return null;
  }
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <InputTypePassword
            required
            value={password}
            error={validatePassword()}
            label="Nueva contraseña"
            onChange={(e, { value }) => setPassword(value)} />
        </Form.Group>
      </Form>
      <div style={{ marginTop: 20 }}>
        <Button
          content="Guardar"
          style={{ backgroundColor: "#045A73", color: "white" }}
          loading={loading}
          disabled={!password.trim() || !PASSWORD_REGEX.test(password) || loading}
          onClick={handleMutation} />
        <Button
          content="Cancelar"
          onClick={() => setOpen(false)} />
      </div>
    </>
  );
}

const UPDATE_PASSWORD = gql`
  mutation($id: ID! $password: String!){
    updatePassword(id: $id password: $password)
  }
`;


export default UpdatePassword;