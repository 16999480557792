import React, { useState } from "react";
import { Dropdown, Form, Input, Radio } from "semantic-ui-react";

const MultiInputRecursive = ({
  id,
  type = "Texto",
  name,
  num,
  numFather = false,
  onChange,
  onChangeFile,
  required,
  label,
  options = [],
  placeholder,
}) => {
  const onChange2 = onChange;
  const onChangeFile2 = onChangeFile;

  const [check, setCheck] = useState(false);
  const [multipleChoice, setMultipleChoice] = useState([]);
  const [responseText, setResponseText] = useState("");
  const [infoInput, setInfoInput] = useState(null);
  const handleOnCheck = (e, data) => {
    setInfoInput({ e, data });
    setCheck(data?.value);
    onChange(e, data);
  };

  const handleResponseText = (e, { value, questiontext }) => {
    if (infoInput) {
      const res = { ...infoInput };
      res.data.responseText = value;
      res.data.questionText = questiontext;
      onChange(res?.e, res?.data);
    }
    setResponseText(value);
  };

  const handleOnMultipleChoice = (e, data) => {
    let res = [...multipleChoice];
    const value = JSON.parse(data?.value)
    if (res.findIndex(v => v?.response === value?.response) === -1) {
      res.push(value);
    } else {
      const index = res.findIndex(v => v?.response === value?.response);
      res.splice(index, 1);
    }
    data.values = res;
    setMultipleChoice(res);
    onChange(e, data);
  };

  if (type === "Archivo")
    return (
      <div style={{ marginTop: 30 }}>
        <Form.Field
          enunciado={label}
          pos={num}
          idcampo={id}
          tipo={type}
          name={name}
          onChange={onChangeFile}
          required={required}
          label={`${num + 1}. ${label}`}
          type="file"
          control={Input}
          placeholder={placeholder}
        />
        <p style={{ fontSize: 12, marginBottom: 20 }}>
          Límite de número de archivos:1 Límite de tamaño del archivo
          individual: 10MB
        </p>
      </div>
    );

  if (type === "Multiple-Opcion")
    return (
      <Form.Field style={{ marginTop: 10, marginBottom: 10 }} required={required}>
        <label>
          {numFather
            ? `${numFather}.${num + 1}. ${label}`
            : `${num + 1}. ${label}`}
        </label>
        {options.map((option, key) => (
          <div key={key}>
            <Form.Checkbox
              key={key}
              tipo={type}
              enunciado={label}
              pos={num}
              idcampo={id}
              label={option?.response}
              name={name}
              value={JSON.stringify(option)}
              checked={multipleChoice.findIndex(v => v?.response === option?.response) !== -1}
              onChange={handleOnMultipleChoice}
            />

            {((multipleChoice.findIndex(v => v?.response === option?.response) !== -1) &&
              option?.questions) &&
              option.questions.map((q, key) => (
                <div key={key} style={{ marginLeft: "20px" }}>
                  <MultiInputRecursive
                    id={q?.id}
                    num={key}
                    type={q?.type?.name}
                    name={q?.enunciated}
                    key={key}
                    onChange={onChange2}
                    onChangeFile={onChangeFile2}
                    options={q?.options}
                    required={q?.required}
                    label={q?.enunciated}
                    control={Input}
                    placeholder={"Rellene la respuesta"}
                  />
                </div>
              ))}
          </div>
        ))}
      </Form.Field>
    );

  if (type === "Calendario")
    return (
      <Form.Input
        type="date"
        idcampo={id}
        pos={num}
        tipo={type}
        enunciado={label}
        name={name}
        label={`${num + 1}. ${label}`}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
      />
    );

  if (type === "Radio")
    return (
      <Form.Field style={{ marginTop: 30 }} required={required}>
        <label>
          {numFather
            ? `${numFather}.${num + 1}. ${label}`
            : `${num + 1}. ${label}`}
        </label>
        {options.map((option, key) => (
          <Form.Field key={key}>
            <Radio
              tipo={type}
              enunciado={label}
              pos={num}
              idcampo={id}
              idopcion={option?.id}
              label={option?.response}
              name={name}
              optionquestion={String(option?.optionQuestion)}
              value={option?.response}
              checked={check === option?.response}
              onChange={handleOnCheck}
            />
            {check === option?.response && option?.optionQuestion && (
              <>
                <br />
                <br />
                <Form.TextArea
                  required={required}
                  value={responseText}
                  questiontext={option?.questionText}
                  onChange={handleResponseText}
                  label={option?.questionText}
                />
              </>
            )}
            {check === option?.response &&
              option?.questions &&
              option.questions.map((q, key) => (
                <div style={{ marginLeft: "20px" }}>
                  <MultiInputRecursive
                    id={q?.id}
                    num={key}
                    numFather={num + 1}
                    type={q?.type?.name}
                    name={q?.enunciated}
                    key={key}
                    onChange={onChange2}
                    onChangeFile={onChangeFile2}
                    options={q?.options}
                    required={q?.required}
                    label={q?.enunciated}
                    control={Input}
                    placeholder={"Rellene la respuesta"}
                  />
                </div>
              ))}
          </Form.Field>
        ))}
      </Form.Field>
    );

  if (type === "Opcion") {
    let data = options.map((option) => ({
      text: option?.response,
      value: option?.response,
      key: option?.id,
    }));

    return (
      <Form.Dropdown
        idcampo={id}
        tipo={type}
        pos={num}
        enunciado={label}
        name={name}
        selection
        onChange={onChange}
        control={Dropdown}
        required={required}
        label={`${num + 1}. ${label}`}
        placeholder={placeholder}
        options={data}
      />
    );
  }

  return (
    <Form.TextArea
      idcampo={id}
      pos={num}
      tipo={type}
      enunciado={label}
      name={name}
      onChange={onChange}
      required={required}
      label={`${num + 1}. ${label}`}
      placeholder={placeholder}
    />
  );
};

export default MultiInputRecursive;
