import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Message,
  Segment,
  Button,
} from "semantic-ui-react";
import Swal from "sweetalert2";

const filterTypeText = {
  ID: {
    placeholder: "Buscar miembro por identificación",
    message: "Digite la identificación para su busqueda.",
  },
  NAME: {
    placeholder: "Buscar miembro por nombre",
    message: "Digite un nombre para su busqueda.",
  },
  HOUSE_NUMBER: {
    placeholder: "Buscar miembro por número de casa",
    message: "Digite un número de casa para su busqueda.",
  },
};

const MemberInput = ({ refetch, answerHeader, open, setOpen }) => {
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("ID");
  const [fakeSearch, setFakeSearch] = useState("");
  const [load, setLoading] = useState(false);
  const [member, setMember] = useState(null);
  const variables = {
    search: search.trim() ? true : false,
  };
  if (filterType === "ID") variables.identification = search;
  if (filterType === "NAME") variables.name = search;
  if (filterType === "HOUSE_NUMBER") variables.houseNumber = search;
  const { loading, error, data } = useQuery(FAMILY_MEMBERS, {
    variables,
    fetchPolicy: "network-only",
  });

  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha transferido la encuesta de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(UPDATE_ANSWER_HISTORY, {
    onCompleted,
    onError,
  });

  const handleDeleteSearch = () => {
    setSearch("");
    setFakeSearch("");
  };

  const handleFilterType = (e, { value }) => {
    setFilterType(value);
    setSearch("");
    setFakeSearch("");
  };

  const handleCleanItem = () => {
    setMember(null);
    setSearch("");
    setFakeSearch("");
  };

  const handleMutation = () => {
    if (!member) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de nacimiento es inválida.",
      });
    } else {
      setLoading(true);
      const input = {
        idAnswerHeader: answerHeader.id,
        idMenmber: member.id,
      };
      mutation({ variables: { input } });
      refetch();
    }
  };

  return (
    <>
      <Form style={{ padding: "1rem" }}>
        {member && (
          <List relaxed divided>
            <List.Item>
              <List.Content floated="right">
                <Icon
                  onClick={handleCleanItem}
                  size="large"
                  name="delete"
                  color="red"
                  link
                />
              </List.Content>
              <List.Icon name="user" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a">{member?.name}</List.Header>
                <List.Description as="a">
                  <Icon name="home" />
                  {member?.houseNumber}
                </List.Description>
                <List.Description as="a">
                  <Icon name="address card" />
                  {member?.identification}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        )}
        {!member && (
          <>
            <Dropdown
              value={filterType}
              onChange={handleFilterType}
              options={[
                { text: "Identificación", value: "ID", key: 1 },
                { text: "Nombre", value: "NAME", key: 2 },
                { text: "# Casa", value: "HOUSE_NUMBER", key: 3 },
              ]}
            />
            <Input
              fluid
              iconPosition={fakeSearch.trim() ? "left" : null}
              icon={
                fakeSearch.trim()
                  ? {
                      name: "delete",
                      circular: true,
                      link: true,
                      onClick: () => handleDeleteSearch(),
                    }
                  : null
              }
              placeholder={filterTypeText[filterType]?.placeholder}
              value={fakeSearch}
              action={{
                content: "Buscar",
                icon: "search",
                style: { backgroundColor: "#045A73", color: "white" },
                onClick: (e) => {
                  e.preventDefault();
                  setSearch(fakeSearch);
                },
              }}
              onChange={(e, { value }) => setFakeSearch(value)}
            />
          </>
        )}
        {data?.familymembers?.length === 0 && !member && !loading && (
          <Message>No se encontró el lider.</Message>
        )}
        {loading && <h3>Buscando...</h3>}
        {error && <Message negative>Ha ocurrido un error.</Message>}
        {data?.familyMenmbers?.length > 0 && !member && (
          <Segment style={{ height: 150, overflow: "auto" }}>
            <h5>Seleccionar miembro familiar</h5>
            <List relaxed divided>
              {data?.familyMenmbers?.map((familymember) => (
                <List.Item
                  onClick={() => setMember(familymember)}
                  key={familymember?.id}
                >
                  <List.Icon name="user" size="large" verticalAlign="middle" />
                  <List.Content>
                    <List.Header as="a">{`${familymember?.firstName || ""} ${
                      familymember?.secondName
                        ? ` ${familymember?.secondName}`
                        : ""
                    } ${
                      familymember?.surname ? ` ${familymember?.surname}` : ""
                    } ${
                      familymember?.secondSurname
                        ? ` ${familymember?.secondSurname}`
                        : ""
                    }`}</List.Header>
                    <List.Description as="a">
                      <Icon name="home" />
                      {familymember?.familyLeader.houseNumber}
                    </List.Description>
                    <List.Description as="a">
                      <Icon name="address card" />
                      {familymember?.identification}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        )}
      </Form>
      {member && (
        <Button disabled={load} onClick={handleMutation}>
          Transferir
        </Button>
      )}
    </>
  );
};

const FAMILY_MEMBERS = gql`
  query (
    $name: String
    $identification: String
    $houseNumber: String
    $search: Boolean!
  ) {
    familyMenmbers(
      name: $name
      identification: $identification
      houseNumber: $houseNumber
    ) @include(if: $search) {
      id
      identification
      firstName
      secondName
      surname
      secondSurname
      familyLeader {
        houseNumber
      }
    }
  }
`;

const UPDATE_ANSWER_HISTORY = gql`
  mutation ($input: UpdateMenmberInput!) {
    UpdateMenmberAnswerHeaders(input: $input) {
      id
    }
  }
`;

export default MemberInput;
