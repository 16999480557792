import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateModule = ({ refetch, module }) => {
  const [name, setName] = useState(module?.name || "");
  const [order, setOrder] = useState(module?.order || "1");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el módulo de manera exitosa.'
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_MODULE, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: { id: module?.id, name, order: parseInt(order) }
    });
  }
  return (
    <Popup
      content={
        <Form>
          <Form.Input
            value={name}
            onChange={(e, { value }) => setName(value)}
            label="Nombre" />
          <Form.Input
            value={order}
            type="number"
            min="1"
            onChange={(e, { value }) => setOrder(value)}
            label="Orden" />
          <Form.Button
            onClick={handleMutation}
            loading={loading}
            disabled={!name.trim() || parseInt(order) < 0 || !order || loading}
            content="Guardar" />
        </Form>
      }
      on='click'
      positionFixed
      trigger={<Button compact size="tiny" icon="edit" />}
    />
  );
}

const UPDATE_MODULE = gql`
mutation($id: ID!, $name: String! $order: Int){
  updateModule(id: $id, name: $name order: $order) {
    id
  }
}
`;

export default UpdateModule;