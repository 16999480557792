import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Icon, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import FilterReport from "./FilterReport";
import DownloadReportList from "./DownloadReportListFamilyPlan";
import Swal from "sweetalert2";

const ReportFamilyPlan = () => {
  const [idFamilyPlan, setIdFamilyPlan] = useState("");
  const [idPeriod, setIdPeriod] = useState("");
  const [trafficData, setTrafficData] = useState([]);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const filter = {};
  const { loading, data, error } = useQuery(FAMILY_PLANS, {
    fetchPolicy: "network-only",
  });
  let periods = [];
  const colors = ["red", "yellow", "green"];
  if (idFamilyPlan) {
    const familyPlan = data?.familyPlans?.filter(
      (familyPlan) => familyPlan?.value === idFamilyPlan
    );
    periods = familyPlan[0]?.periods || [];
    filter.idFamilyPlan = idFamilyPlan;
  }
  if (idPeriod) filter.idPeriod = idPeriod;
  if (trafficData.length > 0) filter.traffic = trafficData;

  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      filter.initialDate = `${initialDate} 00:00:00`;
      filter.finalDate = `${finalDate} 23:59:59`;
    }
  }

  const handleFamilyPlan = (e, { value }) => {
    setIdFamilyPlan(value);
    setIdPeriod("");
    setTrafficData([]);
    setFinalDate("");
    setInitialDate("");
  };
  const handlePeriod = (e, { value }) => {
    setIdPeriod(value);
    setTrafficData([]);
    setFinalDate("");
    setInitialDate("");
  };
  const handleTraffic = (color) => {
    let _trafficData = [...trafficData];
    const _color = color?.toLocaleUpperCase();
    if (!_trafficData.includes(_color)) {
      _trafficData.push(_color);
      setTrafficData(_trafficData);
    } else {
      _trafficData = _trafficData.filter((traffic) => traffic !== _color);
      setTrafficData(_trafficData);
    }
  };
  return (
    <div>
      <Form>
        <Form.Group inline>
          <FilterReport />
          <DownloadReportList />
        </Form.Group>
        <Form.Group>
          <Form.Dropdown
            selection
            label="Filtro por plan familiar"
            placeholder="Seleccione un plan familiar"
            loading={loading}
            error={error}
            clearable
            value={idFamilyPlan}
            onChange={handleFamilyPlan}
            options={data?.familyPlans || []}
          />
          {idFamilyPlan && (
            <>
              <Form.Dropdown
                selection
                label="Filtro por período"
                placeholder="Seleccione un período"
                loading={loading}
                error={error}
                clearable
                value={idPeriod}
                onChange={handlePeriod}
                options={periods}
              />
              <Form.Input
                type="date"
                value={initialDate}
                onChange={(e, { value }) => setInitialDate(value)}
                label="Fecha Inicial"
              />
              <Form.Input
                type="date"
                value={finalDate}
                onChange={(e, { value }) => setFinalDate(value)}
                label="Fecha Final"
              />
            </>
          )}
          {(idPeriod || (initialDate && finalDate)) && (
            <>
              <Form.Field>
                <label>
                  Filtro por semáforo
                  {trafficData.length > 0 && (
                    <Icon
                      link
                      size="large"
                      onClick={() => setTrafficData([])}
                      name="delete"
                    />
                  )}
                </label>
                {colors.map((color) => (
                  <Label
                    as="a"
                    circular
                    onClick={() => handleTraffic(color)}
                    size="big"
                    color={color}
                    key={color}
                  />
                ))}
              </Form.Field>
            </>
          )}
        </Form.Group>
      </Form>
      {Object.keys(filter).length > 1 && <Content filter={filter} />}
    </div>
  );
};

const Content = ({ filter }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data } = useQuery(REPORT_FAMILY_PLAN, {
    variables: {
      page: { number: currentPage, limit: 10 },
      filter,
    },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Lider familiar",
      accessor: "name",
    },
    {
      header: "Identificación",
      accessor: "identification",
    },
    {
      header: "Número de casa",
      accessor: "houseNumber",
    },
    {
      header: "Indicador familiar",
      accessor: "familyIndicator",
      render: (familyIndicator) =>
        familyIndicator ? `${parseFloat(familyIndicator).toFixed(2)}%` : "",
    },
  ];

  const rangeColor = (color) => {
    switch (color) {
      case "RED":
        return "Indicador familiar: (51% - 100%)";
      case "YELLOW":
        return "Indicador familiar: (31% - 50%)";
      case "GREEN":
        return "Indicador familiar: (0% - 30%)";
      default:
        return "- - - -";
    }
  };

  return (
    <div>
      <Form>
        <Form.Field>
          <Label content={`Cantidad (${data?.reportFamilyPlan?.count || 0})`} />
        </Form.Field>
        {filter?.traffic?.length > 0 && (
          <Form.Field>
            <label> Semáforo aplicado</label>
            {filter?.traffic?.map((color) => (
              <Label
                color={color?.toLocaleLowerCase()}
                key={color?.toLocaleLowerCase()}
              >
                {rangeColor(color)}
              </Label>
            ))}
          </Form.Field>
        )}
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.reportFamilyPlan?.totalPages || 0}
        data={data?.reportFamilyPlan?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const FAMILY_PLANS = gql`
  query {
    familyPlans {
      value: id
      text: name
      key: id
      periods {
        value: id
        text: name
        key: id
      }
    }
  }
`;

const REPORT_FAMILY_PLAN = gql`
  query ($page: PageInput!, $filter: FamilyPlanReportFilter) {
    reportFamilyPlan(page: $page, filter: $filter) {
      count
      page {
        number
        limit
      }
      items {
        id
        name
        houseNumber
        familyIndicator
        identification
      }
      totalPages
    }
  }
`;

export default ReportFamilyPlan;