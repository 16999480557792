import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";

const FilterSurvey = ({
  idSurvey,
  idCategorie,
  setIdCategorie,
  setIdSurvey,
  setNameQuestion,
  idPeriod,
  setPeriod,
  idQuestion,
  setIdQuestion,
  optionsValue,
  setOptionsValue,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
}) => {
  let periods = [];
  let optionsQuestion = [];
  let categories = [];
  let options = [];
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (idQuestion.trim()) {
      const question = optionsQuestion.find((v) => v.value === idQuestion);
      if (typeof setIdQuestion === "function") setNameQuestion(question?.text);
    }
  }, [idQuestion]);
  const variables = {};
  if (idSurvey) variables.idSurvey = idSurvey;
  const { loading, error, data } = useQuery(SURVEYS, {
    fetchPolicy: "network-only",
    variables,
  });

  if (idSurvey.trim()) {
    const survey = data?.familyPlanList?.items?.find(
      (v) => v.value === idSurvey
    );
    periods = survey?.periods || [];
    categories = survey?.categories || [];
  }

  if (idCategorie.trim()) {
    const categorie = categories?.find((v) => v.value === idCategorie);
    optionsQuestion = categorie?.questions || [];
  }

  if (idQuestion.trim()) {
    const question = optionsQuestion.find((v) => v.value === idQuestion);
    options = question?.options || [];
  }
  const handleSurvey = (e, { value }) => {
    setIdSurvey(value);
    if (!value) {
      categories = [];
      setIdCategorie("");
      optionsQuestion = [];
      setIdQuestion("");
      options = [];
      setOptionsValue([]);
      periods = [];
      setPeriod("");
    }
  };
  const handleCategorie = (e, { value }) => {
    setIdCategorie(value);
    if (!value) {
      categories = [];
      setIdCategorie("");
      optionsQuestion = [];
      setIdQuestion("");
      options = [];
      setOptionsValue([]);
    }
  };
  const handleQuestion = (e, { value }) => {
    setIdQuestion(value);
    if (!value) {
      options = [];
      setOptionsValue([]);
    }
  };
  return (
    <Form>
      <Form.Dropdown
        value={idSurvey}
        placeholder="Filtrar por encuesta"
        scrolling
        selection
        clearable
        label="Encuesta"
        loading={loading}
        error={error}
        options={data?.familyPlanList?.items || []}
        onChange={handleSurvey}
      />
      {idSurvey && (
        <>
          <Form.Input
            type="date"
            value={initialDate}
            onChange={(e, { value }) => setInitialDate(value)}
            label="Fecha Inicial"
          />
          <Form.Input
            type="date"
            value={finalDate}
            onChange={(e, { value }) => setFinalDate(value)}
            label="Fecha Final"
          />
          <Form.Dropdown
            value={idPeriod}
            placeholder="Filtrar por periodo"
            scrolling
            selection
            clearable
            label="Periodo"
            loading={loading}
            error={error}
            options={periods}
            onChange={(e, { value }) => setPeriod(value)}
          />
          <Form.Dropdown
            value={idCategorie}
            placeholder="Filtrar por Categoría"
            scrolling
            selection
            clearable
            label="Categoría"
            loading={loading}
            error={error}
            options={categories || []}
            onChange={handleCategorie}
          />
        </>
      )}
      {optionsQuestion.length > 0 && (
        <Form.Dropdown
          placeholder="Filtrar por pregunta"
          scrolling
          selection
          clearable
          value={idQuestion}
          label="Pregunta"
          loading={loading}
          error={error}
          options={optionsQuestion}
          onChange={handleQuestion}
        />
      )}
      {options.length > 0 && (
        <Form.Dropdown
          placeholder="Filtrar por opciones"
          scrolling
          selection
          clearable
          multiple
          value={optionsValue}
          label="Opciones"
          loading={loading}
          error={error}
          options={options}
          onChange={(e, { value }) => setOptionsValue(value)}
        />
      )}
    </Form>
  );
};

const SURVEYS = gql`
  query FamilyPlanList {
    familyPlanList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE }
    ) {
      items {
        value: id
        key: id
        text: name
        periods {
          value: id
          key: id
          text: name
        }
        categories {
          value: id
          key: id
          text: name
          questions {
            value: id
            key: id
            text: enunciated
            options {
              value: id
              key: id
              text: response
            }
          }
        }
      }
    }
  }
`;

export default FilterSurvey;
