import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";

const ReportDetail = ({ id, member }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          compact
          size="tiny"
          icon="arrow circle right"
          onClick={() => setOpen(true)}
        />
      }
    >
      <Modal.Header>Detalle de encuestas realizadas por miembro</Modal.Header>
      <Content id={id} member={member} />
      <Modal.Actions>
        <Button content="Cerrar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

const Content = ({ id, member }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    idFamilyMenmber: id,
  };
  const { loading, error, data } = useQuery(POLLSTER_DETAIL, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Oferta",
      accessor: "offer.name",
    },
    {
      header: "Fecha de acceso a la oferta",
      accessor: "answeDate",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : ""),
    },
    {
      header: "Aliados",
      render: (item) => {
        return item?.offer.allies?.map((e) => `${e.name}`).join(", ");
      },
    },
    {
      header: "Tipo de Oferta",
      accessor: "offer.offerType.name",
    },
  ];

  return (
    <>
      <div style={{ paddingTop: 10, paddingLeft: 20 }}>
        <Form>
          <Form.Group>
            <Form.Field>
              <Label content={`Miembro (${member})`} />
            </Form.Field>
            <Form.Field>
              <Label
                content={`Ofertas asociadas (${
                  data?.offerHeaders?.length || 0
                })`}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <Modal.Content scrolling>
        <CustomTable
          currentPage={currentPage}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          totalPages={1}
          data={data?.offerHeaders || []}
          loading={loading}
          error={error}
          columns={columns}
        />
      </Modal.Content>
    </>
  );
};

const POLLSTER_DETAIL = gql`
  query ($idFamilyMenmber: ID!) {
    offerHeaders(idFamilyMenmber: $idFamilyMenmber) {
      answeDate
      offer {
        allies {
          name
        }
        name
        offerType {
          id
          name
        }
      }
    }
  }
`;

export default ReportDetail;
