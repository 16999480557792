import { useMutation, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Header, Label, Modal, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import DragAndDrop from "../../../../../../../../Components/DragAndDrop";
import CreateOption from "./CreateOption";
import DeleteOption from "./DeleteOption";
import QuestionRecursive from "./";

const Options = ({
  options = [],
  idSurvey,
  question,
  refetch,
  optionQuestionValidation,
  count,
}) => {
  const [stateOptions, setStateOptions] = useState(options || []);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setStateOptions(options);
  }, [options]);

  const handleOption = (currentOption) => {
    const newOptions = [...stateOptions];
    const index = newOptions.findIndex(
      (option) => option?.id === currentOption?.id
    );
    if (index !== -1) {
      newOptions[index] = currentOption;
      setStateOptions(newOptions);
    }
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
  };

  const [mutation] = useMutation(UPDATE_FAMILY_PLAN_OPTIONS, {
    onCompleted,
    onError,
  });

  const saveOptions = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          idQuestion: question?.id,
          options: stateOptions.map((option, i) => ({
            id: option?.id,
            order: i + 1,
            response: option?.response,
            optionQuestion: option?.optionQuestion,
            questionText: option?.questionText,
          })),
        },
      },
    });
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <Button
          onClick={() => setOpen(true)}
          content={`Opciones (${question?.options?.length || 0})`}
        />
      }
    >
      <Modal.Header>Pregunta: {question?.enunciated}</Modal.Header>
      <Modal.Content scrolling>
        <div style={{ padding: 10 }}>
          <Header as="h4">Opciones</Header>
          {stateOptions > 0 && <CreateOption idQuestion={question?.id} refetch={refetch} />}
          <DragAndDrop
            data={stateOptions}
            onChange={(res) => setStateOptions(res)}
          >
            {({ element, index }) => (
              <Option
                refetch={refetch}
                index={index}
                idSurvey={idSurvey}
                option={element}
                handleOption={handleOption}
                optionQuestionValidation={optionQuestionValidation}
                count={count}
              />
            )}
          </DragAndDrop>
          <CreateOption idQuestion={question?.id} refetch={refetch} />
        </div>
      </Modal.Content>
      <Modal.Actions>
        {stateOptions.length > 0 && (
          <Button
            loading={loading}
            disabled={loading}
            content="Guardar"
            onClick={saveOptions}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

const Option = ({
  refetch,
  idSurvey,
  index,
  option,
  actionDelete=true,
  actionCreate,
  actionUpdate,
  handleOption,
  optionQuestionValidation,
  count,
}) => {
  const [stateOption, setStateOption] = useState(option || {});
  const onChange = (e, { value, name }) => {
    const newStateOption = { ...stateOption, [name]: value };
    setStateOption(newStateOption);
    if (typeof handleOption === "function") handleOption(newStateOption);
  };
  return (
    <Segment style={{ marginTop: 10 }}>
      {actionDelete && (
        <div style={{ textAlign: "right" }}>
          <DeleteOption refetch={refetch} id={option?.id} />
        </div>
      )}
      <Label attached="top left">{index + 1}</Label>
      <Form>
        <Form.Group inline>
          {count <= 10 && optionQuestionValidation() && (
            <QuestionRecursive
              idSurvey={idSurvey}
              actionCreate={actionCreate}
              actionDelete={actionDelete}
              actionUpdate={actionUpdate}
              idOption={option?.id}
              count={count}
            />
          )}
        </Form.Group>
        <Form.Input
          fluid
          label="Respuesta"
          name="response"
          value={stateOption?.response}
          onChange={onChange}
        />
        <>
          <Form.Checkbox
            checked={stateOption?.optionQuestion}
            label="Opción pregunta"
            name="optionQuestion"
            onChange={(e, data) =>
              onChange(e, { ...data, value: data.checked })
            }
          />
          {stateOption?.optionQuestion && (
            <Form.TextArea
              style={{ minHeight: 60, maxHeight: 60 }}
              label="Pregunta"
              name="questionText"
              value={stateOption?.questionText || ""}
              onChange={onChange}
            />
          )}
        </>
      </Form>
    </Segment>
  );
};

const UPDATE_FAMILY_PLAN_OPTIONS = gql`
  mutation ($input: UpdateOptionsInput!) {
    updateOptions(input: $input) {
      id
      options {
        id
        order
        optionQuestion
        response
        questionText
      }
    }
  }
`;

export default Options;
