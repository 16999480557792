import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import Selector from "./Selector";

const CreatePermit = ({
  reloadPermissions,
  reload,
  data,
  error = false,
  loading = false
}) => {
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [userGroup, setUserGroup] = useState("");
  const [module, setModule] = useState("");


  const onCompleted = async () => {
    setAction("");
    setModule("");
    setUserGroup("");
    setLoadingSave(false);
    await reload();
    await reloadPermissions();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha creado el permiso de manera exitosa.'
    }).then(() => setOpen(false))
  };


  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onClose = () => {
    setAction("");
    setModule("");
    setUserGroup("");
    setOpen(false);
  };

  const [mutation] = useMutation(CREATE_PERMIT, { onCompleted, onError });
  const handleMutation = () => {
    setLoadingSave(true);
    mutation({
      variables: {
        input: {
          idAction: action,
          idModule: module,
          idUserGroup: userGroup
        }
      }
    });
  }
  return (
    <>
      <Form.Button
        icon='add'
        size="mini"
        onClick={() => setOpen(true)} />
      <Modal onClose={onClose} size="tiny" open={open}>
        <Modal.Header>
          <Icon name='add' /> Crear permiso
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Selector
              loading={loading}
              error={error}
              selection
              value={action}
              onChange={(e, { value }) => setAction(value)}
              data={data?.actionList?.items || []}
              placeholder="Acción" />
            <Selector
              loading={loading}
              error={error}
              selection
              value={userGroup}
              onChange={(e, { value }) => setUserGroup(value)}
              data={data?.userGroupList?.items || []}
              placeholder="Grupo usuario" />
            <Selector
              loading={loading}
              error={error}
              selection
              value={module}
              onChange={(e, { value }) => setModule(value)}
              data={data?.moduleList?.items || []}
              placeholder="Módulo" />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={
            loadingSave ||
            !module.trim() ||
            !action.trim() ||
            !userGroup.trim()
          } onClick={handleMutation} content="Crear" />
          <Button onClick={onClose} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_PERMIT = gql`
  mutation createPermit($input: createPermissionInput!) {
    createPermission(input: $input) {
      id
    }
  }
`;
export default CreatePermit;