import { useMutation, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Header, Label, Modal, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import DragAndDrop from "../../../../../../Components/DragAndDrop";
import CreateOption from "./CreateOption";
import DeleteOption from "./DeleteOption";
import QuestionRecursive from "./QuestionsRecursive";

const Options = ({
  options = [],
  idFamilyPlan,
  question,
  refetch,
  actionCreate,
  actionUpdate,
  actionDelete,
  optionQuestionValidation,
  count
}) => {
  const [stateOptions, setStateOptions] = useState(options || []);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setStateOptions(options);
  }, [options]);

  const handleOption = (currentOption) => {
    const newOptions = [...stateOptions];
    const index = newOptions.findIndex(option => option?.id === currentOption?.id);
    if (index !== -1) {
      newOptions[index] = currentOption;
      setStateOptions(newOptions);
    }
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
  };

  const [mutation] = useMutation(UPDATE_FAMILY_PLAN_OPTIONS, { onCompleted, onError });

  const saveOptions = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          idQuestion: question?.id,
          options: stateOptions.map((option, i) => ({
            id: option?.id,
            order: i + 1,
            response: option?.response,
            optionQuestion: option?.optionQuestion,
            negativeIndicator: option?.negativeIndicator,
            questionText: option?.questionText
          }))
        }
      }
    });
  }
  return (
    <Modal
      onClose={() => setOpen(false)} open={open}
      trigger={<Button onClick={() => setOpen(true)}
        content={`Opciones (${question?.options?.length || 0})`} />}>
      <Modal.Header>
        Pregunta: {question?.enunciated}
      </Modal.Header>
      <Modal.Content scrolling>
        <div style={{ padding: 10 }}>
          <Header as="h4">
            Opciones
          </Header>
          {(actionCreate && stateOptions.length > 2) && (
            <CreateOption
              idQuestion={question?.id}
              refetch={refetch} />
          )}
          <DragAndDrop data={stateOptions} onChange={res => setStateOptions(res)}>
            {({ element, index }) => (
              <Option
                refetch={refetch}
                index={index}
                idFamilyPlan={idFamilyPlan}
                option={element}
                handleOption={handleOption}
                actionCreate={actionCreate}
                actionUpdate={actionUpdate}
                actionDelete={actionDelete}
                optionQuestionValidation={optionQuestionValidation}
                count={count}
                />
            )}
          </DragAndDrop>
          {actionCreate && (
            <CreateOption
              idQuestion={question?.id}
              refetch={refetch} />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        {(actionUpdate && stateOptions.length > 0) && (
          <Button
            loading={loading}
            disabled={loading}
            content="Guardar"
            onClick={saveOptions} />
        )}
      </Modal.Actions>
    </Modal>
  );
}

const Option = ({
  refetch,
  idFamilyPlan,
  index,
  option,
  actionDelete,
  actionCreate,
  actionUpdate,
  handleOption,
  optionQuestionValidation,
  count
}) => {
  const [stateOption, setStateOption] = useState(option || {});
  const onChange = (e, { value, name }) => {
    const newStateOption = { ...stateOption, [name]: value };
    setStateOption(newStateOption);
    if (typeof handleOption === "function") handleOption(newStateOption);
  }
  return (
    <Segment style={{ marginTop: 10 }}>
      {actionDelete && (
        <div style={{ textAlign: 'right' }}>
          <DeleteOption
            refetch={refetch}
            id={option?.id} />
        </div>
      )}
      <Label attached="top left">
        {index + 1}
      </Label>
      <Form>
        <Form.Group inline>
          <Form.Checkbox
            checked={stateOption?.negativeIndicator}
            label="Indicador negativo"
            name="negativeIndicator"
            onChange={(e, data) => onChange(e, { ...data, value: data.checked })} />
          {(count <= 10 && (optionQuestionValidation())) && (
            <QuestionRecursive
              idFamilyPlan={idFamilyPlan}
              actionCreate={actionCreate}
              actionDelete={actionDelete}
              actionUpdate={actionUpdate}
              idOption={option?.id}
              count={count}/>
          )}
        </Form.Group>
        <Form.Input
          fluid
          label="Respuesta"
          name="response"
          value={stateOption?.response}
          onChange={onChange} />
        <>
          <Form.Checkbox
            checked={stateOption?.optionQuestion}
            label="Opción pregunta"
            name="optionQuestion"
            onChange={(e, data) => onChange(e, { ...data, value: data.checked })} />
          {stateOption?.optionQuestion && (
            <Form.TextArea
              style={{ minHeight: 60, maxHeight: 60 }}
              label="Pregunta"
              name="questionText"
              value={stateOption?.questionText || ""}
              onChange={onChange} />
          )}
        </>
      </Form>
    </Segment>
  );
}

const UPDATE_FAMILY_PLAN_OPTIONS = gql`
  mutation($input: UpdateFamilyPlanOptionsInput!){
    updateFamilyPlanOptions(input: $input) {
      id
      options {
        id
        order
        negativeIndicator
        optionQuestion
        response
        questionText
      }
    }
  }
`;

export default Options;