import { useQuery, gql } from "@apollo/client";
import { Form } from "semantic-ui-react";

const InputsFamily = ({
  maritalStatus,
  setMaritalStatus,
  gender,
  clearable,
  search = true,
  selection = true,
  setGender,
  required = true,
  typeIdentification,
  setTypeIdentification
}) => {
  const { loading, error, data } = useQuery(DATA);

  return (
    <>
      <Form.Dropdown
        clearable={clearable}
        required={required}
        scrolling
        placeholder="Filtrar por tipo identificación"
        selection={selection}
        search={search}
        value={typeIdentification}
        onChange={(e, { value }) => setTypeIdentification(value)}
        loading={loading}
        error={error}
        options={data?.typeIdentification?.items || []}
        label="Tipo identificación" />
      <Form.Dropdown
        required={required}
        scrolling
        placeholder="Filtrar por género"
        clearable={clearable}
        selection={selection}
        search={search}
        value={gender}
        onChange={(e, { value }) => setGender(value)}
        loading={loading}
        error={error}
        options={data?.gender?.items || []}
        label="Género" />
      <Form.Dropdown
        required={required}
        scrolling
        placeholder="Filtrar por estado civil"
        clearable={clearable}
        selection={selection}
        search={search}
        value={maritalStatus}
        onChange={(e, { value }) => setMaritalStatus(value)}
        loading={loading}
        error={error}
        options={data?.maritalStatus?.items || []}
        label="Estado civil" />
    </>
  );
}


const DATA = gql`
query {
  maritalStatus: parameterValueList(
    page: { number: 1, limit: 100 }
    filter: { status: ACTIVE, idParameter: "2" }
  ) {
    items {
      value: id
      text: name
      key: id
    }
  }
  typeIdentification: parameterValueList(
    page: { number: 1, limit: 100 }
    filter: { status: ACTIVE, idParameter: "3" }
  ) {
    items {
      value: id
      text: name
      key: id
    }
  }
  gender: parameterValueList(
    page: { number: 1, limit: 100 }
    filter: { status: ACTIVE, idParameter: "4" }
  ) {
    items {
      value: id
      text: name
      key: id
    }
  }
}
`;

export default InputsFamily;