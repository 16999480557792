import React, { useState } from "react";
import { Form, Modal, Tab } from "semantic-ui-react";
import InputsEducation from "./InputsEducation";
import InputsEmployability from "./InputsEmployability";
import InputsFamily from "./InputsFamily";
import InputsHealth from "./InputsHealth";
import LeaderInput from "./LeaderInput";
import "./disabledView.css";
import { gql, useQuery } from "@apollo/client";

const ViewFamilyMember = ({ user, triggerFluid = true, refetch }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(
    `${user?.firstName || ""} ${
      user?.secondName ? ` ${user?.secondName}` : ""
    } ${user?.surname ? ` ${user?.surname}` : ""} ${
      user?.secondSurname ? ` ${user?.secondSurname}` : ""
    }` || ""
  );
  const [address, setAddress] = useState(user?.address || "");
  const [socialSecurity, setSocialSecurity] = useState(
    user?.socialSecurity?.id || ""
  );
  const [leader, setLeader] = useState(user?.familyLeader || null);
  const [jobPerformed, setJobPerformed] = useState(user?.jobPerformed || "");
  const [occupation, setOccupation] = useState(user?.occupation?.id || "");
  const [educativeEstablishment, setEducativeEstablishment] = useState(
    user?.educativeEstablishment || ""
  );
  const [educativeLevel, setEducativeLevel] = useState(
    user?.educativeLevel?.id || ""
  );
  const [title, setTitle] = useState(user?.title || "");
  const [lastGradeReached, setLastGradeReached] = useState(
    user?.lastGradeReached || ""
  );

  const [eps, setEps] = useState(user?.eps || "");
  const [inabilities, setInabilities] = useState(user?.inabilities?.id || "");

  const [neighborhood, setNeighborhood] = useState(user?.neighborhood || "");
  const [nationality, setNationality] = useState(user?.nationality?.id || "");
  const [regularization, setRegularization] = useState(
    user?.regularization?.id || ""
  );

  const [isFamilyLeader, setIsFamilyLeader] = useState(
    user?.isFamilyLeader || ""
  );
  const [leaderRelation, setLeaderRelation] = useState(
    user?.leaderRelation?.id || ""
  );

  const [healthAffiliationDate, setHealthAffiliationDate] = useState(
    user?.healthAffiliationDate || ""
  );
  const [supportingElement, setSupportingElement] = useState(
    user?.supportingElement?.id || ""
  );
  const [sisben, setSisben] = useState(user?.sisben || "");
  const [sisbenCategory, setSisbenCategory] = useState(
    user?.sisbenCategory?.id || ""
  );
  const [pregnant, setPregnant] = useState(user?.pregnant || "");
  const [attendsPrenatalControl, setAttendsPrenatalControl] = useState(
    user?.attendsPrenatalControl || ""
  );
  const [PrenatalUnassistanceReason, setPrenatalUnassistanceReason] = useState(
    user?.PrenatalUnassistanceReason?.id || ""
  );

  const [studying, setStudying] = useState(user?.studying || "");
  const [NotStudyingReason, setNotStudyingReason] = useState(
    user?.NotStudyingReason?.id || ""
  );

  const [contractType, setContractType] = useState(
    user?.contractType?.id || ""
  );
  const [independentType, setIndependentType] = useState(
    user?.independentType || ""
  );
  const [undertakes, setUndertakes] = useState(user?.undertakes || "");
  const [undertakeName, setUndertakename] = useState(user?.undertakeName || "");
  const [artOrWork, setArtOrWork] = useState(user?.artOrWork || "");
  const [artOrWorkType, setArtOrWorkType] = useState(user?.artOrWorkType || "");
  const { loading: loadingData, error, data } = useQuery(DATA);
  const [identification, setIdentification] = useState(
    user?.identification || ""
  );
  const [birthDate, setBirthDate] = useState(user?.birthDate || "");
  const [exitDate, setExitDate] = useState(user?.exitDate || "");

  const [phone, setPhone] = useState(user?.phone || "");
  const [mail, setMail] = useState(user?.mail || "");
  const [typeIdentification, setTypeIdentification] = useState(
    user?.typeIdentification?.id || ""
  );
  const [gender, setGender] = useState(user?.gender?.id || "");
  const [maritalStatus, setMaritalStatus] = useState(
    user?.maritalStatus?.id || ""
  );
  const [expeditionDocumentDate, setExpeditionDocumentDate] = useState(
    user?.expeditionDocumentDate || ""
  );
  const [expirationDocumentDate, setExpirationDocumentDate] = useState(
    user?.expirationDocumentDate || ""
  );

  const panes = [
    {
      menuItem: "Datos básicos",
      render: () => (
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              readOnly={true}
              value={name}
              onChange={(e, { value }) => setName(value)}
              label="Primer Nombre"
            />
          </Form.Group>
          <Form.Input
            required
            readOnly={true}
            value={identification}
            onChange={(e, { value }) => setIdentification(value)}
            label="Identificación"
          />
          <InputsFamily
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            gender={gender}
            setGender={setGender}
            typeIdentification={typeIdentification}
            setTypeIdentification={setTypeIdentification}
            setNationality={setNationality}
            disabled={true}
            nationality={nationality}
            regularization={regularization}
            setRegularization={setRegularization}
          />
          <Form.Group widths="equal">
            <Form.Input
              readOnly
              value={mail}
              onChange={(e, { value }) => setMail(value)}
              label="Correo (opcional)"
            />
            <Form.Input
              readOnly
              value={phone}
              onChange={(e, { value }) => setPhone(value)}
              label="Teléfono (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Checkbox
              checked={isFamilyLeader}
              readOnly
              onChange={(e, { checked }) => {
                setLeaderRelation("");
                setIsFamilyLeader(checked);
              }}
              label="¿Es líder de familia?"
            />
          </Form.Group>

          {!isFamilyLeader && (
            <Form.Group widths="equal">
              <Form.Dropdown
                required={true}
                scrolling
                disabled={true}
                placeholder="Parentezco con el líder familiar"
                clearable={false}
                selection={true}
                search={true}
                value={leaderRelation}
                onChange={(e, { value }) => setLeaderRelation(value)}
                loading={loadingData}
                error={error}
                options={data?.relationship?.items || []}
                label="Parentezco con el líder familiar"
              />
            </Form.Group>
          )}
          <Form.Group widths="equal">
            <Form.Input
              readOnly
              value={address}
              onChange={(e, { value }) => setAddress(value)}
              label="Dirección (opcional)"
            />
            <Form.Input
              readOnly
              value={neighborhood}
              onChange={(e, { value }) => setNeighborhood(value)}
              label="Barrio (opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              type="date"
              readOnly
              value={birthDate}
              onChange={(e, { value }) => setBirthDate(value)}
              label="Fecha de nacimiento"
            />
            <Form.Input
              type="date"
              readOnly
              value={expeditionDocumentDate}
              onChange={(e, { value }) => setExpeditionDocumentDate(value)}
              label="Fecha de expedición del documento (Opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              type="date"
              readOnly
              value={expirationDocumentDate}
              onChange={(e, { value }) => setExpirationDocumentDate(value)}
              label="Fecha de vencimiento (Opcional)"
            />
            <Form.Input
              type="date"
              readOnly
              value={exitDate}
              onChange={(e, { value }) => setExitDate(value)}
              label="Fecha de salida (Opcional)"
            />
          </Form.Group>
        </Form>
      ),
    },
    {
      menuItem: "Datos salud",
      render: () => (
        <Form>
          <InputsHealth
            disabled={true}
            socialSecurity={socialSecurity}
            setSocialSecurity={setSocialSecurity}
            eps={eps}
            setEps={setEps}
            inabilities={inabilities}
            setInabilities={setInabilities}
            healthAffiliationDate={healthAffiliationDate}
            setHealthAffiliationDate={setHealthAffiliationDate}
            supportingElement={supportingElement}
            setSupportingElement={setSupportingElement}
            sisben={sisben}
            setSisben={setSisben}
            sisbenCategory={sisbenCategory}
            setSisbenCategory={setSisbenCategory}
            gender={gender}
            birthDate={birthDate}
            pregnant={pregnant}
            setPregnant={setPregnant}
            attendsPrenatalControl={attendsPrenatalControl}
            setAttendsPrenatalControl={setAttendsPrenatalControl}
            PrenatalUnassistanceReason={PrenatalUnassistanceReason}
            setPrenatalUnassistanceReason={setPrenatalUnassistanceReason}
          />
        </Form>
      ),
    },
    {
      menuItem: "Datos educación",
      render: () => (
        <Form>
          <InputsEducation
            disabled={true}
            lastGradeReached={lastGradeReached}
            setLastGradeReached={setLastGradeReached}
            title={title}
            setTitle={setTitle}
            educativeLevel={educativeLevel}
            setEducativeLevel={setEducativeLevel}
            studying={studying}
            setStudying={setStudying}
            NotStudyingReason={NotStudyingReason}
            setNotStudyingReason={setNotStudyingReason}
            birthDate={birthDate}
          />
        </Form>
      ),
    },
    {
      menuItem: "Datos empleabilidad",
      render: () => (
        <Form>
          <InputsEmployability
            disabled={true}
            jobPerformed={jobPerformed}
            setJobPerformed={setJobPerformed}
            educativeEstablishment={educativeEstablishment}
            setEducativeEstablishment={setEducativeEstablishment}
            occupation={occupation}
            setOccupation={setOccupation}
            contractType={contractType}
            setContractType={setContractType}
            independentType={independentType}
            setIndependentType={setIndependentType}
            undertakes={undertakes}
            undertakeName={undertakeName}
            setUndertakename={setUndertakename}
            setUndertakes={setUndertakes}
            setArtOrWork={setArtOrWork}
            setArtOrWorkType={setArtOrWorkType}
            artOrWork={artOrWork}
            artOrWorkType={artOrWorkType}
          />
        </Form>
      ),
    },
    {
      menuItem: "Líder familiar",
      render: () => (
        <Form>
          <LeaderInput
            disabled={true}
            leader={leader}
            setLeader={setLeader}
            refetch={refetch}
          />
        </Form>
      ),
    },
  ];
  return (
    <Modal
      trigger={
        <Form.Button
          onClick={() => setOpen(true)}
          icon="search plus"
          size="tiny"
        />
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Ver miembro familiar</Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
    </Modal>
  );
};

const DATA = gql`
  query {
    relationship: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "20" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default ViewFamilyMember;
