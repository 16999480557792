import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import PollsterDetail from "./PollsterDetail";
import Swal from "sweetalert2";

const Pollster = () => {
  const filter = {};
  let periods = [];
  const [idSurvey, setIdSurvey] = useState("");
  const [idPeriod, setIdPeriod] = useState("");
  const [progress, setProgress] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const { loading, error, data } = useQuery(SURVEYS, {
    fetchPolicy: "network-only",
  });
  if (idSurvey.trim()) {
    const survey = data?.surveys?.find((v) => v.value === idSurvey);
    periods = survey?.periods || [];
    filter.idSurvey = idSurvey;
  }
  if (idPeriod) filter.idPeriod = idPeriod;
  if (progress.trim()) filter.progress = progress;

  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      filter.initialDate = `${initialDate} 00:00:00`;
      filter.finalDate = `${finalDate} 23:59:59`;
    }
  }

  const handleSurvey = (e, { value }) => {
    setIdSurvey(value);
    setIdPeriod("");
    setProgress("");
    setInitialDate("");
    setFinalDate("");
  };

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Dropdown
            value={idSurvey}
            placeholder="Filtrar por encuesta"
            scrolling
            clearable
            label="Encuesta"
            loading={loading}
            error={error}
            options={data?.surveys || []}
            onChange={handleSurvey}
          />
          {idSurvey && (
            <>
              <Form.Dropdown
                value={idPeriod}
                placeholder="Filtrar por periodo"
                scrolling
                clearable
                label="Periodo"
                loading={loading}
                error={error}
                options={periods}
                onChange={(e, { value }) => setIdPeriod(value)}
              />
              <Form.Input
                type="date"
                size="mini"
                loading={loading}
                error={error}
                value={initialDate}
                onChange={(e, { value }) => setInitialDate(value)}
                label="Fecha Inicial"
              />
              <Form.Input
                type="date"
                loading={loading}
                error={error}
                size="mini"
                value={finalDate}
                onChange={(e, { value }) => setFinalDate(value)}
                label="Fecha Final"
              />
              <Form.Dropdown
                value={progress}
                placeholder="Progreso"
                scrolling
                clearable
                label="Estado encuesta"
                options={[
                  { text: "Completada", value: "COMPLETED", key: 1 },
                  { text: "Incompleta", value: "INCOMPLETE", key: 2 },
                ]}
                onChange={(e, { value }) => setProgress(value)}
              />
            </>
          )}
        </Form.Group>
      </Form>
      {Object.keys(filter).length > 0 && <Content filter={filter} />}
    </>
  );
};

const Content = ({ filter }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const variables = {
    filter,
    page: { number: currentPage, limit: 10 },
  };
  const { loading, error, data } = useQuery(POLLSETR_LIST, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Nombre",
      accessor: "user.name",
    },
    {
      header: "Documento",
      accessor: "user.document",
    },
    {
      header: "Teléfono",
      accessor: "user.phone",
    },
    {
      header: "Cantidad de encuestas",
      accessor: "count",
    },
    {
      header: "Detalle",
      render: (info) => (
        <PollsterDetail
          id={info?.id}
          filter={filter}
          pollster={info?.user?.name}
        />
      ),
    },
  ];
  return (
    <>
      <Form>
        <Form.Field>
          <Label
            content={`Usuarios encuestados (${
              data?.reportPollster?.totalSurvey || 0
            })`}
          />
        </Form.Field>
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.reportPollster?.totalPages || 0}
        data={data?.reportPollster?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const POLLSETR_LIST = gql`
  query ($page: PageInput!, $filter: ReportPollsterFilterInput!) {
    reportPollster(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      totalSurvey
      items {
        id
        user {
          id
          document
          phone
          name
        }
        count
      }
    }
  }
`;

const SURVEYS = gql`
  query {
    surveys {
      value: id
      text: name
      key: id
      periods {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default Pollster;
