import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateAlly = ({ refetch, ally }) => {
  const [name, setName] = useState(ally?.name || "");
  const [contactName, setContactName] = useState(ally?.contactName || "");
  const [nit, setNit] = useState(ally?.nit || "");
  const [phone, setPhone] = useState(ally?.phone || "");
  const [mail, setMail] = useState(ally?.mail || "");
  const [status, setStatus] = useState(ally?.status || "");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el acción de manera exitosa.",
    });
  };
  const clear = () => {
    setName(ally?.name || "");
    setContactName(ally?.contactName || "");
    setNit(ally?.nit || "");
    setPhone(ally?.phone || "");
    setMail(ally?.mail || "");
    setStatus(ally?.status || "");
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_ACTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: { id: ally?.id, name, phone, mail, nit, status, contactName },
    });
    clear();
  };
  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
    { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
  ];
  
  useEffect(() => {
    clear();
  }, [open]);

  return (
    <>
      <Button size="tiny" compact icon="edit" onClick={() => setOpen(true)} />
      <Modal size="small" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Editar aliado {ally?.name}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                value={name}
                onChange={(e, { value }) => setName(value)}
                label="Nombre"
              />
              <Form.Input
                value={contactName}
                onChange={(e, { value }) => setContactName(value)}
                label="Nombre contacto"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                value={nit}
                onChange={(e, { value }) => setNit(value)}
                label="Nit"
              />
              <Form.Input
                value={phone}
                onChange={(e, { value }) => setPhone(value)}
                label="Teléfono"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                value={mail}
                onChange={(e, { value }) => setMail(value)}
                label="Correo"
              />
              <Form.Dropdown
                required
                label="Estado"
                value={status}
                selection
                onChange={(e, { value }) => setStatus(value)}
                options={options}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={handleMutation}
            loading={loading}
            disabled={
              (!name.trim() && !nit.trim() && !phone.trim() && !mail.trim()) ||
              loading
            }
            content="Guardar"
          />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const UPDATE_ACTION = gql`
  mutation (
    $id: ID!
    $name: String!
    $phone: String!
    $nit: String!
    $mail: String!
    $status: Status
    $contactName: String!
  ) {
    updateAlly(
      id: $id
      name: $name
      phone: $phone
      nit: $nit
      mail: $mail
      status: $status
      contactName: $contactName
    ) {
      id
    }
  }
`;

export default UpdateAlly;
