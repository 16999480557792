import React, { useState } from "react";
import { Button, Form, Label, Modal } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";

function Beneficiarios({ beneficiaries, error, loading }) {
  const [open, setOpen] = useState(false);
  const [offer, setOffer] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Número de telefono",
      accessor: "phone",
    },
    {
      header: "Dirección",
      accessor: "address",
    },
    {
      header: "Identificacion",
      accessor: "identification",
    },
  ];

  return (
    <>
      <Button
        size="tiny"
        compact
        icon="eye"
        content="ver"
        onClick={() => setOpen(true)}
      />
      <Modal size="large" onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Ofertas</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label content={`Cantidad (${beneficiaries?.length || 0})`} />
              </Form.Field>
              <Form.Input
                action={{
                  icon: "trash",
                  size: "mini",
                  content: "Limpiar",
                  onClick: () => setOffer(""),
                }}
                value={offer}
                size="mini"
                onChange={(e, { value }) => setOffer(value)}
                placeholder="Búsqueda por nombre"
              />
            </Form.Group>
          </Form>
          <CustomTable
            totalPages={Math.ceil(beneficiaries?.length / 10) || 0}
            onPageChange={(_, { activePage }) => setCurrentPage(activePage)}
            data={
              beneficiaries
                ?.filter((item) =>
                  item?.name
                    .toLowerCase()
                    .includes(offer.toLocaleLowerCase().trim())
                )
                .slice(10 * (currentPage - 1), 10 * (currentPage - 1) + 10) ||
              []
            }
            loading={loading}
            error={error}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

export default Beneficiarios;
