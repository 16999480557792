import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { client } from './client';
import { ApolloProvider } from '@apollo/client/react';
import 'semantic-ui-css/semantic.min.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

ReactDOM.render(
  <ApolloProvider client={client}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
