import React, { useCallback } from "react";
import update from 'immutability-helper';
import ElementDragAndDrop from "./Element";
const DragAndDrop = ({ data = [], children, onChange }) => {
  const moveElement = useCallback((dragIndex, hoverIndex) => {
    const dragElement = data[dragIndex];
    const list = update(data, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragElement],
      ],
    });
    if (typeof onChange === "function") onChange(list);
  }, [data]);

  return data.map((element, index) => (
    <ElementDragAndDrop
      key={element?.id}
      id={element?.id}
      index={index}
      moveElement={moveElement}>
      {children({ element, index })}
    </ElementDragAndDrop>
  ));

}

export default DragAndDrop;