import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Accordion, Form, Icon, Input, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import MultiInputRecursive from "../../../../../../Components/MultiInputRecursive";

const Categories = ({ idAnswerHeader, data = [], handleBack }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listResponse, setListResponse] = useState([]);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }
  const onCompleted = () => {
    Swal.fire({
      icon: 'success',
      title: 'Su encuesta fue enviada exitosamente.',
    }).then(() => handleBack())
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(SEND_ANSWERS, { onCompleted, onError });

  const handleChangeFile = ({
    target: {
      files: [file],
    },
  }, { enunciado, idcampo, name, pos }) => {
    /*
    var fileSize = file?.size / 1024 / 1024; // in MiB
    let list = [...listResponse];
    const info = {
      enunciadoCampo: enunciado,
      idCampo: idcampo,
      urlArchivo: file
    }
    if (fileSize > 10) {
      list[pos] = null;
      setListResponse(list);
      return Swal.fire({
        'icon': 'error',
        'title': 'Ops...',
        'text': 'El tamaño del archivo supera los 10mb.'
      });
    }

    list[pos] = info;
    setListResponse(list);
    */
  }

  const handleOnChange = (e, {
    value,
    values,
    name,
    enunciado,
    responseText,
    questionText,
    optionquestion,
    idcampo,
    tipo,
    pos
  }) => {

    const detailAnswerInput = {
      enunciated: enunciado,
      order: pos,
      idQuestion: idcampo,
    }

    let list = [...listResponse];

    if (tipo === "Texto" || tipo === "Opcion") detailAnswerInput.answers = [{ answer: value }];

    if (tipo === "Multiple-Opcion") detailAnswerInput.answers = values.map(v => ({ answer: v }));

    if (tipo === "Radio") {
      const resAnswer = { answer: value };
      if (optionquestion === "true") {
        detailAnswerInput.nameQuestion = questionText || "";
        resAnswer.answerQuestion = responseText || "";
      }
      detailAnswerInput.answers = [resAnswer];
    }

    list[idcampo] = detailAnswerInput;
    setListResponse(list);

  }
  const disabled = () => data.some(category => {
    return category?.questions?.some(question => {
      const answer = listResponse[question?.id];
      if (answer?.nameQuestion !== undefined) return answer?.answers?.some(v => !v?.answerQuestion?.trim())
      if (question?.required) {
        try {
          return (
            !answer || !answer?.answers?.length
            || answer?.answers?.some(v => !v?.answer?.trim())
          )
        } catch (e) {
          return (
            !answer || !answer?.answers?.length
            || answer?.answers?.some(v => !v?.answer)
          )
        }
        
      }
      return false;
    });
  });


  const handleSendAnswers = () => {
    setLoading(true);
    let answers = listResponse.filter(v => v !== undefined)
    answers = answers.map((self) => {
      if (self.answers.length > 0) {
        self.answers = self.answers.map((element) => {
          if (element?.answer.hasOwnProperty("__typename")) {
            element.answer = element.answer.response
          }
          return element;
        })
      }
      return self;
    })
    const input = {
      idAnswerHeader,
      detailAnswers: answers
    }
    mutation({ variables: { input } });
  }


  return (
    <>
      <Form size="large" >
        <Accordion>
          {data.map((category, key) => (
            <Segment key={key}>
              <Accordion.Title
                active={activeIndex === key}
                index={key}
                onClick={handleClick} >
                <Label attached={activeIndex === key ? "top left" : "top"}>
                  <Icon name='dropdown' />
                  {category?.name}
                </Label>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === key}>
                {category?.questions?.map((question, key) => (
                  <MultiInputRecursive
                    id={question?.id}
                    num={key}
                    type={question?.type?.name}
                    name={question?.name}
                    onChange={handleOnChange}
                    onChangeFile={handleChangeFile}
                    key={key}
                    options={question?.options}
                    required={question?.required}
                    label={question?.enunciated}
                    control={Input}
                    placeholder={'Rellene la respuesta'} />
                ))}
              </Accordion.Content>
            </Segment>
          ))}
        </Accordion>
        <Form.Button disabled={disabled() || loading} style={{ marginTop: 30 }} content="Enviar" onClick={handleSendAnswers} />
      </Form>
    </>
  );
}

const SEND_ANSWERS = gql`
  mutation($input: SendAnswersInput!) {
    sendAnswers(input: $input) {
      id
    }
  }
`;

export default Categories;