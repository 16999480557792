import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdatePeriod = ({ refetch, period, dataDropdown, type }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(period?.name || "");
  const [startDate, setStartDate] = useState(period?.startDate || "");
  const [endDate, sertEndDate] = useState(period?.endDate || "");
  const [optionDropdown, setOptionDropdown] = useState("");
  const [loading, setLoading] = useState(false);
  const index = dataDropdown?.findIndex(
    (v) =>
      v?.value === period.survey?.id ||
      v?.value === period.offer?.id ||
      v?.value === period.familyPlan?.id
  );
  const valueData = index !== -1 ? dataDropdown[index]?.value : "";
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el periodo de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_PERIOD, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      id: period.id,
      name,
      startDate,
      endDate,
    };

    switch (type) {
      case "SURVEY":
        input.survey = optionDropdown;
        break;
      case "OFFER":
        input.offer = optionDropdown;
        break;
      case "FAMILYPLAN":
        input.offer = optionDropdown;
        break;
      default:
        break;
    }

    mutation({
      variables: {
        input,
      },
    });
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      trigger={<Button size="mini" onClick={() => setOpen(true)} icon="edit" />}
      size="mini"
    >
      <Modal.Header>Editar periodo</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Nombre"
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Input
            type="date"
            value={startDate}
            onChange={(e, { value }) => setStartDate(value)}
            label="Fecha inicio"
          />
          <Form.Input
            type="date"
            disabled={!startDate}
            value={endDate}
            min={startDate}
            onChange={(e, { value }) => sertEndDate(value)}
            label="Fecha final"
          />
          <Form.Dropdown
            selection
            value={optionDropdown || valueData}
            options={dataDropdown}
            onChange={(e, { value }) => setOptionDropdown(value)}
            label={
              type === "survey"
                ? "Encuestas"
                : type === "offer"
                ? "Ofertas"
                : ""
            }
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!name.trim() || !startDate.trim() || !endDate.trim()}
          loading={loading}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  );
};

const UPDATE_PERIOD = gql`
  mutation ($input: UpdatePeriodInput!) {
    updatePeriod(input: $input) {
      id
      endDate
      name
      startDate
    }
  }
`;

export default UpdatePeriod;
