import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const DeleteOption = ({ idOption }) => {
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    setLoading(false);
    window.location.reload();
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(DELETE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({ variables: { id: idOption } });
  }
  return (
    <Form.Button
      size="mini"
      fluid
      loading={loading}
      disabled={loading}
      onClick={handleMutation}
      color="red"
      content="Eliminar" />
  )
}

const DELETE_OPTION = gql`
  mutation($id: ID!){
    updateOption(input: { id: $id status: REMOVED }) {
      id
    }
  }
`;


export default DeleteOption;