import { useQuery, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";
import Selector from "../../Permissions/Selector";
import Afiliado from "../../Project/Afiliado";
import Programas from "../../Project/Programas";
import Ofertas from "../../Project/Ofertas";
import DownloadReport from "./DownloadReport";

const Project = () => {
  return (
    <>
      <Content />
    </>
  );
};
const reports = [
  { id: 0, name: "Seleciona el reporte" },
  { id: 1, name: "Beneficiarios por Proyecto" },
  { id: 2, name: "Programas por Proyecto" },
  { id: 3, name: "Ofertas por Proyecto" },
];

const Content = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const variables = {
    filter: {
      status: "ACTIVE",
      name: "",
    },
    //page: { number: currentPage, limit: 15 },
  };
  const [reportType, setReportType] = useState(0);
  const { data, loading, error, refetch } = useQuery(GET_PROJECT, {
    variables,
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Nombre",
      accessor: "name",
    },
    {
      header: "Estrategia",
      accessor: "strategy",
    },
    {
      header: "Usuario Encargado",
      accessor: "userCharge.name",
    },
  ];

  if (reportType === 1) {
    columns.push({
      header: "Beneficiarios",
      render: ({ beneficiaries, id }) => (
        <Afiliado
          idProject={parseInt(id)}
          beneficiariesList={beneficiaries?.map((data) => ({
            ...data,
            name: `${data.firstName}${
              data?.secondName ? " " + data?.secondName : " "
            }${" " + data.surname}${
              data?.secondSurname ? " " + data?.secondSurname : " "
            }`,
          }))}
          error={error}
          loading={loading}
          refetch={refetch}
        />
      ),
    });
  }

  if (reportType === 2) {
    columns.push({
      header: "Programas",
      render: ({ programs, id }) => (
        <Programas
          idProject={parseInt(id)}
          programs={programs}
          error={error}
          loading={loading}
          refetch={refetch}
        />
      ),
    });
  }

  if (reportType === 3) {
    columns.push({
      header: "Ofertas",
      render: ({ programs }) => {
        const list = [];
        programs?.forEach((item) =>
          item.offer.forEach((item2) => {
            list.push({ programs: item.name, ...item2 });
          })
        );

        return (
          <Ofertas
            offers={list}
            error={error}
            loading={loading}
            refetch={refetch}
          />
        );
      },
    });
  }
  useEffect(() => {
    switch (reportType) {
      case 1:
        setList(
          data?.projectList?.items.filter(
            (item) => item.beneficiaries.length > 0
          )
        );
        break;
      case 2:
        setList(
          data?.projectList?.items.filter((item) => item.programs.length > 0)
        );
        break;
      case 3:
        const result = data?.projectList?.items.filter(({ programs }) => {
          const list = [];
          programs?.forEach((item) =>
            item.offer.forEach((item2) => {
              list.push(item2);
            })
          );
          return list.length > 0;
        });
        setList(result);
        break;

      default:
        setList(
          data?.projectList?.items.filter(
            (item) => item.beneficiaries.length > 0
          )
        );
        break;
    }
  }, [reportType]);

  return (
    <>
      <Form style={{ padding: 10 }}>
        <Form.Group>
          <Label content={`Total Projectos (${list?.length || 0})`} />
          <DownloadReport list={list} reportType={reportType} />
        </Form.Group>
        <Selector
          loading={!data ? true : false}
          error={error}
          value={reportType}
          onChange={(e, { value }) => {
            setReportType(value);
          }}
          data={
            reports.map((e) => ({
              value: e.id,
              text: e.name,
              key: e.name,
            })) || []
          }
          placeholder="Tipo de Reporte"
        />
      </Form>
      <br />
      <CustomTable
        currentPage={currentPage}
        onPageChange={(_, { activePage }) => setCurrentPage(activePage)}
        totalPages={Math.ceil(list?.length / 10) || 0}
        data={list || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </>
  );
};

const GET_PROJECT = gql`
  query ProjectList($filter: ProjectFilter!) {
    projectList(filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        strategy
        userCharge {
          id
          name
        }
        programs {
          id
          name
          offer {
            id
            name
            description
          }
        }
        beneficiaries {
          firstName
          secondName
          surname
          secondSurname
          neighborhood
          identification
          address
          id
          phone
        }
        status
      }
    }
  }
`;

export default Project;
