import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal, Label } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateUpdateParameter = ({ reload, parameter }) => {
  const [name, setName] = useState(parameter?.name ? parameter?.name : "");
  const [parameterValues, setParameterValues] = useState(
    parameter?.parameterValues
      ? parameter?.parameterValues?.map((item) => ({
          id: item.id,
          index: item.order,
          order: item.order,
          name: item.name,
        }))
      : [{ index: 1, name: "", order: 1 }]
  );
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const onCompleted = async () => {
    setLoadingSave(false);
    await reload();
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: `Se ha ${parameter?.id ? "actualizado" : "creado"} el parámetro.`,
    }).then(() => setOpen(false));
    if (parameter?.id) {
      setName(parameter?.name);
      setParameterValues(
        parameterValues?.map((item) => ({
          id: item.id,
          index: item.order,
          order: item.order,
          name: item.name,
        }))
      );
    } else {
      setName("");
      setParameterValues([{ index: 1, name: "", order: 1 }]);
    }
  };

  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onClose = () => {
    if (parameter?.id) {
      setName(parameter?.name);
      setParameterValues(
        parameter?.parameterValues?.map((item) => ({
          id: item.id,
          index: item.order,
          order: item.order,
          name: item.name,
        }))
      );
    } else {
      setName("");
      setParameterValues([{ index: 1, name: "", order: 1 }]);
    }
    setOpen(false);
  };

  const [mutationCreate] = useMutation(CREATE_PARAMETER, {
    onCompleted,
    onError,
  });

  const handleMutationCreate = () => {
    parameterValues.map((item) => delete item.index);
    setLoadingSave(true);
    mutationCreate({
      variables: {
        input: {
          name: name,
          parameterValues: parameterValues,
        },
      },
    });
  };

  const [mutationEdit] = useMutation(UPDATE_PARAMETER, {
    onCompleted,
    onError,
  });
  const handleMutationEdit = () => {
    parameterValues.map((item) => delete item.index);
    setLoadingSave(true);
    mutationEdit({
      variables: {
        input: {
          id: parameter?.id,
          name: name,
          parameterValues: parameterValues,
        },
      },
    });
  };

  const handleInputParameterValues = (event, index) => {
    parameterValues.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setParameterValues([...parameterValues]);
  };

  const addParameterValue = () => {
    const last = parameterValues[parameterValues.length - 1];
    setParameterValues([
      ...parameterValues,
      { index: last.index + 1, order: last.order + 1 },
    ]);
  };

  const deleteParameterValue = (e, index) => {
    const valueDeleted = parameterValues.filter((item) => item.index !== index);
    valueDeleted.map((item, index) => {
      item["order"] = index + 1;
      return item;
    });
    valueDeleted.sort((a, b) => (a.order < b.order ? -1 : 1));
    setParameterValues([...valueDeleted]);
  };

  const orderUp = (order) => {
    parameterValues.map((item) => {
      if (item.order === order) {
        item["order"] = item.order - 1;
      } else if (item.order === order - 1) {
        item["order"] = item.order + 1;
      }
      return item;
    });
    parameterValues.sort((a, b) => (a.order < b.order ? -1 : 1));
    setParameterValues([...parameterValues]);
  };

  const orderDown = (order) => {
    parameterValues.map((item) => {
      if (item.order === order) {
        item["order"] = item.order + 1;
      } else if (item.order === order + 1) {
        item["order"] = item.order - 1;
      }
      return item;
    });
    parameterValues.sort((a, b) => (a.order < b.order ? -1 : 1));
    setParameterValues([...parameterValues]);
  };

  return (
    <>
      {parameter?.id ? (
        <Form.Button
          icon="edit"
          size="mini"
          onClick={() => setOpen(true)}
          content="Editar"
        />
      ) : (
        <Form.Button icon="add" size="mini" onClick={() => setOpen(true)} />
      )}
      <Modal onClose={onClose} size="tiny" open={open}>
        <Modal.Header>
          <Icon name="add" />{" "}
          {parameter?.id ? "Actualizar parámetro" : "Crear parámetro"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="Parámetro"
              value={name}
              name="name"
              onChange={(e, { value }) => setName(value)}
              required
            />
            <Form.Field label={{ children: "Valores párametros:" }} />
            {parameterValues
              ?.sort((a, b) => (a.order < b.order ? -1 : 1))
              .map((item, index) => (
                <Form.Group widths="equal" key={item.id}>
                  <Label basic>{item.order}</Label>
                  {parameterValues.length !== index + 1 && (
                    <Button basic icon onClick={() => orderDown(index + 1)}>
                      <Icon name="arrow down" />
                    </Button>
                  )}
                  {index + 1 !== 1 && (
                    <Button basic icon onClick={() => orderUp(index + 1)}>
                      <Icon name="arrow up" />
                    </Button>
                  )}
                  <Form.Input
                    placeholder="Valor parámetro"
                    value={item.name}
                    name="name"
                    onChange={(e) => handleInputParameterValues(e, item.index)}
                    required
                  />
                  <Button
                    basic
                    icon
                    onClick={(e) => deleteParameterValue(e, item.index)}
                  >
                    <Icon name="close" />
                  </Button>
                </Form.Group>
              ))}
            <Button basic icon onClick={addParameterValue}>
              <Icon name="add" /> Añadir
            </Button>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loadingSave || !name.trim()}
            onClick={parameter?.id ? handleMutationEdit : handleMutationCreate}
            content={parameter?.id ? "Actualizar" : "Crear"}
          />
          <Button onClick={onClose} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_PARAMETER = gql`
  mutation CreateParameter($input: CreateParameterInput!) {
    createParameter(input: $input) {
      id
    }
  }
`;

const UPDATE_PARAMETER = gql`
  mutation UpdateParameter($input: UpdateParameterInput!) {
    updateParameter(input: $input) {
      id
    }
  }
`;

export default CreateUpdateParameter;
