import { useMutation, gql } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Icon, Message, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const ObservationList = ({ fluid = false, content = "", id, data, refetch }) => {

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [openObservation, setOpenObservation] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setDescription(false);
    setLoading(false);
    setOpenObservation("");
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(CREATE_OBSERVATION, { onError, onCompleted });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        id,
        description
      }
    })
  }
  return (
    <Popup
      on='click'
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button fluid={fluid} content={content || "Ver lista"} size="tiny" icon="list" />}>
      <Icon link onClick={() => setOpen(false)} color="red" style={{ float: 'right' }} name="delete" />
      <span style={{ fontWeight: 'bold', fontSize: 17 }}>
        Observaciones{" "}
        <Popup
          on='click'
          onClose={() => setOpenObservation(false)}
          onOpen={() => setOpenObservation(true)}
          open={openObservation}
          trigger={<Icon name="add" link />}>
          <h4>Crear observación</h4>
          <Form>
            <Form.TextArea
              value={description}
              onChange={(e, { value }) => setDescription(value)}
              label="Observación" />
            <Form.Button
              compact
              onClick={handleMutation}
              disabled={loading || !description.trim()}
              loading={loading}
              content="Guardar" />
          </Form>
        </Popup>
      </span>
      <div style={{ overflowY: 'auto', height: !data?.length ? null : 200, padding: 20 }}>
        {data?.length > 0 ? data?.map(observation => (
          <Message key={observation?.id}>
            <h5>{observation?.user?.name}</h5>
            <p>{observation?.description}</p>
            <p style={{ fontSize: 10, color: 'grey' }}> {observation?.startDate ? moment(observation?.startDate).format("LLL") : '- - - -'}</p>
          </Message>
        ))
          : "No hay observaciones"}
      </div>
    </Popup >
  );
}

const CREATE_OBSERVATION = gql`
mutation($description: String!, $id: ID!) {
  createObservation(description: $description, familyMenmberId: $id) {
    id
    description
    startDate
    user {
      id
      name
    }
  }
}
`;

export default ObservationList;