import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const DownloadReport = (filter) => {
  const [loading, setLoading] = useState(false);

  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      title: "Información",
      icon: "info",
      text: "El reporte está siendo generado, revisar el estado en el listado de descargas.",
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(REPORT_DOWNLOAD, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({ variables: { filter:filter.filter } });
  };
  return (
    <Form.Field
      compact
      icon="file excel"
      loading={loading}
      disabled={loading}
      onClick={handleMutation}
      size="mini"
      control={Button}
      content="Descargar"
    />
  );
};

const REPORT_DOWNLOAD = gql`
  mutation ($filter: FamilyMemberFilter) {
    downloadReportFamilyMemberOffers(filter: $filter) {
      id
      root
      createAt
      status
      statusDownload
    }
  }
`;

export default DownloadReport;
