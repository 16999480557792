import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import Selector from "./Selector";

const options = [
  { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
  { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
  { text: "Eliminado", value: "REMOVED", key: "REMOVED" },
];

const UpdatePermit = ({
  reloadPermissions,
  reload,
  data,
  error = false,
  loading = false,
  permit
}) => {
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [status, setStatus] = useState(permit?.status || "");
  const [action, setAction] = useState(permit?.action?.id || "");
  const [userGroup, setUserGroup] = useState(permit?.userGroup?.id || "");
  const [module, setModule] = useState(permit?.module?.id || "");
  const onCompleted = async () => {
    await reload();
    await reloadPermissions();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el permiso de manera exitosa.'
    })
      .then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_PERMIT, { onCompleted, onError });
  const handleMutation = () => {
    mutation({
      variables: {
        input: {
          id: permit.id,
          idAction: action,
          idModule: module,
          idUserGroup: userGroup,
          status
        }
      }
    });
  };

  const onClose = () => {
    setStatus(permit?.status || "");
    setUserGroup(permit?.userGroup?.id || "");
    setModule(permit?.module?.id || "");
    setAction(permit?.action?.id || "");
    setOpen(false);
  };

  return (
    <>
      <Form.Button
        icon='edit'
        size="mini"
        onClick={() => setOpen(true)}
        content='Editar' />
      <Modal onClose={onClose} size="tiny" open={open}>
        <Modal.Header>
          <Icon name='add' /> Actualizar permiso
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir acción"
              error={error}
              selection
              value={action}
              onChange={(e, { value }) => setAction(value)}
              data={data?.actionList?.items || []}
              placeholder="Acción" />
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir grupo usuario"
              error={error}
              selection
              value={userGroup}
              onChange={(e, { value }) => setUserGroup(value)}
              data={data?.userGroupListe?.items || []}
              placeholder="Grupo usuario" />
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir módulo"
              error={error}
              selection
              value={module}
              onChange={(e, { value }) => setModule(value)}
              data={data?.moduleList?.items || []}
              placeholder="Módulo" />
            <Form.Dropdown
              selection
              label="Elegir estado"
              value={status}
              onChange={(e, { value }) => setStatus(value)}
              options={options} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              loadingSave ||
              !status.trim() ||
              !module.trim() ||
              !action.trim() ||
              !userGroup.trim()
            } onClick={handleMutation} content="Actualizar" />
          <Button onClick={onClose} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const UPDATE_PERMIT = gql`
   mutation updatePermit($input:  updatePermissionInput!) {
    updatePermission(input: $input) {
      id
      status
      action {
        id
        name
      }
      module {
        id
        name
      }
      userGroup {
        id
        name
      }
    }
  }
`;

export default UpdatePermit;