import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import FamilyPlanMember from "./responses";

const ModalAnwserFamilyPlan = ({ idFamilyPlan, idFamilyMember }) => {
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  return (
    <Modal
      open={open}
      trigger={
        <Button
          style={{ backgroundColor: "#045A73", color: "white" }}
          size="mini"
          compact
          content="Responder"
          onClick={() => setOpen(true)}
        />
      }
      size="mini"
    >
      <Modal.Header>Elegir period</Modal.Header>
      <Content
        onClose={onCloseModal}
        idFamilyPlan={idFamilyPlan}
        idFamilyMember={idFamilyMember}
      />
    </Modal>
  );
};

const Content = ({ idFamilyPlan, idFamilyMember, onClose }) => {
  const [idPeriod, setIdPeriod] = useState("");
  const [idAnswerHeader, setIdAnswerHeader] = useState("");
  const [openSurveyModal, setOpenSurveyModal] = useState(false);

  const onCompleted = ({ createFamilyPlanAnswerHeader }) => {
    setOpenSurveyModal(true);
    setIdAnswerHeader(createFamilyPlanAnswerHeader?.id);
  };

  const onBackView = () => {
    setOpenSurveyModal(false);
    setIdAnswerHeader("");
    onClose();
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const { loading, error, data } = useQuery(PERIOD_LIST, {
    variables: {
      idFilter: idFamilyPlan,
      idFamilyMenmber: idFamilyMember,
    },
    fetchPolicy: "network-only",
  });

  const [mutation] = useMutation(CREATE_ANSWER_HEADER, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    Swal.fire({
      title: "¿Deseas realizar está acción?",
      showDenyButton: true,
      confirmButtonText: "Aceptar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenSurveyModal(true);
        /* Mutacion para seleccionar el periodo */
        mutation({
          variables: {
            input: {
              idFamilyPlan,
              idPeriod,
              idFamilyMenmber: idFamilyMember,
            },
          },
        });
      }
    });
  };

  return (
    <>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            value={idPeriod}
            loading={loading}
            error={error}
            fluid
            search
            label="Seleccionar periodo"
            placeholder="Eligir el periodo a responder"
            noResultsMessage="No hay periodos disponibles para responder"
            onChange={(e, { value }) => setIdPeriod(value)}
            options={data?.periodList?.items || []}
            selection
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleMutation}
          disabled={!idPeriod.trim()}
          content="Responder"
        />
        <Button onClick={onClose} content="Cancelar" />
      </Modal.Actions>
      <FamilyPlanMember
        idAnswerHeader={idAnswerHeader}
        openSurveyModal={openSurveyModal}
        setOpenSurveyModal={onBackView}
      />
    </>
  );
};

const CREATE_ANSWER_HEADER = gql`
  mutation ($input: CreateFamilyPlanAnswerHeaderInput!) {
    createFamilyPlanAnswerHeader(input: $input) {
      id
    }
  }
`;

const PERIOD_LIST = gql`
  query ($idFilter: ID, $idFamilyMenmber: ID) {
    periodList(
      page: { number: 1, limit: 100 }
      type: FAMILYPLAN
      idFilter: $idFilter
      idFamilyMenmber: $idFamilyMenmber
    ) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default ModalAnwserFamilyPlan;
