import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal, Table } from "semantic-ui-react";
import Swal from "sweetalert2";
import UpdateOfferHeader from "./UpdateOfferHeader";

const OfferModal = ({
  triggerFluid = true,
  disabled,
  user: familyMenmber,
  update,
}) => {
  const [open, setOpen] = useState(false);
  const variables = {
    page: { number: 1, limit: 100000 },
    filter: { status: "ACTIVE" },
  };
  const [offer, setOffer] = useState("");
  const [date, setDate] = useState("");

  const { data, loading, error } = useQuery(OFFERS, {
    variables,
    fetchPolicy: "network-only",
  });

  const { data: userOffersData, refetch: userOffersRefetch } = useQuery(
    USER_OFFERS,
    {
      variables: {
        idFamilyMember: familyMenmber?.id,
        statusList: ["ACTIVE", "INACTIVE"],
      },
      fetchPolicy: "no-cache",
    }
  );

  const onCompleted = async (data) => {
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha creado la participación de manera exitosa.",
    });
    handleRefetch();
    setDate("");
    setOffer("");
  };

  const handleRefetch = async () => {
    userOffersRefetch({
      variables: {
        idFamilyMember: familyMenmber?.id,
        statusList: ["ACTIVE", "INACTIVE"],
      },
      fetchPolicy: "no-cache",
    });
  };

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_OFFER_HEADER, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    if (offer !== "") {
      const input = {
        answeDate: date,
        idFamilyMenmber: familyMenmber.id,
        idOffer: offer,
      };

      mutation({ variables: { input } });
    } else {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar una oferta para poder añadir una oferta",
      });
    }
  };

  return (
    <Modal
      trigger={
        triggerFluid ? (
          <Button
            onClick={() => setOpen(true)}
            disabled={
              familyMenmber?.exitDate &&
              new Date(familyMenmber?.exitDate) <= new Date()
            }
            icon="edit"
            size="tiny"
            fluid
          />
        ) : (
          <Button
            disabled={
              familyMenmber?.exitDate &&
              new Date(familyMenmber?.exitDate) <= new Date()
            }
            onClick={() => setOpen(true)}
            icon="edit"
            size="tiny"
          />
        )
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Ofertas</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Oferta"
              value={offer}
              onChange={(e, { value }) => setOffer(value)}
              scrolling={true}
              clearable={true}
              selection={true}
              error={error}
              search={true}
              required
              loading={loading}
              options={
                data?.offerList?.items
                  .filter((e) => {
                    let ids = userOffersData?.offerHeaders.map(
                      (e) => e.offer.id
                    );
                    return !ids?.includes(e.id);
                  })
                  .map((item) => {
                    return { value: item.id, text: item.name };
                  }) || []
              }
            />
            <Form.Input
              type="date"
              label="Fecha de acceso a la oferta"
              value={date}
              onChange={(e, { value }) => setDate(value)}
              required
            />
          </Form.Group>
          <Button
            disabled={!offer.trim() || !date.trim()}
            onClick={handleMutation}
            content="Agregar oferta"
          />
        </Form>

        {userOffersData?.offerHeaders.length > 0 ? (
          <Table basic="very" fixed>
            <Table.Header>
              <Table.Row align="center">
                <Table.HeaderCell>Oferta</Table.HeaderCell>
                <Table.HeaderCell>Fecha ingreso</Table.HeaderCell>
                <Table.HeaderCell>Fecha salida</Table.HeaderCell>
                <Table.HeaderCell>Estado</Table.HeaderCell>
                <Table.HeaderCell>Acción</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {userOffersData?.offerHeaders.map((e) => (
                <Table.Row align="center" key={`offerHeader${e.id}`}>
                  <Table.Cell>{e.offer.name}</Table.Cell>
                  <Table.Cell>
                    {new Date(`${e.answeDate} `).toLocaleDateString("en-GB")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.endDate
                      ? new Date(`${e.endDate} `).toLocaleDateString("en-GB")
                      : "Aún no cuenta con una fecha de salida"}
                  </Table.Cell>
                  <Table.Cell>
                    {e.status === "ACTIVE"
                      ? "Activo"
                      : e.status === "INACTIVE"
                      ? "Inactivo"
                      : "Removido"}
                  </Table.Cell>
                  <Table.Cell>
                    <UpdateOfferHeader offer={e} refetch={handleRefetch} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <div style={{ marginTop: 20 }}>
            Este miembro familiar no ha participado en ninguna oferta
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Guardar" />
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
};

const USER_OFFERS = gql`
  query Query($idFamilyMember: ID!, $statusList: [Status]) {
    offerHeaders(idFamilyMenmber: $idFamilyMember, statusList: $statusList) {
      id
      answeDate
      endDate
      offer {
        id
        name
      }
      status
    }
  }
`;

const OFFERS = gql`
  query Query($page: PageInput!, $filter: OfferFilter!) {
    offerList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        description
        status
        inCharge {
          id
          name
        }
      }
    }
  }
`;
const CREATE_OFFER_HEADER = gql`
  mutation ($input: CreateOfferHeaderInput!) {
    createOfferHeader(input: $input) {
      id
      answeDate
      offer {
        id
        name
      }
      status
    }
  }
`;
export default OfferModal;
