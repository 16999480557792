import { useQuery, gql } from "@apollo/client";
import { Form } from "semantic-ui-react";

const InputsEmployability = ({
  occupation,
  setOccupation,
  clearable,
  search = true,
  selection = true,
  required = true,
  educativeEstablishment,
  setEducativeEstablishment,
  jobPerformed,
  setJobPerformed,
  contractType,
  setContractType,
  independentType,
  setIndependentType,
  undertakes,
  setUndertakes,
  undertakeName,
  setUndertakeName,
  artOrWork,
  setArtOrWork,
  artOrWorkType,
  setArtOrWorkType,
  disabled = false,
}) => {
  const { loading, error, data } = useQuery(DATA);

  return (
    <>
      <Form.Dropdown
        scrolling
        placeholder="Ocupación"
        clearable={clearable}
        selection={selection}
        search={search}
        disabled={disabled}
        value={occupation}
        onChange={(e, { value }) => setOccupation(value)}
        loading={loading}
        error={error}
        options={data?.occupation?.items || []}
        label="Ocupación"
      />
      {occupation === "60" && (
        <Form.Input
          type="text"
          readOnly={disabled}
          value={educativeEstablishment}
          onChange={(e, { value }) => setEducativeEstablishment(value)}
          label="Establecimiento educativo"
        />
      )}
      {occupation === "62" && (
        <>
          <Form.Input
            readOnly={disabled}
            type="text"
            value={independentType}
            onChange={(e, { value }) => setIndependentType(value)}
            label="¿En qué campo es independiente el beneficiario?"
          />
          <Form.Input
            readOnly={disabled}
            type="text"
            value={jobPerformed}
            onChange={(e, { value }) => setJobPerformed(value)}
            label="Arte u oficio que desempeña"
          />
        </>
      )}

      {occupation === "64" && (
        <Form.Dropdown
          scrolling
          placeholder="Tipo de contrato"
          clearable={clearable}
          selection={selection}
          search={search}
          disabled={disabled}
          value={contractType}
          onChange={(e, { value }) => setContractType(value)}
          loading={loading}
          error={error}
          options={data?.contractType?.items || []}
          label="Tipo de contrato"
        />
      )}
      <Form.Group widths="equal">
        <Form.Checkbox
          checked={undertakes}
          disabled={disabled}
          onChange={(e, { checked }) => {
            setUndertakes(checked);
          }}
          label="¿Posee algún emprendimiento?"
        />
      </Form.Group>
      {undertakes && (
        <Form.Input
          type="text"
          readOnly={disabled}
          value={undertakeName}
          onChange={(e, { value }) => setUndertakeName(value)}
          label="¿Cuál es el nombre del emprendimiento?"
        />
      )}
      <Form.Group widths="equal">
        <Form.Checkbox
          checked={artOrWork}
          disabled={disabled}
          onChange={(e, { checked }) => {
            setArtOrWorkType("")
            setArtOrWork(checked);
          }}
          label="¿El beneficiario posee algún tipo de conocimiento empírico en algún arte u oficio?"
        />
      </Form.Group>
      {artOrWork && (
        <Form.Input
        readOnly={disabled}
        type="text"
        value={artOrWorkType}
        onChange={(e, { value }) => setArtOrWorkType(value)}
        label="¿Cuál es el nombre del arte u oficio en el cual el beneficiario tiene conocimientos?"
      />
      )}
    </>
  );
};

const DATA = gql`
  query {
    occupation: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "14" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
    contractType: parameterValueList(
      page: { number: 1, limit: 100 }
      filter: { status: ACTIVE, idParameter: "24" }
    ) {
      items {
        value: id
        text: name
        key: id
      }
    }
  }
`;

export default InputsEmployability;
