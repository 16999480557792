import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Grid, Icon, Segment, Image } from "semantic-ui-react";
import Swal from "sweetalert2";
import LOGO_BID from "../../assets/bid.png";
import { EMAIL_REGEX } from "../../Utils/Constants";
const RecoverAccount = ({ history }) => {
  const [email, setEmail] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleBackToLogin = () => {
    history.replace("/");
    setSendEmail(false);
  }

  const onCompleted = () => {
    setLoading(false);
    setSendEmail(true);
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(RECOVER_ACCOUNT, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    mutation({ variables: { email: email.trim() } });
  }
  return (
    <Grid className="login" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 367 }}>
        <Form size='large'>
          <Segment stacked>
            <div style={{ margin: 20, textAlign: 'center' }}>
              {<Image style={{ display: 'initial' }} size="small" src={LOGO_BID} alt="logo bid" />}
            </div>
            <h3>Recuperar cuenta</h3>
            {!sendEmail && (
              <>
                <p style={{ fontWeight: 'bold' }}>Ingresa tu correo electrónico para buscar tu cuenta.</p>
                <Form.Input
                  icon={<Icon name='user' style={{ color: '#045A73' }} />}
                  value={email}
                  error={email.trim() ? !EMAIL_REGEX.test(email) : null}
                  iconPosition='left'
                  placeholder='Correo'
                  onChange={(e, { value }) => setEmail(value)} />
                <Button
                  onClick={handleMutation}
                  loading={loading}
                  style={{ backgroundColor: '#045A73', color: '#fff' }}
                  disabled={!EMAIL_REGEX.test(email) || loading}>
                  Enviar
                </Button>
              </>
            )}
            {sendEmail && (
              <p style={{ fontWeight: 'bold' }}>
                Hemos enviado un enlace para recuperar tu cuenta al correo que nos has proporcionado.
              </p>
            )}
            <Button
              onClick={handleBackToLogin}
              style={{ backgroundColor: '#045A73', color: '#fff' }}>
              volver
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

const RECOVER_ACCOUNT = gql`
  mutation($email: EmailAddress!){
    recoverAccount(email: $email)
  }
`;

export default RecoverAccount;