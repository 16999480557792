import { useMutation, gql } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Label, Modal, Segment } from "semantic-ui-react";
import InputsFamily from "../../Surveys/ResponseSurvey/InputsFamily";
import FilterFamilyPlan from "./FilterFamilyPlan";
import Swal from "sweetalert2";

const optionsRangeAge = [
  { text: "0 años a 5 años", value: "[0, 5]", key: 1 },
  { text: "6 años a 11 años", value: "[6, 11]", key: 2 },
  { text: "12 años a 18 años", value: "[12, 18]", key: 3 },
  { text: "19 años a 26 años", value: "[19, 26]", key: 4 },
  { text: "27 años a 59 años", value: "[27, 59]", key: 5 },
  { text: "Más de 60", value: "[60, 130]", key: 6 },
];

const FilterReport = ({ handleDeleteFilter }) => {
  const [idPeriod, setPeriod] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [nameQuestion, setNameQuestion] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [typeIdentification, setTypeIdentification] = useState("");
  const [idFamilyPlan, setIdFamilyPlan] = useState("");
  const [idCategorie, setIdCategorie] = useState("");
  const [idQuestion, setIdQuestion] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const fields = [];
  const fieldsSurvey = {};
  const variables = { page: { number: currentPage, limit: 10 } };
  if (gender.trim()) fields.push({ value: gender, variable: "GENDER" });
  if (ageRange?.length > 0)
    fields.push({
      values: JSON.parse(ageRange),
      variable: "BIRTH_DATE",
      comparate: "BETWEEN",
    });
  if (maritalStatus.trim())
    fields.push({ value: maritalStatus, variable: "MARITAL_STATUS" });
  if (typeIdentification.trim())
    fields.push({ value: typeIdentification, variable: "TYPE_IDENTIFICATION" });
  if (idFamilyPlan.trim()) {
    fieldsSurvey.idFamilyPlan = idFamilyPlan;
    variables.idFamilyPlan = idFamilyPlan;
  }
  if (idPeriod.trim()) {
    fieldsSurvey.idPeriod = idPeriod;
    variables.idPeriod = idPeriod;
  }
  if (idQuestion.trim()) {
    fieldsSurvey.idQuestion = idQuestion;
    variables.idQuestion = idQuestion;
  }
  if (optionsValue.length > 0) {
    fieldsSurvey.options = optionsValue;
    variables.options = optionsValue;
  }
  if (initialDate && finalDate) {
    if (
      new Date(initialDate) > new Date(finalDate) ||
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rango de fechas es inválido.",
      });
    } else {
      fieldsSurvey.initialDate = `${initialDate} 00:00:00`;
      fieldsSurvey.finalDate = `${finalDate} 23:59:59`;
    }
  }
  const filter = { fields };
  if (Object.keys(fieldsSurvey).length > 0) filter.fieldsSurvey = fieldsSurvey;
  variables.filter = filter;
  variables.inFilter = Boolean(
    fields.length || Object.keys(fieldsSurvey).length
  );

  const handleClearFilter = () => {
    setGender("");
    setPeriod("");
    setMaritalStatus("");
    setTypeIdentification("");
    setIdFamilyPlan("");
    setIdQuestion("");
    setIdCategorie("");
    setInitialDate("");
    setFinalDate("");
    setOptionsValue([]);
  };

  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      title: "Información",
      icon: "info",
      text: "El reporte está siendo generado, revisar el estado en el listado de descargas.",
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(REPORT_DOWNLOAD, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    setOpenModal(false);
    mutation({ variables });
  };

  return (
    <Modal
      onClose={() => setOpenModal(false)}
      open={openModal}
      trigger={
        <Button
          circular
          onClick={() => setOpenModal(true)}
          icon="filter"
          content="Filtros"
        />
      }
    >
      <Modal.Header>Construir filtros</Modal.Header>
      <Modal.Content scrolling>
        <div style={{ minHeight: 200 }}>
          <Segment>
            <Label attached="top right">Campos de la encuesta</Label>
            <FilterFamilyPlan
              setNameQuestion={setNameQuestion}
              handleClearFilter={handleClearFilter}
              idPeriod={idPeriod}
              setPeriod={setPeriod}
              idCategorie={idCategorie}
              setIdCategorie={setIdCategorie}
              idSurvey={idFamilyPlan}
              setIdSurvey={setIdFamilyPlan}
              idQuestion={idQuestion}
              setIdQuestion={setIdQuestion}
              optionsValue={optionsValue}
              setOptionsValue={setOptionsValue}
              initialDate={initialDate}
              finalDate={finalDate}
              setInitialDate={setInitialDate}
              setFinalDate={setFinalDate}
            />
          </Segment>
          <Segment>
            <Label attached="top right">Campos del usuario</Label>
            <Form>
              <InputsFamily
                clearable
                required={false}
                search={false}
                widths={null}
                gender={gender}
                setGender={setGender}
                maritalStatus={maritalStatus}
                setMaritalStatus={setMaritalStatus}
                typeIdentification={typeIdentification}
                setTypeIdentification={setTypeIdentification}
              />
              <Form.Dropdown
                value={ageRange}
                label="Rango de edad"
                placeholder="Filtrar por rango de edad"
                clearable
                scrolling
                onChange={(e, { value }) => setAgeRange(value)}
                options={optionsRangeAge}
                selection
              />
            </Form>
          </Segment>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="check"
          style={{ backgroundColor: "#045A73", color: "white" }}
          onClick={() => handleMutation()}
          content="Filtrar"
        />
        <Button
          icon="trash"
          onClick={handleClearFilter}
          content="Vaciar filtros"
        />
      </Modal.Actions>
    </Modal>
  );
};

const REPORT_DOWNLOAD = gql`
  mutation ($filter: ReportFilterInputFamilyPlan!) {
    reportFamilyPlanByFilter(filter: $filter) {
      id
      root
      createAt
      status
      statusDownload
    }
  }
`;

export default FilterReport;
