import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import CreateOffer from "./CreateOffer";
//import UpdateOffer from "./UpdateOffer";

const Offers = ({ permissions }) => {
  let history = useHistory();
  const options = [
    { text: "Activo", value: "ACTIVE", key: "ACTIVE" },
    { text: "Inactivo", value: "INACTIVE", key: "INACTIVE" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [fakeName, setFakeName] = useState("");
  const variables = {
    page: { number: currentPage, limit: 10 },
    filter: { status, name },
  };

  if (name.trim()) variables.filter.name = name;
  const { data, loading, error, refetch } = useQuery(OFFERS, {
    variables,
    fetchPolicy: "network-only",
  });
  const CREATE = permissions?.some((value) => value?.action?.name === "Crear");
  const EDIT = permissions?.some((value) => value?.action?.name === "Editar");
  const columns = [
    {
      header: "Oferta",
      accessor: "name",
    },
    {
      header: "Actividades",
      accessor: "description",
    },
    {
      header: "Coordinador encargado",
      accessor: "inCharge.name",
    },
    {
      header: "Programa",
      accessor: "program.name",
    },
    {
      header: "Tipo de Oferta",
      accessor: "offerType.name",
    },
  ];

  if (EDIT)
    columns.push({
      header: "Acción de editar",
      render: (data) => (
        <Button
          size="mini"
          onClick={() => history.push(`/dashboard/Ofertas/${data.id}`)}
          icon="edit"
        />
      ),
    });

  return (
    <div>
      <h1>Ofertas</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateOffer refetch={refetch} />}
          <Form.Field>
            <Label content={`Cantidad (${data?.offerList?.count || 0})`} />
          </Form.Field>
          <Form.Input
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setName(fakeName),
            }}
            value={fakeName}
            size="mini"
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Dropdown
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options}
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        totalPages={data?.offerList?.totalPages || 0}
        data={data?.offerList?.items || []}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const OFFERS = gql`
  query Query($page: PageInput!, $filter: OfferFilter!) {
    offerList(page: $page, filter: $filter) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        name
        description
        status
        workTeam {
          id
        }
        inCharge {
          id
          name
        }
        offerType {
          id
          name
        }
        program {
          id
          name
        }
      }
    }
  }
`;

export default Offers;
